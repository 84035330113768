import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import Constants from 'components/Constants';
import API from 'lib/api';
import { Auth } from 'lib/auth';
import utils from 'lib/utils';
import scheduled_empty_icon from 'images/scheduled_empty.png';
import { flowMenuItems, flowTypes, flowStatus } from './Constants';
import FlowsList from './FlowsList';
import FlowsSidebar from './FlowsSidebar';

function FlowsTab() {
  const match = useRouteMatch();
  const isAdminView = match.path.indexOf('create') === -1;
  const baseUrl = isAdminView ? '' : '/create';
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState('');
  const isAdmin = Auth.hasRole(Constants.PRODUCER_ROLE) || Auth.hasRole(Constants.PRODUCER_ADMIN_ROLE);
  const activeSidebarMenuItem = match.params.item
    ? match.params.item
    : isAdmin
    ? flowMenuItems.MY_FLOWS
    : flowMenuItems.SCHEDULED_FLOWS;
  const [counts, setCounts] = useState({ processing_flows: 0, scheduled_flows: 0, manual_flows: 0, my_flows: 0 });
  const [flows, setFlows] = useState([]);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    API.fetchContent(`/flows/?preset=${activeSidebarMenuItem}`, () => setIsLoading(true), onContentFetch);
  }, [activeSidebarMenuItem]);

  useEffect(() => {
    if (notification) {
      utils.notify(notification);
      setTimeout(() => {
        setNotification('');
      }, 4000);
    }
  }, [notification]);

  const emptyInfo = {
    emptyImageUrl: scheduled_empty_icon,
    emptyTitle: 'No scheduled bulk generations',
    emptyPrompt:
      'Want to generate a presentation at a future date? You can now schedule bulk generations for your templates.',
  };

  const onContentFetch = (data, paginationResponse) => {
    setCounts(data.counts);
    setFlows(data.flows);
    setPagination(paginationResponse);
    setIsLoading(false);
  };

  const paginateContent = (offset, limit = Constants.PAGE_SIZE, sort = null) => {
    API.fetchContent(
      `/flows/?preset=${activeSidebarMenuItem}`,
      () => setIsLoading(true),
      onContentFetch,
      offset,
      limit,
      sort,
    );
  };

  const deactivateFlow = (flow_id) => {
    const updatedFlows = cloneDeep(flows);
    const flowIndex = updatedFlows.findIndex((flow) => flow.id === flow_id);
    if (flowIndex > -1 && updatedFlows[flowIndex].flow_type === flowTypes.SCHEDULED) {
      updatedFlows[flowIndex].status = flowStatus.DEACTIVATED;
      const updateData = {
        status: flowStatus.DEACTIVATED,
      };
      API.put(
        `/scheduled_tasks/${flow_id}/`,
        updateData,
        () => {
          utils.notify('Scheduled flow deactivated');
          setFlows(updatedFlows);
        },
        API.defaultError,
      );
    }
  };

  const removeFlowFromList = (flowToRemove) => {
    const updatedFlows = flows.filter(
      (flow) => !(flow.id === flowToRemove.id && flow.flow_type === flowToRemove.flow_type),
    );
    setFlows(updatedFlows);
  };

  const onFlowDelete = (flowToDelete) => {
    switch (flowToDelete.flow_type) {
      case flowTypes.MANUAL:
        API.delete(
          `/bulk_presentations/${flowToDelete.id}/`,
          () => {
            removeFlowFromList(flowToDelete);
            setNotification('Manual flow deleted');
          },
          API.defaultError,
        );
        break;
      default:
        API.delete(
          `/scheduled_tasks/${flowToDelete.id}/`,
          () => {
            removeFlowFromList(flowToDelete);
            setNotification('Scheduled flow deleted');
          },
          API.defaultError,
        );
    }
  };

  return (
    <div className="wrapper">
      <FlowsSidebar activeSidebarMenuItem={activeSidebarMenuItem} baseUrl={baseUrl} counts={counts} isAdmin={isAdmin} />
      <FlowsList
        emptyInfo={emptyInfo}
        isLoading={isLoading}
        elements={flows}
        onFlowDeactivate={deactivateFlow}
        onFlowDelete={onFlowDelete}
        flows={flows}
        match={match}
        pagination={pagination}
        paginateContent={paginateContent}
      />
    </div>
  );
}

export default FlowsTab;
