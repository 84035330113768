import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constants from 'components/Constants';
import API from 'lib/api';
import SmallLoader from 'components/shared/SmallLoader';

class FileViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      embedUrl: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    if (
      this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
      this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
    ) {
      this.setState({ isLoading: true });
      const id = this.props.presentation.id;
      API.get(
        `/presentations/${id}/embed_url/`,
        (response) => {
          const embedUrl = response?.data?.url && response.data.url.length > 0 ? response.data.url : '';
          this.setState({ embedUrl: embedUrl, isLoading: false });
        },
        () => {
          // eslint-disable-next-line no-console
          console.warn('Could not fetch embed url for Microsoft 365 file');
          this.setState({ isLoading: false });
        },
      );
    }
  }

  render() {
    const embedUrl = this.getUrl();
    const html = this.getHtml();

    if (this.state.isLoading) {
      return <SmallLoader />;
    }

    if (html) {
      return (
        <iframe
          sandbox="allow-same-origin"
          id="template-preview"
          title={'template-preview-' + this.props.template.id}
          srcDoc={html}
          width="100%"
          height="100%"
          frameBorder="0"
          className={this.props.frameClass}
        />
      );
    } else {
      return (
        <iframe
          id="template-preview"
          title={'template-preview-' + this.props.template.id}
          src={embedUrl}
          width="100%"
          height="100%"
          frameBorder="0"
          className={this.props.frameClass}
        />
      );
    }
  }

  getUrl() {
    if (this.props.presentation) {
      if (
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS ||
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS
      ) {
        return this.props.presentation.presentation_file.embed_url;
      } else if (
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT ||
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD
      ) {
        return (
          'https://view.officeapps.live.com/op/embed.aspx?src=' +
          encodeURIComponent(this.props.presentation.presentation_file.url)
        );
      } else if (
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
      ) {
        if (this.state.embedUrl.length > 0) {
          return this.state.embedUrl;
        }
        return this.props.presentation.presentation_file.url;
      }
    } else {
      if (
        this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS ||
        this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS
      ) {
        return this.props.template.source.embedUrl;
      } else if (
        this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT ||
        this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
        this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD ||
        this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
      ) {
        return (
          'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(this.props.template.source.url)
        );
      }
    }
    return null;
  }

  getHtml() {
    if (this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      if (this.props.presentation) {
        return this.processHtml(this.props.presentation.presentation_file);
      } else if (this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
        return this.props.template.slides[0].slide_xml;
      }
    }
    return null;
  }

  processHtml(presentationFile) {
    const images = presentationFile['images_by_dc_name'];
    const html = presentationFile['parsed_html'];
    if (typeof images === 'object' && Object.keys(images).length) {
      let html_copy = html.slice();
      for (const [dc_name, image_data] of Object.entries(images)) {
        html_copy = html_copy.replace(`cid:${dc_name}`, image_data);
      }
      return html_copy;
    }
    return html;
  }
}

FileViewer.propTypes = {
  presentation: PropTypes.any,
  template: PropTypes.object,
  frameClass: PropTypes.string,
};

export default FileViewer;
