import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useContext } from 'react';
import { UserContext } from 'components/UserContext';
import Icon from 'components/lib/Icon';
import PropTypes from 'prop-types';
import Tag from 'components/lib/Tag';
import ListItem from '../../lib/ListItem';

function AiSpeakerNotesButton({ title, onClick }) {
  const flags = useFlags();
  const context = useContext(UserContext);
  const aiEnabled = context.user?.enterprise?.enterprise_settings?.ai_enabled || false;

  if (!flags.enableAiSpeakerNotes) {
    return null;
  } else {
    return (
      <button
        className="flex items-center justify-start relative rounded py-1 px-4 hover:bg-grey-100 mb-1 w-full"
        onClick={onClick}
      >
        <ListItem
          icon={<Icon name="text_grammar_wand" size={20} />}
          title={title}
          size="s"
          status={aiEnabled ? 'default' : 'disabled'}
        ></ListItem>
        <Tag label="AI" size="xxs" color="gradient" luminosity="medium" />
      </button>
    );
  }
}

AiSpeakerNotesButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default AiSpeakerNotesButton;
