import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import utils from 'lib/utils';
import Constants from 'components/Constants';
import CheckboxWithLabel from 'components/shared/CheckboxWithLabel';
import Banner from 'components/shared/Banner';
import ButtonGroup from 'components/lib/ButtonGroup';
import Button from 'components/lib/Button';
import InputText from 'components/lib/InputText';
import IntegrationFolderSelector from 'components/shared/IntegrationFolderSelector';
import Tag from 'components/lib/Tag';
import { UserContext } from 'components/UserContext';

function GeneratePresentationPopup({
  onPdfToggle,
  includePdf,
  includeExecSummary,
  includeTalkTracks,
  onTalkTracksToggle,
  onExecSummaryToggle,
  templateType,
  sourceType,
  popupMenuRef,
  primaryButtonText,
  primaryButtonOnClick,
  primaryButtonActive,
  showConditionsBanner,
  onCustomFolderUpdate,
  customFolder,
  customFolderPlaceholder,
  onCustomNameUpdate,
  customName,
  disabledFolderOptions,
  namePreview,
}) {
  const flags = useFlags();
  const titleType = utils.toTitleCase(templateType);

  const context = useContext(UserContext);
  const aiEnabled = context.user.enterprise.enterprise_settings.ai_enabled;

  const disabledAiMessage = 'Requires Matik AI. Contact Sales.';
  const aiExecSummaryTooltip = aiEnabled
    ? 'Matik will generate an executive summary based on the presentation’s content.'
    : disabledAiMessage;
  const aiTalkTrackTooltip = aiEnabled
    ? 'Matik will populate each slide’s speaker notes with talk track based on the slide’s contents.'
    : disabledAiMessage;

  const aiIcon = <Tag label="AI" size="xxs" color="gradient" luminosity="medium" />;

  return (
    <div className="bg-matik-white shadow-s rounded-lg w-[640px] cursor-auto flex flex-col max-h-screen">
      <div className="p-4">
        <CheckboxWithLabel
          checked={includePdf}
          id="include-pdf-checkbox"
          label="Generate a PDF"
          onChange={onPdfToggle}
        />
        {showConditionsBanner && (
          <Banner
            className="mts mbm text-wrap"
            text="Conditional Generation is on. "
            color="message-info"
            sublineText={`Only ${templateType}s that meet the specified criteria will be generated.`}
            bannerType="info"
          />
        )}
        {flags.customPresentationGenerationFoldersRunLevel && sourceType !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL && (
          <div className="mt-3 mb-3">
            {sourceType !== Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT &&
              sourceType !== Constants.TEMPLATE_SOURCE_TYPES.WORD && (
                <>
                  <p className="mb-1">{titleType} will be saved to:</p>
                  <IntegrationFolderSelector
                    currentFolder={customFolder}
                    onFolderSelect={onCustomFolderUpdate}
                    disabledOptions={disabledFolderOptions}
                    placeholder={customFolderPlaceholder}
                  />
                </>
              )}
            <p className="mb-1">{titleType} will be named:</p>
            <InputText value={customName} placeholder={namePreview} onChange={onCustomNameUpdate} />
          </div>
        )}
        {flags.enableAiSpeakerNotes && (
          <div>
            <div className="flex items-center gap-1">
              <CheckboxWithLabel
                tooltip={aiTalkTrackTooltip}
                checked={includeTalkTracks}
                id="generate-talk-tracks"
                label="Generate Talk Tracks"
                onChange={onTalkTracksToggle}
                dataPlace="top"
                disabled={!aiEnabled}
              />
              {aiIcon}
            </div>
            <div className="flex items-center gap-1">
              <CheckboxWithLabel
                tooltip={aiExecSummaryTooltip}
                checked={includeExecSummary}
                id="generate-executive-summary"
                label="Generate Executive Summary"
                onChange={onExecSummaryToggle}
                dataPlace="top"
                disabled={!aiEnabled}
              />
              {aiIcon}
            </div>
          </div>
        )}
      </div>
      <div className="flex p-4 justify-end border-t border-grey-300">
        <ButtonGroup width="hug">
          <Button category="tertiary" type="button" onClick={() => popupMenuRef.current.close()}>
            Cancel
          </Button>
          <Button
            category="primary"
            onClick={primaryButtonOnClick}
            status={primaryButtonActive ? 'default' : 'disabled'}
          >
            {primaryButtonText}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

GeneratePresentationPopup.propTypes = {
  onPdfToggle: PropTypes.func,
  includePdf: PropTypes.bool,
  includeExecSummary: PropTypes.bool,
  includeTalkTracks: PropTypes.bool,
  onTalkTracksToggle: PropTypes.func,
  onExecSummaryToggle: PropTypes.func,
  templateType: PropTypes.string,
  sourceType: PropTypes.string,
  popupMenuRef: PropTypes.object,
  showConditionsBanner: PropTypes.bool,
  primaryButtonOnClick: PropTypes.func,
  primaryButtonText: PropTypes.string,
  primaryButtonActive: PropTypes.bool,
  onCustomFolderUpdate: PropTypes.func,
  customFolder: PropTypes.object,
  customFolderPlaceholder: PropTypes.string,
  onCustomNameUpdate: PropTypes.func,
  customName: PropTypes.string,
  disabledFolderOptions: PropTypes.array,
  namePreview: PropTypes.string,
};

export default GeneratePresentationPopup;
