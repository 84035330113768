import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Level } from 'react-bulma-components';
import { Link } from 'react-router-dom';
const { parse } = require('json2csv');
import { ReactComponent as Plus } from '../../svg/plus.svg';
import ComponentWithHitsAndPagination from './search/ComponentWithHitsAndPagination';
import SearchBoxAndFilters from './search/SearchBoxAndFilters';
import SortDropdownSelector from './SortDropdownSelector';
import Button from '../lib/Button';
import Icon from '../lib/Icon';
import DropdownMenu from '../lib/DropdownMenu';
import API from '../../lib/api';
import DropdownMenuItem from '../lib/DropdownMenuItem';
import ListItem from '../lib/ListItem';

const WithFilterListHeader = (WrappedComponent) => {
  return class Inner extends Component {
    static propTypes = {
      buttonClass: PropTypes.string,
      entities: PropTypes.array,
      setEntities: PropTypes.func,
      pagination: PropTypes.object,
      fetchItems: PropTypes.func,
      filterPlaceholder: PropTypes.string,
      isSearching: PropTypes.bool,
      newEntityFunc: PropTypes.func,
      newEntityUrl: PropTypes.string,
      newEntityPhrase: PropTypes.string,
      searchAttributes: PropTypes.object,
      searchState: PropTypes.object,
      title: PropTypes.string,
      presentationsTitle: PropTypes.bool,
      displaySortDropdown: PropTypes.bool,
      displayFilterHeader: PropTypes.bool,
      showFilterButton: PropTypes.bool,
      entityType: PropTypes.string,
      inTable: PropTypes.bool,
      emailEditorType: PropTypes.string,
      updateIsFetchingPresentation: PropTypes.func,
      filtersAreShownInSidepane: PropTypes.bool,
    };
    static displayName = 'WithFilterListHeader';

    constructor(props) {
      super(props);

      this.state = {
        showSetupDropdown: false,
        csvLoading: false,
      };
    }

    render() {
      let sortOrder = this.props.isSearching ? null : this.props.pagination?.sort;

      const showTitleAddNewHeader = this.props.title || this.props.newEntityUrl || this.props.newEntityFunc;
      const newEntityPhrase = this.props.newEntityPhrase ? this.props.newEntityPhrase : 'Add New';
      let searchAndSort;
      if (this.props.displaySortDropdown && this.props.displayFilterHeader) {
        searchAndSort = (
          <Level>
            <Level.Item>
              <div className="search-full-width">
                <SearchBoxAndFilters
                  searchAttributes={this.props.searchAttributes}
                  searchState={this.props.searchState}
                  filterPlaceholder={this.props.filterPlaceholder}
                  showFilterButton={this.props.showFilterButton}
                  updateIsFetchingPresentation={this.props.updateIsFetchingPresentation}
                />
              </div>
            </Level.Item>
            <Level.Side align="right">
              <Level.Item>
                <SortDropdownSelector
                  name="sort-selector"
                  onChange={this.onSortedChange}
                  value={sortOrder}
                  isDisabled={this.props.isSearching}
                  entityType={this.props.entityType}
                />
              </Level.Item>
            </Level.Side>
          </Level>
        );
      } else if (this.props.displaySortDropdown) {
        searchAndSort = (
          <Level>
            <Level.Item></Level.Item>
            <Level.Side align="right">
              <Level.Item>
                <SortDropdownSelector
                  name="sort-selector"
                  onChange={this.onSortedChange}
                  value={sortOrder}
                  isDisabled={this.props.isSearching}
                  entityType={this.props.entityType}
                />
              </Level.Item>
            </Level.Side>
          </Level>
        );
      } else {
        searchAndSort = (
          <SearchBoxAndFilters
            searchAttributes={this.props.searchAttributes}
            searchState={this.props.searchState}
            filterPlaceholder={this.props.filterPlaceholder}
            showFilterButton={this.props.showFilterButton}
            filtersAreShownInSidepane={this.props.filtersAreShownInSidepane}
          />
        );
      }

      let body = <WrappedComponent entitiesToRender={this.props.entities} {...this.props} />;
      if (this.props.isSearching) {
        body = <ComponentWithHitsAndPagination WrappedComponent={WrappedComponent} {...this.props} items={[]} />;
      }

      let newEntityEl = null;
      if (this.props.newEntityUrl) {
        const linkId = `new-button-${this.props.newEntityUrl.replace(/\//g, '-')}`;
        const handleClick = () => {
          if (window.location.pathname === this.props.newEntityUrl) {
            window.location.reload();
          }
        };
        newEntityEl = (
          <Link
            to={this.props.newEntityUrl}
            onClick={handleClick}
            className={`is-medium button ${this.props.buttonClass}`}
            id={linkId}
          >
            <Plus className="mrs" />
            {newEntityPhrase}
          </Link>
        );
      } else if (this.props.newEntityFunc) {
        const buttonId = `new-button-${this.props.newEntityFunc.name}`;
        newEntityEl = (
          <button
            onClick={this.props.newEntityFunc}
            className={`is-medium button ${this.props.buttonClass}`}
            id={buttonId}
          >
            <Plus className="mrs" />
            {newEntityPhrase}
          </button>
        );
      }

      return this.props.inTable ? (
        <React.Fragment>
          <tr className="filters-container">
            <td className="mbn phl pbl ptm">{searchAndSort}</td>
          </tr>
          {body}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="filters-container">
            {showTitleAddNewHeader && (
              <Level className="mbn ptm">
                <Level.Side align="left" className="pll">
                  <Level.Item>
                    <Heading size={4}>{this.props.title}</Heading>
                  </Level.Item>
                </Level.Side>
                <Level.Side align="right" className="prl">
                  <Level.Item>{newEntityEl}</Level.Item>
                  {this.props.entityType === 'dynamic_content' && (
                    <Level.Item>
                      <DropdownMenu
                        dropdownTrigger={
                          <Button
                            category="secondary"
                            onClick={() => this.setState({ showSetupDropdown: !this.state.showSetupDropdown })}
                            status={this.state.csvLoading ? 'loading' : ''}
                          >
                            <Icon name="more_horizontal" size={20} theme="regular" />
                          </Button>
                        }
                        active={this.state.showSetupDropdown}
                        onClose={() => this.setState({ showSetupDropdown: false })}
                      >
                        <DropdownMenuItem>
                          <ListItem
                            onClick={this.onDownloadCSV}
                            icon={<Icon size={16} theme="regular" name="arrow_download" />}
                            title="Download CSV"
                          />
                        </DropdownMenuItem>
                      </DropdownMenu>
                    </Level.Item>
                  )}
                </Level.Side>
              </Level>
            )}
            <div className="mbn phl pbl ptm">{searchAndSort}</div>
          </div>
          {body}
        </React.Fragment>
      );
    }

    onDownloadCSV = (e) => {
      e.preventDefault();
      this.setState({ csvLoading: true, showSetupDropdown: false });
      API.get(
        '/dynamic_content/?all=true&lite-params=true',
        (response) => {
          const data = parse(response.data);
          const downloadLink = document.createElement('a');
          const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
          downloadLink.href = URL.createObjectURL(blob); // eslint-disable-line
          downloadLink.setAttribute('download', 'matik_dynamic_content.csv');
          downloadLink.click();
          this.setState({ csvLoading: false });
        },
        (err) => {
          throw err;
        },
      );
    };

    onSortedChange = (newSorted) => {
      let sorted = null;
      if (newSorted && newSorted.length > 0) {
        sorted = [newSorted[0], newSorted[1]];
      }
      this.props.fetchItems(this.props.pagination.currentPage, sorted);
    };
  };
};

export default WithFilterListHeader;
