import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';
import TextboxWithEmbeddedIcons from 'components/lib/TextboxWithEmbeddedIcons';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';
import GoogleDrivePicker from 'components/shared/GoogleDrivePicker';
import Button from 'components/lib/Button';

function IntegrationFolderSelectorWithFolderButton({
  currentFolder = {},
  onFolderSelect = () => {},
  placeholder = 'Select a Folder',
  isEnabled = true,
  onFolderDelete,
}) {
  const microsoftRef = useRef(null);
  const googleRef = useRef(null);
  const current = currentFolder?.folder_name || placeholder;
  const handleOpenFolderPicker = () => {
    if (!isEnabled) {
      return;
    }
    if (currentFolder?.source_type == 'microsoft') {
      microsoftRef.current?.click();
    } else if (currentFolder?.source_type == 'google') {
      googleRef.current?.click();
    }
  };

  const onMicrosoftFolderSelect = (e) => {
    const result = e.items[0];
    const folder = {
      source_type: 'microsoft',
      folder_id: result?.id,
      drive_id: result?.parentReference?.driveId,
      folder_name: result?.name,
    };
    onFolderSelect(folder);
  };
  const onGoogleFolderSelect = (e) => {
    const result = e[0];
    const folder = {
      source_type: 'google',
      folder_id: result?.id,
      folder_name: result?.name,
    };
    onFolderSelect(folder);
  };

  const deleteFolderFromGroup = () => {
    onFolderDelete(currentFolder);
  };

  const selectFolderButton = (
    <Button category="secondary" onClick={handleOpenFolderPicker} isBoxShadowHidden={true}>
      <Icon name={`${currentFolder?.source_type === 'google' ? 'google_drive_logo' : 'office_365_logo'}`} size={20} />
      <span className="flex ml-2">{currentFolder?.folder_id ? 'Change Folder' : 'Select a Folder'}</span>
    </Button>
  );

  return (
    <div className="flex w-full gap-x-3">
      <div className="w-auto whitespace-nowrap">{selectFolderButton}</div>
      {currentFolder?.folder_id && (
        <>
          <TextboxWithEmbeddedIcons readOnly={!isEnabled} textboxValue={current} />
          <Button type="button" width="square" size="medium" category="secondary" onClick={deleteFolderFromGroup}>
            <span className="text-grey-600 flex py-2 px-3">
              <Icon size={20} name="dismiss" />
            </span>
          </Button>
        </>
      )}
      <MicrosoftPicker
        className="hidden"
        onPicked={onMicrosoftFolderSelect}
        pickerMode="folders"
        buttonRef={microsoftRef}
      />
      <GoogleDrivePicker
        className="hidden"
        onPicked={onGoogleFolderSelect}
        view="folders"
        permissionsMessage="In order to set a custom folder, you will need to select allow in the authentication window."
        buttonRef={googleRef}
      />
    </div>
  );
}

IntegrationFolderSelectorWithFolderButton.propTypes = {
  currentFolder: PropTypes.shape({
    folder_name: PropTypes.string,
    source_type: PropTypes.string,
    folder_id: PropTypes.string,
    drive_id: PropTypes.string,
  }),
  onFolderSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isEnabled: PropTypes.bool,
  onFolderDelete: PropTypes.func.isRequired,
};

export default IntegrationFolderSelectorWithFolderButton;
