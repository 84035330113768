import React from 'react';
import PropTypes from 'prop-types';
import { Level } from 'react-bulma-components';
import WithSpreadSheetContext from './WithSpreadSheetContext';
import { ReactComponent as ChevronLeft } from '../../../svg/left_chevron.svg';
import { ReactComponent as ChevronRight } from '../../../svg/right_chevron.svg';
// import DrawerDockSideMenu from './DrawerDockSideMenu';
import google_sheets_logo from '../../../images/google_sheets.svg';
import excel_logo from '../../../images/excel.svg';
import Constants from '../../Constants';
import Button from '../../lib/Button';
import utils from 'lib/utils';

function SpreadSheetFooter(props) {
  let { placement, sheets, worksheetIndex, dataSource } = props.SpreadSheetContext;

  if (props.sheets) {
    sheets = props.sheets;
  }
  if (props.worksheetIndex) {
    worksheetIndex = props.worksheetIndex;
  }
  let documentUrl = 'https://docs.google.com/spreadsheets';
  if (props.documentId) {
    documentUrl = documentUrl + '/d/' + props.documentId;
  }
  let footerClass = 'google-sheet-footer';
  if (placement && placement === 'left') {
    footerClass = footerClass + ' vertical-footer';
  } else {
    footerClass = footerClass + ' horizontal-footer';
  }

  const worksheetList = sheets.map((sheet, index) => {
    if (sheet?.title) {
      return { title: sheet.title, index: sheet.index };
    } else {
      return { title: sheet, index: index };
    }
  });

  const downloadFile = () => {
    // if 365 file, open in new tab instead of download
    if (dataSource?.host) {
      const dataSourceHost = JSON.parse(dataSource?.host);
      if (dataSourceHost?.type == Constants.MICROSOFT.excel_type && dataSourceHost?.share_url) {
        window.open(dataSourceHost.share_url, '_blank').focus();
        return;
      }
    }
    utils.downloadExcelFile(null, dataSource);
  };

  return (
    <div className={footerClass}>
      <Level>
        <Level.Side align="left" className="footer-left">
          <Level.Side align="left">
            <Level.Item>
              <div className="worksheet-selector is-flex is-vertical-centered">
                <a href="#dummy" onClick={props.selectPreviousSheet} className="is-flex is-vertical-centered">
                  <ChevronLeft />
                </a>
                <a href="#dummy" onClick={props.selectNextSheet} className="is-flex is-vertical-centered plm">
                  <ChevronRight />
                </a>
              </div>
            </Level.Item>
          </Level.Side>
          <Level.Side className="workbook-name-list">
            {worksheetList
              .filter((sheet) => sheet.title)
              .map((sheet, index) => (
                <Level.Item key={sheet.title} className="worksheet-name-container" id={'workbook-' + sheet.index}>
                  <div onClick={() => props.selectSheet(sheet.index)} className="worksheet-link">
                    <div className={`worksheet-name ${index === worksheetIndex ? 'active' : ''}`}>{sheet.title}</div>
                  </div>
                </Level.Item>
              ))}
          </Level.Side>
        </Level.Side>
        <Level.Side align="right" className="mr-2">
          <Level.Item>
            {dataSource.type === Constants.DATA_SOURCE_TYPES.excel ? (
              <Button category="secondary" onClick={() => downloadFile()}>
                <img src={excel_logo} alt="Excel Sheets" className="icon is-flex" />
                <div className="spreadsheet-link pls">{dataSource && dataSource.name}</div>
              </Button>
            ) : (
              <Button category="secondary">
                <a href={documentUrl} target="_blank" rel="noopener noreferrer" className="is-flex">
                  <img src={google_sheets_logo} alt="Google Sheets" width="20px" height="20px" />
                  <div className="spreadsheet-link pls">{dataSource && dataSource.name}</div>
                </a>
              </Button>
            )}
            {/*TODO(Sean): Add this back in once left-docking CSS issues have been fixed*/}
            {/*<DrawerDockSideMenu />*/}
          </Level.Item>
        </Level.Side>
      </Level>
    </div>
  );
}

SpreadSheetFooter.propTypes = {
  SpreadSheetContext: PropTypes.object,
  selectSheet: PropTypes.func,
  selectPreviousSheet: PropTypes.func,
  selectNextSheet: PropTypes.func,
  documentId: PropTypes.string,
  sheets: PropTypes.array,
  worksheetIndex: PropTypes.number,
};

export default WithSpreadSheetContext(SpreadSheetFooter);
