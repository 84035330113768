import * as pbi from 'powerbi-client';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bulma-components';

import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { Select } from 'components/shared/FormSelect';
import API from 'lib/api';

/**
 * A form that allows an admin to select a report in their Power BI workspace.
 * The selected report is embedded within the application so that the admin can
 * configure a visualization to use as image dynamic content.
 */
const PowerBIForm = (props) => {
  const { queryObj, dataSourceId } = props;

  const [workspaceId, setWorkspaceId] = useState(null);
  const [reportEmbedURL, setReportEmbedURL] = useState(null);
  const [reportId, setReportId] = useState(null);
  const [semanticModelId, setSemanticModelId] = useState(null);
  const dynamicContentContext = useContext(DynamicContentContext);
  const workspaces = usePowerBIWorkspaces(dataSourceId);
  const reports = usePowerBIReports(dataSourceId, workspaceId);
  const reportEmbedToken = usePowerBIEmbedToken(dataSourceId, reportId, semanticModelId);

  const workspaceSelectOptions = workspaces.data.map((workspace) => ({
    label: workspace.name,
    value: workspace.id,
  }));

  const reportSelectOptions = reports.data.map((report) => ({
    label: report.name,
    value: {
      reportId: report.id,
      reportEmbedURL: report.embedUrl,
      semanticModelId: report.datasetId,
    },
  }));

  const selectWorkspaceOption = (option, event) => {
    if (event.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj, { workspaceOption: option });
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
      setWorkspaceId(option.value);
    }
  };

  const selectReportOption = (option, event) => {
    if (event.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj, { reportOption: option });
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
      setReportId(option.value.reportId);
      setReportEmbedURL(option.value.reportEmbedURL);
      setSemanticModelId(option.value.semanticModelId);
    }
  };

  return (
    <React.Fragment>
      <Form.Field>
        <Form.Help>Select a workspace</Form.Help>
        <Form.Control>
          <Select
            name="workspace"
            classNamePrefix="matik-select"
            aria-label="Select a workspace"
            placeholder="Select a workspace"
            isDisabled={workspaces.isLoading}
            value={queryObj?.workspaceOption ?? null}
            onChange={selectWorkspaceOption}
            options={workspaceSelectOptions}
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Help>Select a report</Form.Help>
        <Form.Control>
          <Select
            name="report"
            classNamePrefix="matik-select"
            aria-label="Select a report"
            placeholder="Select a report"
            isDisabled={!workspaceId || reports.isLoading}
            value={queryObj?.reportOption ?? null}
            onChange={selectReportOption}
            options={reportSelectOptions}
          />
        </Form.Control>
      </Form.Field>

      {reportEmbedToken.data !== null && reportEmbedURL !== null && (
        <PowerBIEmbed embedToken={reportEmbedToken.data} embedURL={reportEmbedURL} />
      )}
    </React.Fragment>
  );
};

PowerBIForm.propTypes = {
  queryObj: PropTypes.object,
  dataSourceId: PropTypes.string,
};

const PowerBIEmbed = (props) => {
  const { embedToken, embedURL } = props;

  const embeddedContainerRef = useRef(null);

  const embedConfiguration = {
    type: 'report',
    tokenType: pbi.models.TokenType.Embed,
    accessToken: embedToken,
    embedUrl: embedURL,
    permissions: pbi.models.Permissions.Read,
    settings: {
      navContentPaneEnabled: false,
      filterPaneEnabled: false,
      layoutType: pbi.models.LayoutType.Custom,
      customLayout: {
        displayOption: pbi.models.DisplayOption.FitToPage,
      },
    },
  };

  useEffect(() => {
    const powerBI = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory,
    );

    powerBI.embed(embeddedContainerRef.current, embedConfiguration);
  }, []);

  return (
    <React.Fragment>
      <div ref={embeddedContainerRef} className="w-full" style={{ height: '800px' }} />
    </React.Fragment>
  );
};

PowerBIEmbed.propTypes = {
  embedToken: PropTypes.string,
  embedURL: PropTypes.string,
};

/**
 * Queries the access token from a Power BI data source.
 *
 * @property {string} dataSourceId - Data source ID.
 */
const usePowerBIEmbedToken = (dataSourceId, reportId, semanticModelId) => {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ['power_bi', dataSourceId, 'embed_token', reportId, semanticModelId],
    queryFn: () => {
      return API.post(
        `/data_sources/${dataSourceId}/power_bi/embed_token`,
        {
          report_id: reportId,
          semantic_model_id: semanticModelId,
        },
        (response) => {
          const embed_token = response?.data?.embed_token?.token ?? '';
          return embed_token;
        },
        (error) => {
          throw error;
        },
      );
    },
    placeholderData: null,
    enabled: reportId !== null && semanticModelId !== null,
  });

  return {
    data,
    error,
    isLoading,
    isError,
  };
};

/**
 * Queries the workspaces from a Power BI data source.
 *
 * @property {string} dataSourceId - Data source ID.
 */
const usePowerBIWorkspaces = (dataSourceId) => {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ['power_bi', dataSourceId, 'workspaces'],
    queryFn: () => {
      return API.get(
        `/data_sources/${dataSourceId}/power_bi/workspaces`,
        (response) => {
          const workspaces = response?.data?.workspaces?.value ?? [];
          return workspaces;
        },
        (error) => {
          throw error;
        },
      );
    },
    placeholderData: [],
  });

  return {
    data,
    error,
    isLoading,
    isError,
  };
};

/**
 * Queries the reports in a workspace from a Power BI data source.
 *
 * @property {string} dataSourceId - Data source ID.
 * @property {string} workspaceId - Workspace ID.
 */
const usePowerBIReports = (dataSourceId, workspaceId) => {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ['power_bi', dataSourceId, 'workspaces', workspaceId, 'reports'],
    queryFn: () => {
      return API.get(
        `/data_sources/${dataSourceId}/power_bi/workspaces/${workspaceId}/reports`,
        (response) => {
          const reports = response?.data?.workspaces?.value ?? [];
          return reports;
        },
        (error) => {
          throw error;
        },
      );
    },
    placeholderData: [],
    enabled: workspaceId !== null,
  });

  return {
    data,
    error,
    isLoading,
    isError,
  };
};

export default PowerBIForm;
