import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Constants from '../Constants';
import UserAvatar from 'react-user-avatar';
import Indicator from './Indicator';

const SizeOptions = {
  xxs: 'xxs',
  xs: 'xs',
  s: 's',
  m: 'm',
};

const StateOptions = {
  default: 'default',
  disabled: 'disabled',
  hidden: 'hidden',
};

const LuminosityMap = {
  light: { border: 200, bg: 100, text: 'matik-black' },
  medium: { border: 500, bg: 500, text: 'matik-white' },
};

function Tag({
  label,
  labelToolTip = '',
  iconName,
  rightIconName,
  avatarInfo,
  onClose,
  indicatorColor,
  state = 'default',
  size = 'm',
  color = 'white',
  luminosity = 'light',
  iconToolTip = '',
  rightIconToolTip = '',
  onRemoveToolTip,
  id = null,
  onClick = () => {},
  rightIconOnClick = () => {},
  actionLabel = '',
  actionOnClick = () => {},
}) {
  let colorClasses = 'border border-grey-300 bg-matik-white hover:bg-blue-100';
  let iconTextClasses = 'text-grey-700';
  let textClass = 'text-matik-black';
  if (color === 'gradient') {
    colorClasses = 'bg-gradient-to-r from-matik-gradient-start to-matik-gradient-end text-matik-white';
    iconTextClasses = 'text-matik-white';
    textClass = 'text-matik-white';
  } else if (color === 'matik-black') {
    colorClasses = 'bg-matik-black text-matik-white';
    textClass = iconTextClasses = 'text-matik-white';
  } else if (color !== 'white') {
    const bgClass = `bg-${color}-${LuminosityMap[luminosity]['bg']}`;
    const borderClass = `border border-${color}-${LuminosityMap[luminosity]['border']}`;
    colorClasses = `${bgClass} ${borderClass}`;
    textClass = `text-${LuminosityMap[luminosity]['text']}`;
  }
  if (state === StateOptions.disabled || state === StateOptions.hidden) {
    if (!color) {
      colorClasses = 'bg-grey-100 text-grey-400';
    }
    iconTextClasses = 'text-grey-400';
    textClass = 'text-grey-400';
  }
  let layoutClasses = 'h-8 px-3 gap-2 text-sm';
  let iconSize = 20;
  switch (size) {
    case SizeOptions.s:
      layoutClasses = 'h-6 px-2 gap-1.5 text-sm';
      iconSize = 16;
      break;
    case SizeOptions.xs:
      layoutClasses = 'h-5 px-2 gap-1 text-xs';
      iconSize = 12;
      break;
    case SizeOptions.xxs:
      layoutClasses = 'h-4 px-1.5 gap-1 text-2xs';
      iconSize = 12;
      break;
  }
  return (
    <button
      id={id}
      onClick={onClick}
      className={`rounded-4xl ${colorClasses} flex items-center ${layoutClasses}`}
      disabled={state === 'disabled'}
    >
      {iconName && (
        <div data-tooltip-content={iconToolTip} data-tooltip-id="matik-tooltip">
          <span className={`${iconTextClasses} flex`}>
            <Icon name={iconName} size={iconSize} />
          </span>
        </div>
      )}
      {avatarInfo && (
        <span className="flex text-matik-white">
          <UserAvatar colors={Constants.AVATAR_COLORS} size={24} name={avatarInfo.name} src={avatarInfo.src} />
        </span>
      )}
      <span className={textClass} data-tooltip-content={labelToolTip} data-tooltip-id="matik-tooltip">
        {label}
      </span>
      {actionLabel && (
        <a href="#" onMouseDown={(e) => e.stopPropagation()} onClick={actionOnClick}>
          ({actionLabel})
        </a>
      )}
      {rightIconName && (
        <div data-tooltip-content={rightIconToolTip} data-tooltip-id="matik-tooltip">
          <a
            href="#"
            onMouseDown={(e) => e.stopPropagation()}
            onClick={rightIconOnClick}
            className={`ml-2 ${iconTextClasses} flex`}
          >
            <Icon name={rightIconName} size={12} />
          </a>
        </div>
      )}
      {onClose && (
        <div data-tooltip-content={onRemoveToolTip} data-tooltip-id="matik-tooltip">
          <a href="#" onMouseDown={(e) => e.stopPropagation()} onClick={onClose} className={`${iconTextClasses} flex`}>
            <Icon name="dismiss" size={12} />
          </a>
        </div>
      )}
      {indicatorColor && <Indicator color={indicatorColor} />}
    </button>
  );
}

Tag.propTypes = {
  avatarInfo: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
  }),
  color: PropTypes.string,
  iconName: PropTypes.string,
  iconToolTip: PropTypes.string,
  id: PropTypes.string,
  indicatorColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelToolTip: PropTypes.string,
  luminosity: PropTypes.oneOf(Object.keys(LuminosityMap)),
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onRemoveToolTip: PropTypes.string,
  rightIconName: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SizeOptions)),
  state: PropTypes.oneOf(['default', 'disabled', 'hidden']),
  actionLabel: PropTypes.string,
  actionOnClick: PropTypes.func,
  rightIconOnClick: PropTypes.func,
  rightIconToolTip: PropTypes.string,
};

export default Tag;
