import { union, difference, uniq } from 'lodash';

export const LOOKER_QUERY_VERSION = {
  v1: 'v1',
  v2: 'v2',
};

export function getQueryVersion(queryObj) {
  return queryObj.version || LOOKER_QUERY_VERSION.v1;
}

export function convertV1QueryToV2(queryObj) {
  const lookerQuery = queryObj.query;

  // Table calc dynamic fields end up treated as return fields by being adding by Looker automatically
  // even if they aren't explicitly in the query's fields
  const tableCalcDynamicFieldNames = (lookerQuery.dynamic_fields || [])
    .filter(isTableCalculation)
    .map(getDynamicFieldName);
  let fieldNames = union(lookerQuery.fields || [], tableCalcDynamicFieldNames);
  const cachedDynamicFieldNames = (lookerQuery.cached_dynamic_fields || []).map(getDynamicFieldName);
  let hiddenFields = difference(union(lookerQuery.cached_fields || [], cachedDynamicFieldNames), fieldNames);

  if ((lookerQuery.dynamic_fields || []).length === 0) {
    // Emulate v1 bug where hiding all dynamic fields prevented treating them as hidden fields and removed them
    // from the query entirely
    hiddenFields = hiddenFields.filter((name) => cachedDynamicFieldNames.indexOf(name) < 0);
    fieldNames = fieldNames.filter((name) => cachedDynamicFieldNames.indexOf(name) < 0);
  }
  let userInputs = {};
  userInputs.version = LOOKER_QUERY_VERSION.v2;
  userInputs.source = queryObj.source;

  if (queryObj.source === 'dashboard') {
    userInputs.dashboard_element_id = queryObj.selectedDashboardElement.id;
    userInputs.dashboard_id = queryObj.selectedDashboard.id;
    userInputs.model = null;
    userInputs.view = null;
    userInputs.hidden_fields = hiddenFields;
  } else if (queryObj.source === 'model') {
    userInputs.dashboard_element_id = null;
    userInputs.dashboard_id = null;
    userInputs.model = queryObj.selectedModel.name;
    userInputs.view = queryObj.selectedModelExplore.name;
    userInputs.hidden_fields = [];
  }
  userInputs.pivots = lookerQuery.pivots || [];
  userInputs.sorts = lookerQuery.sorts || [];
  userInputs.filters = lookerQuery.filters || [];
  userInputs.limit = lookerQuery.limit || null;
  userInputs.query_timezone = lookerQuery.query_timezone || null;
  userInputs.fields = fieldNames;
  userInputs.apply_formatting = lookerQuery.apply_formatting || false;
  userInputs.return_field_mapping = lookerQuery.return_field_mapping || null;
  // If output_snapshot is undefined, then default to true
  userInputs.output_snapshot = lookerQuery.output_snapshot === undefined ? true : lookerQuery.output_snapshot;
  return userInputs;
}

export function getDynamicFieldName(dynamicField) {
  // Schema for Looker dynamic fields is wildly inconsistent and has probably changed over time.
  if (dynamicField.category) {
    return dynamicField[dynamicField.category];
  }
  if (dynamicField.measure) {
    return dynamicField.measure;
  }
  if (dynamicField.table_calculation) {
    return dynamicField.table_calculation;
  }
  if (dynamicField.label) {
    return dynamicField.label;
  }
  return '';
}

export function isTableCalculation(dynamicField) {
  return !!(dynamicField.category === 'table_calculation' || dynamicField.table_calculation);
}

export function parseFieldNamesFromExpression(expression) {
  if (!expression) {
    return [];
  }
  return uniq([...expression.matchAll(/\$\{([^:}]+)(?::[^}]+)?}/g)].map((match) => match[1]));
}

export function initializeQuery(initialValues) {
  return {
    version: LOOKER_QUERY_VERSION.v2,
    source: null,
    dashboard_element_id: null,
    dashboard_id: null,
    model: null,
    view: null,
    hidden_fields: [],
    pivots: [],
    sorts: [],
    filters: [],
    limit: null,
    query_timezone: null,
    fields: [],
    apply_formatting: false,
    return_field_mapping: null,
    output_snapshot: true,
    ...(initialValues || {}),
  };
}

export function updateV2QueryWithLookerQueryData(v2Query, lookerQuery) {
  const filters = lookerQuery['filters']
    ? Object.keys(lookerQuery['filters']).map((filterField) => {
        const filterVal = lookerQuery['filters'][filterField];
        return { field: filterField, val: filterVal };
      })
    : [];
  const tableCalcDynamicFieldNames = (lookerQuery.dynamic_fields || [])
    .filter(isTableCalculation)
    .map(getDynamicFieldName);
  const fields = union(lookerQuery['fields'] || [], tableCalcDynamicFieldNames);
  return {
    ...v2Query,
    pivots: lookerQuery.pivots,
    sorts: lookerQuery.sorts,
    filters: filters,
    limit: lookerQuery.limit,
    query_timezone: lookerQuery.query_timezone || null,
    fields: fields,
  };
}
