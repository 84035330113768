import React from 'react';
import PropTypes from 'prop-types';
import CheckboxWithLabel from 'components/shared/CheckboxWithLabel';

const LookerOutputScreenshot = (props) => {
  const outputScreenshot = !!props.outputSnapshot;
  const onChange = (e) => {
    const value = e.target.checked;
    props.onOutputSnapshot(value);
  };
  return (
    <div className="mbl">
      <CheckboxWithLabel
        checked={outputScreenshot}
        id="looker-output-screenshot"
        name="looker-output-screenshot"
        disabled={props.isReadOnly}
        label="Output as screenshot of Looker chart"
        onChange={onChange}
      />
    </div>
  );
};

LookerOutputScreenshot.propTypes = {
  isReadOnly: PropTypes.bool,
  outputSnapshot: PropTypes.bool,
  onOutputSnapshot: PropTypes.func,
};

export default LookerOutputScreenshot;
