import API from 'lib/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { updateSuccessBanner, updateErrorBanner, deleteSuccessBanner } from 'components/shared/AccessUpdateBanners';
import { useDispatch } from 'react-redux';
import { addNotificationSuccess } from 'redux/notifications/action';
import useMultiFetch from 'lib/hooks/useMultiFetch';

/** Fetch the accesses for a given type. If no IDs are given, it will fetch accesses for all of that type.
 * @param itemType e.g. `template`, `dynamic_content`, `presentation`
 * @param itemIds an array of item IDs to fetch the accesses for. e.g. the template id. When empty, fetch
 * accesses for all.
 */
const useAccesses = (itemType, itemIds) => {
  const queryClient = useQueryClient();
  if (!itemIds) {
    itemIds = [];
  }
  const queryKey = ['access', itemType, itemIds];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        `/accesses/all/${itemType}/${itemIds.length > 0 ? `?filter=${JSON.stringify({ id: itemIds })}` : ''}`,
        (response) => response.data, // like { <item id>: [{ access data }, ...  ], ... }
        API.defaultError,
      );
    },
    enabled: !!itemType,
  });

  const invalidate = (type) => {
    const queryKey = ['access'];
    if (type ?? itemType) {
      queryKey.push(type ?? itemType);
    }
    queryClient.invalidateQueries({ queryKey });
  };

  return {
    isPending: isLoading,
    isError,
    data,
    error,
    invalidate,
  };
};

export default useAccesses;

export const useAccessMutator = () => {
  const queryClient = useQueryClient();

  const del = (accesses) => {
    const byItemType = {};
    accesses.forEach((access) => {
      const ids = byItemType[access.item_type] || [];
      ids.push(access.id);
      byItemType[access.item_type] = ids;
    });

    const deletes = Object.keys(byItemType).map((itemType) => {
      return API.post('/accesses/bulk_delete/', { item_type: itemType, access_ids: byItemType[itemType] }).then(
        (data) => {
          queryClient.invalidateQueries({ queryKey: ['access'] });
          queryClient.invalidateQueries({ queryKey: ['my_generation_issues'] });
          queryClient.invalidateQueries({ queryKey: ['generation_issues'] });
          return data;
        },
      );
    });
    return Promise.all(deletes).then(() => {
      deleteSuccessBanner({ accesses });
    });
  };

  const update = (itemType, itemIds, permission, accessorsToAdd) => {
    const data = {
      item_type: itemType,
      item_ids: itemIds,
      permission: permission,
      accesses: accessorsToAdd,
    };
    return API.post(
      '/accesses/bulk/',
      data,
      (response) => {
        queryClient.invalidateQueries({ queryKey: ['access'] });
        queryClient.invalidateQueries({ queryKey: ['my_generation_issues'] });
        queryClient.invalidateQueries({ queryKey: ['generation_issues'] });
        updateSuccessBanner({ itemIds, permission, accessorsToAdd });
        return response.data;
      },
      (err) => {
        updateErrorBanner({ err, itemIds, permission, accessorsToAdd });
      },
    );
  };

  return {
    del,
    update,
  };
};

/** Fetch all the accessors who can be granted access to the given item.
 * @param {*} itemType The Constants.ItemTypes of the item in question. If missing, return all
 * potential accessors without restriction.
 * @param {*} itemId The id of the item in question (optional)
 * @returns ```
 * {
 *   isPending,
 *   isError,
 *   data: [ <accessor>, ... ],
 *   error
 * }
 * ```
 */
export const usePotentialAccessors = (itemType, itemId) => {
  const queryKey = ['potential_accessors', itemType, itemId];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        itemId ? `/accesses/potential_accessors/${itemType}/${itemId}/` : `/accesses/potential_accessors/${itemType}/`,
        (response) => response.data, // like [ { type: "group", id: 1, name: ... }, ... ]
        API.defaultError,
      );
    },
    staleTime: 0, // better to invalidate when accesses are updated and group membership changes
  });

  return {
    isPending: isLoading,
    isError,
    error,
    data,
  };
};

/** Get accessor info. */
export const useAccessor = (type, id) => {
  const queryKey = ['accessor', type, parseInt(id)];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        `/accesses/accessors/${type}/${id}/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: type && id > 0,
  });

  return {
    isPending: isLoading,
    isError,
    error,
    data,
  };
};

export const useGenerationIssues = (templateIds) => {
  const getQueryKey = (key) => ['generation_issues', key];
  const getFetchUri = (ids) => `/accesses/generation_issues/?filter=${JSON.stringify({ template_ids: ids })}`;
  const getEntityId = (templateGenerationIssues) => templateGenerationIssues.id;
  const resultById = useMultiFetch(templateIds, getQueryKey, getFetchUri, getEntityId, 0);
  const generationIssues = {};
  for (const templateId in resultById) {
    const templateGenerationIssues = resultById[templateId]?.data?.generation_issues;
    if (templateGenerationIssues) {
      generationIssues[templateId] = templateGenerationIssues;
    }
  }
  return generationIssues;
};

export const useMyGenerationIssues = (templateIds) => {
  const getQueryKey = (id) => ['my_generation_issues', parseInt(id)];
  const getFetchUri = (ids) => `/accesses/my_generation_issues/?filter=${JSON.stringify({ template_ids: ids })}`;
  const getEntityId = (issues) => issues.template_id;

  return useMultiFetch(templateIds, getQueryKey, getFetchUri, getEntityId, 60 * 1000);
};

export const useGenerationIssuesMutator = () => {
  const dispatch = useDispatch();

  return {
    requestAccess: (templateId) => {
      return API.post(`/accesses/generation_issues/${templateId}/request_access/`, {}, () => {
        dispatch(addNotificationSuccess('Access requested'));
      });
    },
  };
};
