import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import WithInstantSearch from '../search/WithInstantSearch';
import WithLoadingAndEmpty from '../WithLoadingAndEmpty';
import WithUserContext from '../WithUserContext';
import PresentationsList from './PresentationsList';
import API from '../../../lib/api';
import Presentation from './Presentation';
import { UserContext } from '../../UserContext';
import PresentationsSidebar from './PresentationsSidebar';
import withPresentationContextConsumer from './WithPresentationContextConsumer';

class PresentationsContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templateChanged: false,
    };
  }

  render() {
    const renderPresentationsList = (props) => {
      return (
        <div className="wrapper">
          <PresentationsSidebar
            pagination={this.props.pagination}
            fetchItems={this.props.fetchItems}
            isPaginating={this.props.isPaginating}
            searchAttributes={this.props.searchAttributes}
            onSearchStateChange={this.props.onSearchStateChange}
            searchState={this.props.searchState}
            isSearching={this.props.isSearching}
            inputs={this.props.inputs}
            onRegenerateClick={this.onRegenerateClick}
            updateIsFetchingPresentation={this.props.updateIsFetchingPresentation}
          />
          <div className="main">
            <PresentationsList
              isBulk={this.props.isBulk}
              bulkPresentationId={this.props.bulkPresentationId}
              {...props}
              initialPresentations={this.props.initialPresentations}
              presentations={this.props.presentations}
              setSearchHits={this.props.setSearchHits}
              searchHits={this.props.searchHits}
              onPresentationDelete={this.onPresentationDelete}
              onPresentationBulkDelete={this.props.onPresentationBulkDelete}
              onPresentationRegenerate={this.onPresentationRegenerate}
              onSearchStateChange={this.props.onSearchStateChange}
              searchState={this.props.searchState}
              searchAttributes={this.props.searchAttributes}
              isSearching={this.props.isSearching}
              pagination={this.props.pagination}
              inputs={this.props.inputs}
              isPaginating={this.props.isPaginating}
              fetchItems={this.props.fetchItems}
              user={this.props.user}
              onRegenerateClick={this.onRegenerateClick}
              updateIsFetchingPresentation={this.props.updateIsFetchingPresentation}
            />
          </div>
        </div>
      );
    };
    const renderPresentation = (props) => {
      return (
        <Presentation
          presentationsPath={props.match.path === '/:tab/:id' ? '/presentations' : '/create/presentations'}
          presentationId={props.match.params.id}
          onRegenerateClick={this.onRegenerateClick}
        />
      );
    };
    return (
      <Switch>
        <Route path={`${this.props.match.path}/:id`} render={renderPresentation} />
        <Route path={this.props.match.path} render={renderPresentationsList} />
      </Switch>
    );
  }

  onPresentationDelete = (presentationToDelete) => {
    API.delete(
      `/presentations/${presentationToDelete.id}/`,
      () => {
        this.props.onPresentationDelete(presentationToDelete);
      },
      API.defaultError,
    );
  };

  onRegenerateClick = (e, currentPresentation, overrideConditions = false) => {
    e?.preventDefault();
    e?.stopPropagation();
    let urlPrefix = '';
    if (!this.props.isProducer) {
      urlPrefix += '/create';
    }
    let createUrl = `${urlPrefix}/templates/${currentPresentation.template.id}`;
    this.props.presentationContext.setRegenerate(true);
    this.props.presentationContext.setPresentationToRegenerate(currentPresentation);
    this.props.presentationContext.setRegenerateTemplateChanged(this.state.templateChanged);
    this.props.presentationContext.setOverrideConditions(overrideConditions);
    this.props.history.push(createUrl);
  };
}

PresentationsContent.propTypes = {
  flags: PropTypes.object,
  isBulk: PropTypes.bool,
  bulkPresentationId: PropTypes.any,
  initialPresentations: PropTypes.array,
  presentations: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  setSearchHits: PropTypes.func,
  searchHits: PropTypes.array,
  match: PropTypes.any,
  onPresentationBulkDelete: PropTypes.func,
  onPresentationDelete: PropTypes.func,
  onPresentationRegenerate: PropTypes.func,
  onSearchStateChange: PropTypes.func,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  isLoading: PropTypes.bool,
  inputs: PropTypes.object,
  user: PropTypes.object,
  isProducer: PropTypes.bool,
  presentationContext: PropTypes.object,
  history: PropTypes.object,
  isFetchingPresentation: PropTypes.bool,
  updateIsFetchingPresentation: PropTypes.func,
};

PresentationsContent.contextType = UserContext;

export default WithLoadingAndEmpty(
  withRouter(
    WithUserContext(withPresentationContextConsumer(WithInstantSearch(withLDConsumer()(PresentationsContent)))),
  ),
);
