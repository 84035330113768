import React, { Component } from 'react';
import { Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import DropdownMenu from '../../lib/DropdownMenu';
import Tag from '../../lib/Tag';
import ListItem from '../../lib/ListItem';
import Icon from '../../lib/Icon';

class InputsList extends Component {
  render() {
    if (!this.props.inputsInQueryString || Object.values(this.props.inputsInQueryString).length === 0) {
      return (
        <Form.Control>
          <i className="pll">Configurable inputs will appear here when included below.</i>
        </Form.Control>
      );
    }

    return (
      <Form.Control className="flex gap-3 pl-3">
        {Object.values(this.props.inputsInQueryString).map((input) => {
          const isMappedInput =
            input.input_mapping &&
            !isEmpty(input.input_mapping) &&
            !isEqual(Object.keys(input.input_mapping)[0], Object.values(input.input_mapping)[0]);
          const tagElement = (
            <Tag
              color="yellow"
              onClick={(e) => this.onClick(e, input.name)}
              iconName={isMappedInput ? 'input_mapping' : null}
              label={input.name}
            ></Tag>
          );
          return (
            <React.Fragment key={input.name}>
              {isMappedInput ? (
                <DropdownMenu dropdownTrigger={tagElement}>
                  <div className="m-3">
                    <p>
                      <Form.Label className="input-pill-mapping-dropdown-title">Mapped Input</Form.Label>
                    </p>
                    <ListItem
                      icon={<Icon name="arrow_hook_down_left" size={20} />}
                      title={Object.keys(input.input_mapping)[0]}
                      subtitle="Visible to the End User"
                    />
                    <ListItem
                      icon={<Icon name="eye" size={20} theme="filled" />}
                      title={Object.values(input.input_mapping)[0]}
                      subtitle="Field actually inserted"
                    />
                  </div>
                </DropdownMenu>
              ) : (
                tagElement
              )}
            </React.Fragment>
          );
        })}
      </Form.Control>
    );
  }

  onClick = (e, inputName) => {
    e.preventDefault();
    if (this.props.isReadOnly) {
      return false;
    }
    this.props.toggleCurrentInput(inputName);
  };
}

InputsList.propTypes = {
  isReadOnly: PropTypes.bool,
  inputsInQueryString: PropTypes.object,
  selectedInputName: PropTypes.string,
  toggleCurrentInput: PropTypes.func,
};

export default InputsList;
