import { isEqual } from 'lodash';

class FormChange {
  constructor(initialValues) {
    this.initialValues = initialValues;
  }

  hasChanged(newState) {
    for (const key in this.initialValues) {
      if (newState[key] === undefined && this.initialValues[key] !== undefined) {
        return true;
      }
      const isChanged = !isEqual(newState[key], this.initialValues[key]);
      if (isChanged) {
        return true;
      }
    }

    return false;
  }

  updateInitialValues(newInitialValues) {
    this.initialValues = { ...newInitialValues };
  }
}

export default FormChange;
