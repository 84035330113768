import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LookerPivots from './LookerPivots';
import LookerTimezone from './LookerTimezone';
import LookerApplyFormatting from './LookerApplyFormatting';
import LookerOutputScreenshot from './LookerOutputScreenshot';
import Constants from '../../../Constants';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { cloneDeep } from 'lodash';
import ApiSorts from './ApiSorts';
import ApiLimit from './ApiLimit';
import ApiFilters from './ApiFilters';
import { getDynamicFieldName, isTableCalculation, parseFieldNamesFromExpression } from '../../../../lib/looker';
import LookerReturnFields from './LookerReturnFields';
import InputMapping from '../InputMapping';
import { union } from 'lodash';

function LookerQueryForm({ dynamicFields, entityType, fields, input, inputMapping, onInputMappingUpdate, queryObj }) {
  const dynamicContentContext = useContext(DynamicContentContext);

  const tableCalcDependencies = Object.fromEntries(
    (dynamicFields || [])
      .filter(isTableCalculation)
      .map((tableCalc) => [getDynamicFieldName(tableCalc), parseFieldNamesFromExpression(tableCalc.expression)]),
  );

  const onSortsUpdate = (sortsArray) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.sorts = sortsArray;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onLimitUpdate = (limitValue) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.limit = limitValue;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onFilterUpdate = (filterArray) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.filters = filterArray;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onPivotsUpdate = (pivots) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.pivots = pivots;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onTimezoneUpdate = (timezone) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.query_timezone = timezone;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onApplyFormatting = (applyFormatting) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.apply_formatting = applyFormatting;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onOutputSnapshot = (snapshotValue) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.output_snapshot = snapshotValue;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const returnFieldsArray = queryObj.fields || [];
  const returnFieldMapping = queryObj.return_field_mapping || {};

  const onReturnFieldAdd = (addedOption) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.fields = union([...returnFieldsArray, addedOption.value]);
    updatedQueryObj.hidden_fields = hiddenFieldsArray.filter((removedValue) => removedValue !== addedOption.value);
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onReturnFieldRemove = (removedOption) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.fields = returnFieldsArray.filter((removedValue) => removedValue !== removedOption.value);
    updatedQueryObj.hidden_fields = hiddenFieldsArray.filter((removedValue) => removedValue !== removedOption.value);
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onReturnFieldSelectAll = (allOptions) => {
    const returnFields = allOptions.map((fieldOption) => fieldOption.value);
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.fields = returnFields.filter((returnField) => hiddenFieldsArray.indexOf(returnField) < 0);
    updatedQueryObj.hidden_fields = returnFields.filter((returnField) => hiddenFieldsArray.indexOf(returnField) >= 0);
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onReturnFieldClear = () => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.fields = [];
    updatedQueryObj.hidden_fields = [];
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const hiddenFieldsArray = queryObj.hidden_fields || [];

  const onReturnFieldHide = (hiddenOption) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.hidden_fields = union([...hiddenFieldsArray, hiddenOption]);
    updatedQueryObj.fields = returnFieldsArray.filter((hiddenValue) => hiddenValue !== hiddenOption);
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onReturnFieldShow = (shownOption) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.hidden_fields = hiddenFieldsArray.filter((shownField) => shownField !== shownOption);
    updatedQueryObj.fields = union([...returnFieldsArray, shownOption]);
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onReturnFieldMappingUpdate = (returnField, newAlias) => {
    const updatedQueryObj = cloneDeep(queryObj);
    if (newAlias === '') {
      delete updatedQueryObj.return_field_mapping[returnField];
    } else {
      updatedQueryObj.return_field_mapping = { ...returnFieldMapping, [returnField]: newAlias };
    }
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const validateField = (fieldName) => {
    return fields.includes(fieldName)
      ? undefined
      : 'This field can no longer be found in Looker. Remove it or replace it with a valid field.';
  };

  const validatePivotField = (fieldName) => {
    const fieldNameErrorMessage = validateField(fieldName);
    if (fieldNameErrorMessage) {
      return fieldNameErrorMessage;
    }
    if (queryObj.fields.includes(fieldName)) {
      return undefined;
    }
    if (queryObj.hidden_fields.includes(fieldName)) {
      return 'This field cannot be used to pivot results if it is hidden. Please unhide this field in your fields to return.';
    }
    return 'This field cannot be used to pivot results if it is not included in the fields to return and not hidden. Please add this field to your fields to return.';
  };

  return (
    <React.Fragment>
      <ApiFilters
        filterArray={queryObj.filters}
        allFields={fields}
        supportedOperators={Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE[Constants.DATA_SOURCE_TYPES.looker]}
        inputs={dynamicContentContext.existingInputs}
        isReadOnly={dynamicContentContext.isReadOnly}
        isInputPopoverDisabled={entityType === 'input'}
        onFilterUpdate={onFilterUpdate}
        validateField={validateField}
      />
      <ApiSorts
        allFields={fields}
        sortsArray={queryObj.sorts}
        isReadOnly={dynamicContentContext.isReadOnly}
        onSortsUpdate={onSortsUpdate}
        validateField={validateField}
      />
      <LookerPivots
        pivots={queryObj.pivots}
        fields={fields}
        isReadOnly={dynamicContentContext.isReadOnly}
        onPivotsUpdate={onPivotsUpdate}
        validateField={validatePivotField}
      />
      <ApiLimit
        limitValue={queryObj.limit}
        isReadOnly={dynamicContentContext.isReadOnly}
        onLimitUpdate={onLimitUpdate}
      />
      <LookerTimezone
        timezone={queryObj.query_timezone}
        isReadOnly={dynamicContentContext.isReadOnly}
        onTimezoneUpdate={onTimezoneUpdate}
      />
      <LookerReturnFields
        queryObj={queryObj}
        allFields={fields}
        tableCalcDependencies={tableCalcDependencies}
        onReturnFieldAdd={onReturnFieldAdd}
        onReturnFieldRemove={onReturnFieldRemove}
        onReturnFieldSelectAll={onReturnFieldSelectAll}
        onReturnFieldClear={onReturnFieldClear}
        onReturnFieldHide={onReturnFieldHide}
        onReturnFieldShow={onReturnFieldShow}
        onReturnFieldMappingUpdate={onReturnFieldMappingUpdate}
      />
      {onInputMappingUpdate && (
        <InputMapping
          input={input}
          inputMapping={inputMapping}
          onInputMappingUpdate={onInputMappingUpdate}
          returnFieldsArray={returnFieldsArray}
        />
      )}
      <LookerApplyFormatting
        applyFormatting={queryObj.apply_formatting}
        isReadOnly={dynamicContentContext.isReadOnly}
        onApplyFormatting={onApplyFormatting}
      />
      {dynamicContentContext.dynamicContentType === Constants.DynamicContentTypes.IMAGE && (
        <LookerOutputScreenshot
          outputSnapshot={queryObj.output_snapshot}
          onOutputSnapshot={onOutputSnapshot}
          isReadOnly={dynamicContentContext.isReadOnly}
        />
      )}
    </React.Fragment>
  );
}

LookerQueryForm.propTypes = {
  dynamicFields: PropTypes.array,
  fields: PropTypes.array,
  inputsInQueryString: PropTypes.object,
  queryObj: PropTypes.object,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
};

export default LookerQueryForm;
