import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import InputWithOptionalInputs from '../../../shared/InputWithOptionalInputs';
import AddRemoveButtons from '../../../shared/AddRemoveButtons';
import { CreatableSelect, Select } from '../../../shared/FormSelect';
import info_icon from '../../../../images/info_icon.svg';
import { cloneDeep } from 'lodash';

function ApiFilters({
  filterArray,
  onFilterUpdate,
  allFields,
  supportedOperators,
  inputs,
  isReadOnly,
  noOptionsMessage,
  isInputPopoverDisabled,
  validateField,
}) {
  const fieldsToFilter = filterArray?.map((filterObj) => filterObj.field) || [];

  const filtersContainsField = (field) => fieldsToFilter.indexOf(field) > -1;

  const addFilter = (e) => {
    e.preventDefault();
    let updatedFilterArray = cloneDeep(filterArray);
    if (!filterArray) {
      updatedFilterArray = [];
    }
    const filtersNotUsed = allFields.filter((field) => !filtersContainsField(field)) || [];
    const operators = supportedOperators || [];
    updatedFilterArray.push({ field: filtersNotUsed[0], val: '', operator: operators[0] });
    onFilterUpdate(updatedFilterArray);
  };

  const removeFilter = (e, idx) => {
    e.preventDefault();
    let updatedFilterArray = cloneDeep(filterArray);
    let filters = [];
    for (let i = 0; i < updatedFilterArray.length; i++) {
      if (i !== idx) {
        filters.push(updatedFilterArray[i]);
      }
    }
    updatedFilterArray = filters;
    onFilterUpdate(updatedFilterArray);
  };

  const onFilterFieldChange = (obj, action, idx) => {
    if (action.action === 'select-option' || action.action === 'create-option') {
      const field = obj.value;
      const updatedFilterArray = cloneDeep(filterArray);
      updatedFilterArray[idx]['field'] = field;
      onFilterUpdate(updatedFilterArray);
    }
  };

  const onFilterInputChange = (newValue, idx) => {
    const updatedFilterArray = cloneDeep(filterArray);
    updatedFilterArray[idx]['val'] = newValue;
    onFilterUpdate(updatedFilterArray);
  };

  const onOperatorChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const operator = obj.value;
      const updatedFilterArray = cloneDeep(filterArray);
      updatedFilterArray[idx]['operator'] = operator;
      onFilterUpdate(updatedFilterArray);
    }
  };

  let body = (
    <a href="#dummy" onClick={(e) => addFilter(e)}>
      Add Filter
    </a>
  );
  if (filterArray && filterArray.length > 0) {
    body = filterArray.map((filter, idx) => {
      const filterValue = filter.val;
      const filterField = filter.field;
      const filterOperator = filter.operator;
      return renderFilter(filterField, filterOperator, filterValue, idx);
    });
  }

  function renderFilter(filterField, filterOperator, filterValue, idx) {
    const errorMessage = validateField?.(filterField);
    return (
      <Form.Field kind="group" key={`${filterField}_${idx}`}>
        <AddRemoveButtons
          idx={idx}
          includeAdd={idx === filterArray.length - 1}
          addEntity={addFilter}
          removeEntity={removeFilter}
          isReadOnly={isReadOnly}
        >
          <Form.Control style={{ flex: '3 1' }}>
            <CreatableSelect
              value={filterField ? { label: filterField, value: filterField } : null}
              classNames={{
                control: () => (errorMessage ? 'select-error' : ''),
              }}
              name="filterField"
              classNamePrefix="matik-select"
              isDisabled={isReadOnly}
              noOptionsMessage={() => (noOptionsMessage ? noOptionsMessage : 'No options')}
              onChange={(obj, action) => onFilterFieldChange(obj, action, idx)}
              aria-label="Select Filter Field on Object"
              options={allFields
                .filter((field) => !filtersContainsField(field) || field === filterField)
                .map((field) => ({ label: field, value: field }))}
            />
            <Form.Help color="danger">{errorMessage}</Form.Help>
          </Form.Control>
          {renderOperators(filterOperator, idx)}
          <Form.Control style={{ flex: '3 1' }}>
            <InputWithOptionalInputs
              onChange={(newValue) => onFilterInputChange(newValue, idx)}
              value={filterValue}
              inputs={inputs}
              isReadOnly={isReadOnly}
              isInputPopoverDisabled={isInputPopoverDisabled}
            />
          </Form.Control>
        </AddRemoveButtons>
      </Form.Field>
    );
  }

  function renderOperators(filterOperator, idx) {
    const style = { flex: '.5 1' };
    if (!supportedOperators) {
      return (
        <span className="prl plm is-size-4" style={style}>
          =
        </span>
      );
    }

    if (supportedOperators.length === 1) {
      return (
        <span className="prl plm is-size-4" style={style}>
          {supportedOperators[0]}
        </span>
      );
    }

    if (!filterOperator) {
      filterOperator = '=';
    }
    return (
      <Form.Control style={style}>
        <Select
          value={{ label: filterOperator, value: filterOperator }}
          classNamePrefix="matik-select"
          onChange={(obj, action) => onOperatorChange(obj, action, idx)}
          isDisabled={isReadOnly}
          options={supportedOperators.map((supportedOperator) => ({
            label: supportedOperator,
            value: supportedOperator,
          }))}
        />
      </Form.Control>
    );
  }
  return (
    <React.Fragment>
      <Form.Field className="mbl">
        <Form.Label>Filters</Form.Label>
        <Form.Help>
          Select fields and inputs to filter by.
          <span
            className="mls filter-hint-tooltip"
            data-tooltip-id="matik-tooltip"
            data-tooltip-html="Hint: Create & insert inputs using &:&lt;input_name&gt;"
            data-tooltip-place="right"
          >
            <img src={info_icon} alt="Filter Info" width="14px" className="icon-pull-down" />
          </span>
        </Form.Help>
        <Form.Control>{body}</Form.Control>
      </Form.Field>
    </React.Fragment>
  );
}

ApiFilters.propTypes = {
  filterArray: PropTypes.array,
  onFilterUpdate: PropTypes.func,
  allFields: PropTypes.array,
  supportedOperators: PropTypes.array,
  inputs: PropTypes.object,
  isReadOnly: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  isInputPopoverDisabled: PropTypes.bool,
  validateField: PropTypes.func,
};

export default ApiFilters;
