import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bulma-components';
import { concat, each, find } from 'lodash';
import moment from 'moment';
import API from '../../../lib/api';
import useOnClickOutside from '../../../lib/hooks/useOnClickOutside';
import { ReactComponent as BellIcon } from '../../../svg/bell.svg';
import Constants from '../../Constants';
import { EVENT_TYPE_VALUES, READ_STATE_NAMES, READ_STATE_VALUES, MAXIMUM_TIMESTAMP_GAP } from './Constants';
import { Renderer } from './renderer';
import Notification from './Notification';
import Button from '../../lib/Button';

function NotificationCenter(props) {
  const [isActive, setIsActive] = useState(false);
  const [readNotifications, setReadNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [readHasMore, setReadHasMore] = useState(false);
  const [unreadHasMore, setUnreadHasMore] = useState(false);
  const [allNotificationsFeed, setAllNotificationFeed] = useState([]);
  const ref = useRef();
  useOnClickOutside(
    ref,
    () => {
      if (isActive) {
        setIsActive(false);
      }
    },
    [isActive],
  );

  useEffect(() => {
    if (props.user) {
      fetchNotifications();
    }
  }, []);

  useEffect(() => {
    mergeNotifications();
    const interval = setInterval(() => {
      if (props.user && unreadNotifications.length) {
        fetchNotifications(READ_STATE_NAMES.unread, unreadNotifications.at(0).event.timestamp);
      } else if (props.user && readNotifications.length) {
        fetchNotifications(READ_STATE_NAMES.unread, readNotifications.at(0).event.timestamp);
      } else {
        fetchNotifications(READ_STATE_NAMES.unread, moment().subtract(60, 'seconds').format('X'));
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [unreadNotifications]);

  useEffect(() => {
    mergeNotifications();
  }, [readNotifications]);

  const fetchMoreNotifications = () => {
    if (unreadHasMore) {
      fetchNotifications(READ_STATE_NAMES.unread);
    }
    if (readHasMore) {
      fetchNotifications(READ_STATE_NAMES.read);
    }
  };

  const fetchNotifications = (readState, since) => {
    const relativeUrl = `/notifications/${props.user.id}`;
    if (readState === undefined) {
      // fetches both unread and read notifications
      each(READ_STATE_NAMES, (value) => {
        API.get(
          `${relativeUrl}/${value}/`,
          (response) => {
            appendNotifications(response.data, value);
          },
          () => {},
        );
      });
    } else if (since === undefined) {
      // fetches older unread or read notifications
      let before = '';
      if (readState === READ_STATE_NAMES.unread && unreadHasMore) {
        const lastUnread = unreadNotifications.at(-1) || find(readNotifications, { previousLastUnread: true });
        before = lastUnread?.event?.timestamp;
      } else {
        before = readNotifications.at(-1)?.event?.timestamp;
      }
      API.get(
        `${relativeUrl}/${readState}/?before=${before}`,
        (response) => {
          appendNotifications(response.data, readState);
        },
        () => {},
      );
    } else if (readState === READ_STATE_NAMES.unread && since) {
      // fetches new unread presentations
      API.get(
        `${relativeUrl}/${readState}/?since=${since}`,
        (response) => {
          prependNotifications(response.data, readState);
        },
        () => {},
      );
    }
  };

  const prependNotifications = (data, readState) => {
    const fetchedNotifications = [];
    if (data.notifications && data.notifications.length) {
      each(data.notifications, (event) => {
        const { id } = event;
        fetchedNotifications.push({ event: event, templateData: data.template_data[id] });
      });
      if (readState === READ_STATE_NAMES.unread) {
        setUnreadNotifications((prevUnread) => {
          return concat(fetchedNotifications, prevUnread);
        });
      } else {
        setReadNotifications((prevRead) => {
          return concat(fetchedNotifications, prevRead);
        });
      }
    }
  };

  const appendNotifications = (data, readState) => {
    const fetchedNotifications = [];
    if (data.notifications && data.notifications.length) {
      each(data.notifications, (event) => {
        const { id } = event;
        fetchedNotifications.push({ event: event, templateData: data.template_data[id] });
      });
      if (readState === READ_STATE_NAMES.unread) {
        if (unreadHasMore !== data.has_more) {
          setUnreadHasMore(data.has_more);
        }
        setUnreadNotifications((prevUnread) => {
          return concat(prevUnread, fetchedNotifications);
        });
      } else {
        if (readHasMore !== data.has_more) {
          setReadHasMore(data.has_more);
        }
        setReadNotifications((prevRead) => {
          return concat(prevRead, fetchedNotifications);
        });
      }
    }
  };

  const mergeNotifications = (readState, arr1 = unreadNotifications, arr2 = readNotifications) => {
    const merged = [...arr1, ...arr2].sort((a, b) => b.event.timestamp - a.event.timestamp);
    if (readState === undefined) {
      setAllNotificationFeed(merged);
    } else if (readState === READ_STATE_NAMES.unread) {
      setUnreadNotifications(merged);
    } else {
      setReadNotifications(merged);
    }
  };

  const bulkMarkRead = (notificationIds = []) => {
    if (unreadNotifications.length) {
      const data = { notification_ids: [] };
      const updatedUnreadNotifications = [];
      const markReadNotifications = [];
      if (unreadHasMore) {
        unreadNotifications.at(-1).previousLastUnread = true;
      }

      if (notificationIds.length) {
        data.notification_ids = notificationIds;
        each(unreadNotifications, (unreadNotification) => {
          const unreadNotificationIdExistsInNotificationIds = notificationIds.indexOf(unreadNotification.event.id) > -1;
          if (unreadNotificationIdExistsInNotificationIds) {
            markReadNotifications.push(unreadNotification);
          } else {
            updatedUnreadNotifications.push(unreadNotification);
          }
        });
      } else {
        each(unreadNotifications, (unreadNotification) => {
          data.notification_ids.push(unreadNotification.event.id);
          markReadNotifications.push(unreadNotification);
        });
      }
      API.post(
        `/notifications/${props.user.id}/bulk/mark_read/`,
        data,
        () => {
          setUnreadNotifications(updatedUnreadNotifications);
          each(markReadNotifications, (n) => {
            n.event.read_state = 2;
          });
          setReadNotifications((prevRead) => {
            return concat(prevRead, markReadNotifications);
          });
        },
        API.defaultError,
      );
    }
  };

  const renderNotificationItems = () => {
    if (allNotificationsFeed.length) {
      const bundleHeads = {};
      each(Object.values(READ_STATE_VALUES), (readState) => {
        bundleHeads[readState] = {};
        each(Object.values(EVENT_TYPE_VALUES), (eventType) => {
          if (eventType === EVENT_TYPE_VALUES.presentationErrors) {
            bundleHeads[readState][Constants.LOG_LEVEL.warning] = [];
            bundleHeads[readState][Constants.LOG_LEVEL.error] = [];
          } else if (eventType !== EVENT_TYPE_VALUES.all) {
            bundleHeads[readState][eventType] = [];
          }
        });
      });
      const itemsToRender = [];
      each(allNotificationsFeed, (current) => {
        // All the notifications in the notification feed are grouped by bundles. Bundles are notifications of the same
        // event type and read state where the first notification in the bundle (aka bundle head), and all the subsequent
        // ones have a timestamp difference that is less than the MAXIMUM_TIMESTAMP_GAP. The bundles are then sorted by
        // their respective bundle head timestamp.
        let currentBundleHeads = bundleHeads[current.event.read_state][current.event.event_type];
        if (current.event.event_type === EVENT_TYPE_VALUES.presentationErrors) {
          currentBundleHeads = bundleHeads[current.event.read_state][current.templateData.level];
        }

        if (!currentBundleHeads.at(-1)) {
          currentBundleHeads.push(current);
          itemsToRender.push([current]);
        } else {
          const firstBundleHeadWithinTimestampGap = currentBundleHeads.find((head) => {
            return head.event.timestamp - current.event.timestamp < MAXIMUM_TIMESTAMP_GAP;
          });
          if (firstBundleHeadWithinTimestampGap) {
            itemsToRender
              .find((bundle) => bundle.at(0).event.id === firstBundleHeadWithinTimestampGap.event.id)
              .push(current);
          } else {
            currentBundleHeads.push(current);
            itemsToRender.push([current]);
          }
        }
      });
      return itemsToRender.flatMap((bundle) => {
        const bundleHeadEvent = bundle.at(0).event;
        if (bundleHeadEvent.event_type in Renderer) {
          const rendererClass = Renderer[bundleHeadEvent.event_type];
          if (rendererClass.canBeBundled) {
            const renderData = new rendererClass(bundle, props.role, bulkMarkRead, setIsActive);
            return <Notification key={bundleHeadEvent.id} renderData={renderData} />;
          } else {
            return bundle.map((bundleItem) => {
              const renderData = new rendererClass([bundleItem], props.role, bulkMarkRead, setIsActive);
              return <Notification key={bundleItem.event.id} renderData={renderData} />;
            });
          }
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Navbar.Link
        className="navbar-white-hover"
        arrowless={true}
        onClick={(e) => {
          e.stopPropagation();
          setIsActive(true);
        }}
      >
        {unreadNotifications.length > 0 && <div className="notification-center-status"></div>}
        <BellIcon className="image mhxs" />
      </Navbar.Link>
      <div ref={ref}>
        {isActive && (
          <div className="notification-center">
            <h1>Notifications</h1>
            <div className="notification-items">
              {renderNotificationItems()}
              {(unreadHasMore || readHasMore) && (
                <Button category="secondary" onClick={fetchMoreNotifications}>
                  Load More
                </Button>
              )}
            </div>
            <footer onClick={() => bulkMarkRead()}>Mark all as read</footer>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

NotificationCenter.propTypes = {
  role: PropTypes.string,
  user: PropTypes.object,
};

export default NotificationCenter;
