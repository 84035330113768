import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import EmailTemplateHeader from './EmailTemplateHeader';
import inputs from '../../../lib/inputs';
import utils from '../../../lib/utils';
import { Auth } from '../../../lib/auth';
import InputsList from '../../../lib/inputsList';
import EmailTemplateComponents from 'components/producer/email/EmailTemplateComponents';
import Constants from 'components/Constants';
import EmailPresentationCreator from 'components/consumer/EmailPresentationCreator';
import templates_empty_icon from '../../../images/templates_empty.png';
import Banner from 'components/shared/Banner';
import { useTemplateContent, useTemplateMutator } from 'lib/hooks/useTemplate';
import EmailFieldsSetup from './EmailFieldsSetup';
import dynamicContent from 'lib/dynamicContent';
import { MConfirm } from '../../shared/Alerts';
import CopyModal from '../dynamicContent/CopyModal';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from '../../../redux/ui/action';
import API from '../../../lib/api';
import { cloneDeep } from 'lodash';

function EmailTemplateMain(props) {
  const [activeTab, setActiveTab] = useState(props.currentTab ? props.currentTab : 'edit');
  const [canUndo, setCanUndo] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { createFast: createTemplate, update: updateTemplate } = useTemplateMutator();
  const dispatch = useDispatch();

  const emailHtml = props.currentTemplate?.slides?.[0]?.slide_xml ? props.currentTemplate.slides[0].slide_xml : '';

  const { data: templateContent, invalidate: invalidateContent } = useTemplateContent(
    props.currentTemplate?.id,
    props.currentTemplate?.deleted,
  );
  const { data: attachedTemplateContent } = useTemplateContent(
    props.attachedTemplate?.id,
    props.attachedTemplate?.deleted,
  );

  const handleEmailContentUpdate = (...args) => {
    return props.onTemplateUpdateWithServerCall(...args).then(() => invalidateContent());
  };

  const getBannerText = () => {
    const sublineText = props.sendgridInfo?.auth?.domain_authenticated ? (
      <span className="banner-link" onClick={props.openAuthModal}>
        Validate Records
      </span>
    ) : (
      <span className="banner-link" onClick={props.openAuthModal}>
        Start Authentication
      </span>
    );

    return sublineText;
  };

  const toggleTab = (label) => {
    if ((activeTab === 'edit' && canUndo) || (activeTab === 'settings' && hasUnsavedChanges)) {
      MConfirm('', 'Are you sure you want to navigate away? There are unsaved changes.', 'warning', (confirmed) => {
        if (confirmed) {
          setActiveTab(label.toLowerCase());
        }
      });
    } else {
      setActiveTab(label.toLowerCase());
    }
  };

  const inputsInMatchingContent = (currentTemplate, templateContent, attachedTemplate, attachedTemplateContent) => {
    const tagTree = utils.getDynamicContentTags(currentTemplate, templateContent);
    const attachedTagTree = utils.getDynamicContentTags(attachedTemplate, attachedTemplateContent);
    const bodyTags = inputs.getAllInputs(
      tagTree.getTagNodes(),
      props.allDynamicContent,
      currentTemplate,
      templateContent,
      null,
    );
    let attachmentTags = {};
    if (attachedTemplate) {
      attachmentTags = inputs.getAllInputs(
        attachedTagTree.getTagNodes(),
        props.allDynamicContent,
        attachedTemplate,
        attachedTemplateContent,
        null,
      );
    }
    return [bodyTags, attachmentTags];
  };

  const showCopyModal = (e) => {
    e.preventDefault();
    dispatch(openModal('copyModal'));
  };

  const onCopySubmit = (e, name) => {
    const tagsArray = cloneDeep(props.currentTemplate?.slides[0]?.dynamic_content_tags) || [];

    const templateData = {
      name: name,
      source_type: 'email',
      source: props.currentTemplate?.source,
      attached_template_id: props.currentTemplate?.attached_template_id,
      params_order: props.currentTemplate?.params_order,
    };

    createTemplate(templateData, null)
      .then((newTemplateData) => {
        newTemplateData.newTags = tagsArray;
        newTemplateData.html = emailHtml;
        updateTemplate(newTemplateData, Constants.PRODUCER_ROLE, false).then((updatedTemplateData) => {
          handleEmailContentUpdate(updatedTemplateData, false, false);
          dispatch(closeModal('copyModal'));
          props.onTemplateAdd(updatedTemplateData);
        });
      })
      .catch(API.defaultError);
  };

  const attachedTemplate = props.attachedTemplate;

  const [bodyMatchingInputs, attachmentMatchingInputs] = inputsInMatchingContent(
    props.currentTemplate,
    templateContent,
    attachedTemplate,
    attachedTemplateContent,
  );
  const bodyInputsList = new InputsList(bodyMatchingInputs, props.currentTemplate.params_order);
  const bodyOrderedInputs = bodyInputsList.getSortedList();

  let attachmentOrderedInputs = [];
  if (attachedTemplate) {
    const attachmentInputsList = new InputsList(attachmentMatchingInputs, attachedTemplate.params_order);
    attachmentOrderedInputs = attachmentInputsList.getSortedList();
  }
  const allOrderedInputs = bodyOrderedInputs;
  attachmentOrderedInputs.forEach((input) => {
    if (allOrderedInputs.findIndex((i) => i.id === input.id) < 0) {
      allOrderedInputs.push(input);
    }
  });

  const emptyInfo = {
    emptyImageUrl: templates_empty_icon,
    emptyPrompt: "There aren't any available templates.",
  };

  const sublineText = getBannerText();

  const showEmailEditComponents = ['edit', 'setup'].includes(activeTab) && Auth.hasRole(Constants.PRODUCER_ROLE);
  const showEmailSettingsComponents = activeTab === 'settings' && Auth.hasRole(Constants.PRODUCER_ROLE);
  const showEmailSendComponents = ['send & schedule', 'generate'].includes(activeTab);
  const showHeader = showEmailEditComponents || showEmailSettingsComponents;

  const dynamicRecipientsName = dynamicContent.getDynamicContentNameById(
    parseInt(props.currentTemplate.source.recipients.dynamicRecipients),
    props.allDynamicContent,
  );

  return (
    <>
      {!props.sendgridInfo?.auth.domain_validated && (
        <Banner
          bannerType="warning"
          text="Authenticate your domain to improve email deliverability  • "
          sublineText={sublineText}
          alignment="center"
        />
      )}
      {showHeader && (
        <EmailTemplateHeader
          onTemplateDelete={props.onTemplateDelete}
          onTemplateArchive={props.onTemplateArchive}
          accessesByTemplateId={props.accessesByTemplateId}
          template={props.currentTemplate}
          canEdit={props.canEdit}
          showAccessModal={props.showAccessModal}
          orderedInputs={allOrderedInputs}
          onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
          activeTab={activeTab}
          toggleTab={toggleTab}
          canUndo={canUndo}
          onCopyClick={showCopyModal}
        />
      )}
      {showEmailEditComponents && (
        <EmailTemplateComponents
          currentTemplate={props.currentTemplate}
          onTemplateUpdateWithServerCall={handleEmailContentUpdate}
          attachedTemplate={attachedTemplate}
          allDynamicContent={props.allDynamicContent}
          sendgridInfo={props.sendgridInfo}
          openAuthModal={props.openAuthModal}
          enterprise={props.enterprise}
          canEdit={props.canEdit}
          onTemplateTest={props.onTemplateTest}
          emailPreviewValues={props.emailPreviewValues}
          setCanUndo={setCanUndo}
          onContentClick={props.onContentClick}
          onInputClick={props.onInputClick}
          isPreviewLoading={props.isPreviewLoading}
          emailHtml={emailHtml}
          dynamicRecipientsName={dynamicRecipientsName}
        />
      )}
      {showEmailSettingsComponents && (
        <EmailFieldsSetup
          currentTemplate={props.currentTemplate}
          allDynamicContent={props.allDynamicContent}
          canEdit={props.canEdit}
          onTemplateUpdateWithServerCall={handleEmailContentUpdate}
          emailHtml={emailHtml}
          sendgridInfo={props.sendgridInfo}
          openAuthModal={props.openAuthModal}
          enterprise={props.enterprise}
          onContentClick={props.onContentClick}
          onInputClick={props.onInputClick}
          dynamicRecipientsName={dynamicRecipientsName}
          setHasUnsavedChanges={setHasUnsavedChanges}
        />
      )}
      {showEmailSendComponents && (
        <EmailPresentationCreator
          toggleTab={toggleTab}
          template={props.currentTemplate}
          attachedTemplate={attachedTemplate}
          onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
          userDataSources={props.userDataSources}
          onUserDataSourceAdd={props.onUserDataSourceAdd}
          accessesByTemplateId={props.accessesByTemplateId}
          onPresentationCreate={props.onPresentationCreate}
          isLoading={!props.currentTemplate}
          elements={props.currentTemplate ? [props.currentTemplate] : []}
          emptyInfo={emptyInfo}
          activeTab={activeTab}
          allDynamicContentByName={props.allDynamicContent}
        />
      )}
      <CopyModal
        itemType={Constants.ItemTypes.TEMPLATE}
        oldName={props.currentTemplate.name}
        onCopySubmit={onCopySubmit}
      />
    </>
  );
}

EmailTemplateMain.propTypes = {
  accessesByTemplateId: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  currentTemplate: PropTypes.object,
  canEdit: PropTypes.bool,
  showAccessModal: PropTypes.func,
  onTemplateUpdateWithServerCall: PropTypes.func,
  onTemplateDelete: PropTypes.func,
  onTemplateArchive: PropTypes.func,
  openModal: PropTypes.func,
  allDynamicContent: PropTypes.object,
  userDataSources: PropTypes.object,
  onUserDataSourceAdd: PropTypes.func,
  onPresentationCreate: PropTypes.func,
  currentTab: PropTypes.string,
  sendgridInfo: PropTypes.object,
  openAuthModal: PropTypes.func,
  enterprise: PropTypes.object,
  onTemplateTest: PropTypes.func,
  emailPreviewValues: PropTypes.object,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
  isPreviewLoading: PropTypes.bool,
  attachedTemplate: PropTypes.object,
  onTemplateAdd: PropTypes.func,
};

export default withRouter(EmailTemplateMain);
