import React from 'react';
import PropTypes from 'prop-types';
import Indicator from './Indicator';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';
import { Link } from 'react-router-dom';

const ListItemStatus = {
  DEFAULT: 'default',
  DISABLED: 'disabled',
  WARNING: 'warning',
};

function ListItem({
  title,
  subtitle,
  download,
  href,
  id,
  isSubtitleInline,
  icon,
  indicatorColor,
  metadata,
  size = 'm',
  status = ListItemStatus.DEFAULT,
  to,
  tooltip,
  onClick,
}) {
  const mainTextClass = status === ListItemStatus.DEFAULT ? 'text-matik-black' : 'text-grey-500';
  let subtitleTextClass;
  switch (status) {
    case ListItemStatus.WARNING:
      subtitleTextClass = 'text-yellow-500';
      break;
    case ListItemStatus.DEFAULT:
      subtitleTextClass = 'text-grey-600';
      break;
    default:
      subtitleTextClass = 'text-grey-400';
      break;
  }
  const item = (
    <>
      {icon}
      {indicatorColor && <Indicator color={indicatorColor} />}
      <div className={`flex-1 min-w-0 flex ${isSubtitleInline ? 'flex-row gap-2' : 'flex-col'}`}>
        <TextOverflowWithTooltip className="leading-6 truncate">{title}</TextOverflowWithTooltip>
        {subtitle && (
          <TextOverflowWithTooltip
            className={`${subtitleTextClass} truncate ${isSubtitleInline ? 'leading-6 italic' : 'leading-none'}`}
          >
            {subtitle}
          </TextOverflowWithTooltip>
        )}
      </div>
    </>
  );
  let wrapperEl = (
    <div className={`flex items-center grow text-left truncate gap-${size === 's' ? '2' : '3'}`}>{item}</div>
  );
  if (status !== ListItemStatus.DISABLED) {
    if (href) {
      wrapperEl = (
        <a
          href={href}
          download={download}
          onClick={onClick}
          className={`flex items-center text-matik-black grow text-left truncate gap-${size === 's' ? '2' : '3'}`}
        >
          {item}
        </a>
      );
    } else if (to) {
      wrapperEl = (
        <Link
          to={to}
          className={`flex items-center text-matik-black grow text-left truncate gap-${size === 's' ? '2' : '3'}`}
        >
          {item}
        </Link>
      );
    } else if (onClick) {
      wrapperEl = (
        <button
          id={id}
          onClick={onClick}
          className={`flex items-center grow text-left truncate gap-${size === 's' ? '2' : '3'}`}
        >
          {item}
        </button>
      );
    }
  }

  return (
    <div
      className={`flex items-center w-full gap-${size === 's' ? '2' : '3'} ${mainTextClass}`}
      data-tooltip-id="matik-tooltip"
      data-tooltip-content={tooltip}
    >
      {wrapperEl}
      {metadata && <div className={subtitleTextClass}>{metadata}</div>}
    </div>
  );
}
ListItem.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  href: PropTypes.string,
  download: PropTypes.string,
  isSubtitleInline: PropTypes.bool,
  icon: PropTypes.node,
  id: PropTypes.string,
  indicatorColor: PropTypes.string,
  metadata: PropTypes.node,
  size: PropTypes.oneOf(['s', 'm']),
  status: PropTypes.oneOf(Object.values(ListItemStatus)),
  to: PropTypes.string,
  onClick: PropTypes.func,
  showWarning: PropTypes.bool,
  tooltip: PropTypes.string,
};
export default ListItem;
