import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from 'components/lib/ButtonGroup';
import emptystate_big from 'images/emptystates_big.svg';
import emptystate_medium from 'images/emptystates_medium.svg';

function EmptyState({ size = 'medium', title, content, buttons }) {
  return (
    <div className={`flex flex-col items-center ${size === 'medium' ? 'py-20' : '!py-6'}`}>
      <img src={size === 'medium' ? emptystate_big : emptystate_medium} />
      <div className={`mt-${size === 'medium' ? '8' : '4'} text-matik-black text-[16px] font-semibold text-center`}>
        {title}
      </div>
      <div className="mt-3 text-grey-500 text-center">{content}</div>
      <div className="mt-10">
        <ButtonGroup>{buttons}</ButtonGroup>
      </div>
    </div>
  );
}
EmptyState.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  title: PropTypes.node,
  content: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.element),
};

export default EmptyState;
