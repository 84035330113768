import utils from './utils';

const spreadsheetUtils = {
  formatRowData: (rows) => {
    // Convert GoogleSpreadsheetRow objects into an array of dicts for the Spreadsheet library to properly display.
    // E.g.:
    // parsedRowData = [
    //     [{ value: 'a1' }, { value: 'b1' }],
    //     [{ value: 'a2' }, { value: 'b2' }],
    //   ];

    if (typeof rows === 'undefined') {
      rows = [];
    }

    let parsedRowData = [];
    for (let i = 0; i < rows.length; i++) {
      parsedRowData.push(spreadsheetUtils.rowArrayToDict(rows[i]));
    }

    // Keep a minimum number of rows in the sheet for display purposes
    for (let j = rows.length; j < 100; j++) {
      parsedRowData.push({ value: '' });
    }

    return parsedRowData;
  },

  formatRowDataWithStyling: (rowData) => {
    let parsedRowData = [];
    rowData.forEach((row) => {
      const formattedRowData = [];
      const rowInfo = row.values || [];
      rowInfo.forEach((cell) => {
        const unformattedCellStyleData = cell.effectiveFormat || {};
        const formattedCellStyleData =
          spreadsheetUtils.convertGoogleStylesToXSpreadsheetStyles(unformattedCellStyleData);
        formattedRowData.push({
          value: cell.formattedValue || '',
          style: formattedCellStyleData || {},
        });
      });
      parsedRowData.push(formattedRowData);
      // Keep a minimum number of columns for display purposes
      for (let i = rowInfo.length; i < 26; i++) {
        formattedRowData.push({ value: '', style: {} });
      }
    });

    // Keep a minimum number of rows in the sheet for display purposes
    for (let j = rowData.length; j < 100; j++) {
      parsedRowData.push({ value: '' });
    }

    return parsedRowData;
  },

  convertGoogleStylesToXSpreadsheetStyles: (googleStyleObj) => {
    return {
      align: googleStyleObj.horizontalAlignment?.toLowerCase() || 'left',
      valign: googleStyleObj.verticalAlignment ? googleStyleObj.verticalAlignment.toLowerCase() : 'middle',
      font: {
        bold: googleStyleObj.textFormat?.bold,
        name: googleStyleObj.textFormat?.fontFamily || 'RM Neue',
        size: googleStyleObj.textFormat?.fontSize || '',
        italic: googleStyleObj.textFormat?.italic,
        strikethrough: googleStyleObj.textFormat?.strikethrough,
        underline: googleStyleObj.textFormat?.underline,
      },
      bgcolor: googleStyleObj.backgroundColor ? utils.protoToHexColor(googleStyleObj.backgroundColor) : '#FFFFFF',
      color: googleStyleObj.textFormat?.foregroundColor
        ? utils.protoToHexColor(googleStyleObj.textFormat.foregroundColor)
        : '',
      border: {
        top: googleStyleObj.borders?.top?.color
          ? ['medium', utils.protoToHexColor(googleStyleObj.borders.top.color)]
          : null,
        right: googleStyleObj.borders?.right?.color
          ? ['medium', utils.protoToHexColor(googleStyleObj.borders.right.color)]
          : null,
        bottom: googleStyleObj.borders?.bottom?.color
          ? ['medium', utils.protoToHexColor(googleStyleObj.borders.bottom.color)]
          : null,
        left: googleStyleObj.borders?.left?.color
          ? ['medium', utils.protoToHexColor(googleStyleObj.borders.left.color)]
          : null,
      },
    };
  },

  rowArrayToDict: (rowArray) => {
    // Convert a single array representing row values into a dict for proper visualization.
    // E.g.:
    // rowArray = ['a1', 'b1']
    // --> [{value: 'a1'}, {value: 'b1'}]

    if (typeof rowArray === 'undefined') {
      rowArray = [];
    }

    let rowDict = [];
    for (let j = 0; j < rowArray.length; j++) {
      rowDict.push({
        value: rowArray[j],
      });
    }

    // Keep a minimum number of columns for display purposes
    for (let i = rowArray.length; i < 26; i++) {
      rowDict.push({ value: '' });
    }

    return rowDict;
  },

  colNumToChar: (num) => {
    // Convert a column number to its corresponding spreadsheet letter.
    // E.g.:
    // 1 --> 'A', 27 --> 'AA', etc.

    let s = '',
      t;
    while (num > 0) {
      t = (num - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      num = ((num - t) / 26) | 0;
    }

    return s || undefined;
  },

  charToColNum: (char) => {
    // Converts column character to column index
    const chars = char.split('');
    let colNum = 0;
    chars.forEach((c) => (colNum += c.toUpperCase().charCodeAt(0) - 65));
    return colNum;
  },

  cellArrayToA1Notation: (cellSelection, worksheetTitle, rowCount) => {
    // Convert the selected cells to A1 notation:
    // {worksheetTitle}!{rangeStart}:{rangeEnd}
    //
    // Case 1: Single Cell - Sheet1!A1
    // Case 2: Columns, rows, or columns and rows - Sheet1!A1:B2
    // Handles special case where an entire column is selected: we pass in startRowIndex -1 and convert it to a
    // multiselection of the whole row.

    if (spreadsheetUtils._isSingleSelection(cellSelection) && cellSelection.startRowIndex !== -1) {
      const column = cellSelection.startColumnIndex + 1;
      const row = cellSelection.startRowIndex + 1;
      const colString = spreadsheetUtils.colNumToChar(column);
      return worksheetTitle + '!' + colString + row;
    }

    const rowMin =
      cellSelection.startRowIndex === -1 ? cellSelection.startRowIndex + 2 : cellSelection.startRowIndex + 1;
    const rowMax = cellSelection.startRowIndex === -1 ? rowCount : cellSelection.endRowIndex + 1;
    const colMin = cellSelection.startColumnIndex + 1;
    const colMax = cellSelection.endColumnIndex + 1;

    return (
      worksheetTitle +
      '!' +
      spreadsheetUtils.colNumToChar(colMin) +
      rowMin +
      ':' +
      spreadsheetUtils.colNumToChar(colMax) +
      rowMax
    );
  },

  _isSingleSelection: (cellSelection) => {
    return (
      cellSelection.startRowIndex === cellSelection.endRowIndex &&
      cellSelection.startColumnIndex === cellSelection.endColumnIndex
    );
  },
  inputStringToSelection: (inputString) => {
    // Convert input string of the type sheet!A1:B7 to
    // {sheetName: sheet, startColumnIndex: 0, startRowIndex: 0, endColumnIndex: 1, endRowIndex: 6}
    if (!inputString) {
      return null;
    }

    const locationRegex = /(?:([^!]+)!)?([A-Z]{1,3})([0-9]+):?([A-Z]{1,3})?([0-9]+)?/i;
    const match = inputString.match(locationRegex);
    // Handle three cases:
    // A1 -> ['A1', undefined, 'A', 1, undefined, undefined, ...]
    // sheet1!A1 -> ['sheet1!A1', 'sheet1', 'A', 1, undefined, undefined, ...]
    // sheet1!A1:B27 -> ['sheet1!A1:B27', 'sheet1', 'A', 1, 'B', 27, ...]
    if (!match || match.length < 6) {
      return null;
    }
    const selection = {};
    selection.sheet = match[1];
    selection.startColumnIndex = selection.endColumnIndex = spreadsheetUtils.charToColNum(match[2]);
    selection.startRowIndex = selection.endRowIndex = match[3] - 1;
    if (match[4] && match[5]) {
      selection.endColumnIndex = spreadsheetUtils.charToColNum(match[4]);
      selection.endRowIndex = match[5] - 1;
    }

    return selection;
  },
};

export default spreadsheetUtils;
