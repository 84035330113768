import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from 'react-bulma-components';
import FormSkeletonLoader from 'components/producer/dynamicContent/FormSkeletonLoader';

function Sidepane({ children, isFetchingContent, sidepaneType, name }) {
  const ui = useSelector((state) => state.ui);
  const sidepaneId = `sidepane-container-${name}`;
  let sidepaneClassName = ui.sidepane?.name === name ? `${sidepaneType}-sidepane sidepane-active` : 'sidepane';
  if (ui.sidepane && ui.googleSheetDrawer) {
    sidepaneClassName += ' with-drawer';
  }

  useEffect(() => {
    mountContainer();
    return () => {
      unmountContainer();
    };
  }, []);

  const mountContainer = () => {
    const docBody = document.getElementsByClassName('body')[0];
    const container = document.getElementById(sidepaneId);
    if (container && container.parentNode !== docBody) {
      docBody?.appendChild(container);
    }
  };

  const unmountContainer = () => {
    const docBody = document.getElementsByClassName('body')[0];
    // Not using getElementById here since we could temporarily have two when mounting a different sidepane
    for (const childNode of docBody?.childNodes || []) {
      if (childNode.id === sidepaneId) {
        docBody.removeChild(childNode);
      }
    }
  };

  return (
    <div>
      <Box className={`${sidepaneClassName} is-paddingless is-marginless`} id={sidepaneId}>
        {ui.sidepane?.name === name && isFetchingContent && (
          <div className="sidepane-loading">
            <FormSkeletonLoader />
          </div>
        )}
        {ui.sidepane?.name === name && isFetchingContent !== true && children}
      </Box>
    </div>
  );
}

Sidepane.propTypes = {
  onClose: PropTypes.func,
  sidepaneType: PropTypes.string,
  isFetchingContent: PropTypes.bool,
  name: PropTypes.string,
  children: PropTypes.any,
};

export default Sidepane;
