import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Level } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import AccessAvatarsWithShareButton from '../../shared/AccessAvatarsWithShareButton';
import EditableTemplateName from '../../shared/templates/EditableTemplateName';
import { UserContext } from 'components/UserContext';
import Constants from 'components/Constants';
import DropdownMenu from 'components/lib/DropdownMenu';
import Icon from 'components/lib/Icon';
import TemplateConditionsModal from 'components/producer/templates/TemplateConditionsModal';
import { openFullScreenModal } from 'redux/ui/action';
import { useDispatch, useSelector } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { openModal } from 'redux/ui/action';
import API from 'lib/api.js';
import { useTemplateFavorites } from 'lib/hooks/useTemplate';
import { Auth } from 'lib/auth';
import Button from '../../lib/Button';
import IconPill from '../../lib/IconPill';
import Tabs from '../../lib/Tabs';
import TabItem from '../../lib/TabItem';
import EmailSendDropdown from './EmailSendDropdown';
import DropdownMenuItem from '../../lib/DropdownMenuItem';
import ListItem from '../../lib/ListItem';

function EmailTemplateHeader(props) {
  const context = useContext(UserContext);
  const [showSetupDropdown, setShowSetupDropdown] = useState(false);
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const ui = useSelector((state) => state.ui);
  const enableDynamicSenderFeatures = ldClient?.variation('enable-dynamic-sender-features', false);
  const enableEmailCopy = ldClient?.variation('enable-email-copy', false);

  const { data: favorites, update: updateFavorite } = useTemplateFavorites();

  const isFavorite = favorites?.indexOf(props.template.id) > -1;

  const onScheduleOpen = () => {
    dispatch(openModal('scheduledFlowModal'));
  };

  const toggleSetupDropdown = () => {
    setShowSetupDropdown(!showSetupDropdown);
  };

  const onTemplateDelete = (e) => {
    e.preventDefault();
    props.onTemplateDelete(props.template);
  };

  const onTemplateArchive = (e) => {
    e.preventDefault();
    props.onTemplateArchive(props.template);
  };

  const openConditionsModal = (e) => {
    e.preventDefault();
    dispatch(openFullScreenModal('conditionsModal'));
  };

  let tooltip = '';
  const subjectFilledOut = props.template.source.subject?.length > 0;
  const recipientsFilledOut = Boolean(props.template.source.recipients?.dynamicRecipients);
  const fromFilledOut =
    [
      props.template.source.from.fromEmail,
      props.template.source.from.replyEmail,
      props.template.source.from.name,
    ].every((field) => field !== '') || Boolean(props.template.source.from.senderContent);

  const allEmailFieldsFilledOut = subjectFilledOut && recipientsFilledOut && fromFilledOut;
  const isSendingDisabled = !(props.allInputsFilledOut && allEmailFieldsFilledOut);
  if (props.allInputsFilledOut === false) {
    tooltip = 'Some inputs are missing';
  }
  if (allEmailFieldsFilledOut === false) {
    tooltip = 'Some email fields are incomplete: ';
    const missingEmailFields = [];
    if (!props.template.source.subject?.length) {
      missingEmailFields.push('Subject');
    }
    if (!Object.values(props.template.source.recipients).some((field) => field.length > 0)) {
      missingEmailFields.push('To');
    }
    if (!Object.values(props.template.source.from).every((field) => field !== '')) {
      missingEmailFields.push('From');
    }
    if (missingEmailFields.length) {
      tooltip += missingEmailFields.join(', ');
    }
  }

  const producer = context?.user?.roles.indexOf(Constants.PRODUCER_ROLE) > -1;
  const isSchedulingEnabled = context.user?.enterprise?.plan_id === Constants.MATIK_TIERS.matik_enterprise.tier_id;
  const conditionalIconTooltip = producer
    ? 'This template has send conditions applied. An email will only be sent if it meets these conditions. (Click here to manage the conditions.)'
    : 'This template has send conditions applied. An email will only be sent if it meets these conditions.';

  const shouldShowTemplateConditionsIcon = props.template?.conditions?.length;

  const backLink =
    Auth.hasRole('producer') && location.pathname.indexOf('create') === -1 ? '/templates' : '/create/templates';

  const tabs = enableDynamicSenderFeatures ? (
    <Tabs>
      <TabItem label="Edit" onClick={props.toggleTab} isActive={props.activeTab === 'edit'} />
      <TabItem
        label="Settings"
        onClick={props.toggleTab}
        isActive={props.activeTab === 'settings'}
        indicatorColor={allEmailFieldsFilledOut ? null : 'red-500'}
      />
      <TabItem label="Send & Schedule" onClick={props.toggleTab} isActive={props.activeTab === 'send & schedule'} />
    </Tabs>
  ) : (
    <Tabs>
      <TabItem label="Setup" onClick={props.toggleTab} isActive={props.activeTab === 'setup'} />
      <TabItem label="Generate" onClick={props.toggleTab} isActive={props.activeTab === 'generate'} />
    </Tabs>
  );

  const showSendDropdown = props.onSendEmailClick && !props.hideSendButton;

  return (
    <Level className="light-bottom-border mbn templates-header email">
      <Level.Side align="left">
        <Level.Item>
          <Link to={backLink} className="button is-secondary is-round-button">
            <Icon name="chevron_left" size={16} theme="regular" />
          </Link>
        </Level.Item>
        <Level.Item>
          <div className="mr-2">
            <IconPill iconName="email" iconTheme="filled" size="m" color="purple" theme="circle" />
          </div>
          <EditableTemplateName
            template={props.template}
            canEdit={props.canEdit}
            orderedInputs={props.orderedInputs}
            onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
          />
        </Level.Item>
      </Level.Side>
      {producer && props.toggleTab ? tabs : null}
      <Level.Side align="right" className="w-72">
        <React.Fragment>
          <Level.Item>
            <AccessAvatarsWithShareButton
              template={props.template}
              accessesByTemplateId={props.accessesByTemplateId}
              showShareButton={true}
            />
          </Level.Item>
          {props.canEdit && props.toggleTab ? (
            <React.Fragment>
              <Level.Item>
                {shouldShowTemplateConditionsIcon ? (
                  <div
                    data-tooltip-id="matik-tooltip"
                    data-tooltip-content={conditionalIconTooltip}
                    onClick={openConditionsModal}
                    disabled={!(props.canEdit && producer)}
                  >
                    <IconPill iconName="conditional" size="m" color="blue" theme="circle" />
                  </div>
                ) : null}
              </Level.Item>
              {!showSendDropdown && (
                <Level.Item>
                  <DropdownMenu
                    dropdownTrigger={
                      <Button category="secondary" onClick={toggleSetupDropdown}>
                        <Icon name="more" size={16} theme="regular" />
                      </Button>
                    }
                    active={showSetupDropdown}
                    onClose={() => setShowSetupDropdown(false)}
                  >
                    {props.canEdit && (
                      <>
                        <DropdownMenuItem>
                          <ListItem
                            id="conditional"
                            onClick={openConditionsModal}
                            icon={<Icon size={20} theme="regular" name="conditional" />}
                            title="Add Send Conditions"
                          />
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                          <ListItem
                            id="export"
                            href={`data:text/html,${encodeURIComponent(props.template?.slides?.[0]?.slide_xml)}`}
                            onClick={() => API.track('email_template_export_html_click')}
                            download={`${props.template.name}.html`}
                            icon={<Icon name="code" size={20} theme="regular" />}
                            title="Export HTML"
                          />
                        </DropdownMenuItem>
                        {enableEmailCopy && (
                          <DropdownMenuItem>
                            <ListItem
                              id="copy"
                              onClick={props.onCopyClick}
                              icon={<Icon name="copy" size={20} theme="regular" />}
                              title="Copy Template"
                            />
                          </DropdownMenuItem>
                        )}
                        <DropdownMenuItem>
                          <ListItem
                            id="archive"
                            onClick={onTemplateArchive}
                            tooltip="Archive this template to hide it from end users."
                            icon={<Icon name="archive" size={20} theme="regular" />}
                            title="Archive Template"
                          />
                        </DropdownMenuItem>
                        <hr className="my-1" />
                        <DropdownMenuItem>
                          <ListItem
                            id="delete"
                            onClick={onTemplateDelete}
                            icon={<Icon name="trash_can" size={20} theme="regular" />}
                            title="Delete Template"
                          />
                        </DropdownMenuItem>
                      </>
                    )}
                  </DropdownMenu>
                </Level.Item>
              )}
            </React.Fragment>
          ) : (
            <Level.Item>
              <button
                disabled={!favorites}
                className="button is-secondary"
                onClick={() => updateFavorite(props.template, !isFavorite)}
              >
                {isFavorite ? (
                  <span>
                    <Icon name="star" size={16} theme="filled" />
                    <span className="ml-2">Remove from Favorites</span>
                  </span>
                ) : (
                  <span className="button-span">
                    <Icon name="star" size={16} theme="regular" />
                    <span className="ml-2">Add to Favorites</span>
                  </span>
                )}
              </button>
            </Level.Item>
          )}
        </React.Fragment>
        <Level.Item className="is-relative">
          {showSendDropdown ? (
            <EmailSendDropdown
              isSendingDisabled={isSendingDisabled}
              submitTestEmail={props.submitTestEmail}
              onSendEmailClick={props.onSendEmailClick}
              isSchedulingEnabled={isSchedulingEnabled}
              tooltip={tooltip}
              onScheduleOpen={onScheduleOpen}
            />
          ) : null}
        </Level.Item>
      </Level.Side>
      <TemplateConditionsModal
        show={ui.fullScreenModal?.name === 'conditionsModal'}
        currentTemplate={props.template}
        conditionLevel="template"
      />
    </Level>
  );
}

EmailTemplateHeader.propTypes = {
  onTemplateDelete: PropTypes.func,
  onTemplateArchive: PropTypes.func,
  accessesByTemplateId: PropTypes.object,
  template: PropTypes.object,
  canEdit: PropTypes.bool,
  orderedInputs: PropTypes.array,
  onTemplateUpdateWithServerCall: PropTypes.func,
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func,
  onSendEmailClick: PropTypes.func,
  allInputsFilledOut: PropTypes.bool,
  submitTestEmail: PropTypes.func,
  canUndo: PropTypes.bool,
  hideSendButton: PropTypes.bool,
  onCopyClick: PropTypes.func,
};

export default EmailTemplateHeader;
