import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import TemplateMain from './TemplateMain';
import BulkPresentationPreview from '../../consumer/BulkPresentationPreview';
import GeneratedPresentation from '../../consumer/GeneratedPresentation';
import ScheduledPresentationPreview from 'components/consumer/ScheduledPresentationPreview';
import { Container } from 'react-bulma-components';
import TemplatesListMain from '../../shared/templates/TemplatesListMain';
import Constants from '../../Constants';
import BulkPresentationComplete from '../../consumer/BulkPresentationComplete';
import ScheduledPresentationComplete from '../../consumer/ScheduledPresentationComplete';

class TemplatesContent extends Component {
  componentDidMount() {
    this.props.onSearchParamsChange(0, null, Constants.PAGE_SIZE, this.activeSidebarMenuItem());
  }
  render() {
    const renderTemplatesList = () => {
      return (
        <TemplatesListMain
          isFetching={this.props.isLoading}
          templates={this.props.templates}
          emptyInfo={this.props.emptyInfo}
          entityType="template"
          addNarrative={this.props.addNarrative}
          onSearchParamsChange={this.props.onSearchParamsChange}
          pagination={this.props.pagination}
          isPaginating={this.props.isPaginating}
        />
      );
    };
    const renderTemplate = (props) => {
      return (
        <TemplateMain
          {...props}
          onPresentationCreate={this.props.onPresentationCreate}
          emptyInfo={this.props.emptyInfo}
          onTemplateAdd={this.props.onTemplateAdd}
        />
      );
    };
    const renderBulkPreview = (props) => {
      return (
        <Container className="consumer-section is-fluid bg-matik-white">
          <BulkPresentationPreview
            bulkPresentationId={props.match.params.bulk_presentation_id}
            selectPresentation={this.selectPresentation}
            templateId={props.match.params.template_id}
            urlBase="/templates/"
          >
            <GeneratedPresentation
              isBulk={true}
              fetchPresentation={this.props.fetchPresentation}
              presentations={this.props.presentations}
              selectPresentation={this.selectPresentation}
              emptyInfo={this.props.emptyInfo}
              elements={this.props.presentations}
              isLoading={this.props.isLoading}
            />
          </BulkPresentationPreview>
        </Container>
      );
    };

    const renderBulkComplete = (props) => {
      return (
        <BulkPresentationComplete
          presentationId={props.location.state?.presentationId}
          selectPresentation={this.selectPresentation}
          templateId={props.match.params.template_id}
          urlBase="/templates/"
        />
      );
    };

    const renderSchedulePreview = (props) => {
      return (
        <Container className="consumer-section is-fluid bg-matik-white">
          <ScheduledPresentationPreview
            presentationId={props.match.params.presentation_id}
            schedule={this.props.schedule}
            selectPresentation={this.selectPresentation}
            templateId={props.match.params.template_id}
            urlBase="/templates/"
          >
            <GeneratedPresentation
              isBulk={true}
              isSchedule={true}
              fetchPresentation={this.props.fetchPresentation}
              presentations={this.props.presentations}
              selectPresentation={this.selectPresentation}
              emptyInfo={this.emptyInfo}
              elements={this.props.presentations}
              isLoading={this.props.isLoading}
            />
          </ScheduledPresentationPreview>
        </Container>
      );
    };

    const renderScheduleComplete = (props) => {
      return (
        <ScheduledPresentationComplete
          presentationId={props.location.state?.presentationId}
          templateId={props.match.params.template_id}
          selectPresentation={this.selectPresentation}
          urlBase="/create/templates/"
        />
      );
    };

    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/:template_id/bulk/:bulk_presentation_id/preview/:presentation_id`}
          render={renderBulkPreview}
        />
        <Route
          exact
          path={`${this.props.match.path}/:template_id/bulk/:bulk_presentation_id/complete`}
          render={renderBulkComplete}
        />
        <Route
          exact
          path={`${this.props.match.path}/:template_id/presentation/:presentation_id/schedule-preview`}
          render={renderSchedulePreview}
        />
        <Route
          exact
          path={`${this.props.match.path}/:template_id/presentation/:presentation_id/schedule-complete`}
          render={renderScheduleComplete}
        />
        <Route path={`${this.props.match.path}/:template_id/presentation/:presentation_id/complete`}>
          <Container className="consumer-section is-fluid">
            <GeneratedPresentation
              fetchPresentation={this.props.fetchPresentation}
              presentations={this.props.presentations}
              selectPresentation={this.selectPresentation}
              emptyInfo={this.emptyInfo}
              elements={this.props.presentations}
              isLoading={this.props.isLoading}
            />
          </Container>
        </Route>
        <Route path={`${this.props.match.path}/:id`} render={renderTemplate} />
        <Route exact path={this.props.match.path} render={renderTemplatesList} />
      </Switch>
    );
  }

  selectPresentation = (presentationId) => {
    let selectedPresentation = this.props.presentations.filter(
      (presentation) => presentation.id === parseInt(presentationId),
    );
    if (selectedPresentation.length === 0 && presentationId) {
      this.props.history.push('/');
    }
    return selectedPresentation[0];
  };

  activeSidebarMenuItem = () => {
    return this.props.match.params.item || Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates;
  };
}

TemplatesContent.propTypes = {
  onSearchParamsChange: PropTypes.func,
  isPaginating: PropTypes.bool,
  match: PropTypes.object,
  pagination: PropTypes.object,
  schedule: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  templates: PropTypes.array,
  addNarrative: PropTypes.func,
  history: PropTypes.object,
  onPresentationCreate: PropTypes.func,
  fetchPresentation: PropTypes.func,
  presentations: PropTypes.array,
  emptyInfo: PropTypes.object,
  isLoading: PropTypes.bool,
  onTemplateAdd: PropTypes.func,
};

export default withRouter(TemplatesContent);
