import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Constants from '../../../Constants';
import ApiFormWithQuery from './ApiFormWithQuery';
import MatikLogoForm from './MatikLogoForm';
import { mapApiInfoStateToProps } from '../../../../redux/dataSources/stateMappers';
import { mapDispatchToProps } from '../../../../redux/dataSources/dispatchers';
import LookerDynamicContentForm from './LookerDynamicContentForm';
import TableauDynamicContentForm from './TableauDynamicContentForm';
import RestDynamicContentForm from './RestDynamicContentForm';
import SpreadSheetForm from './SpreadSheetForm';
import GainsightForm from './GainsightForm';
import SmallLoader from '../../../shared/SmallLoader';
import ProductboardForm from './ProductboardForm';
import MatikAIForm from './MatikAIForm';
import MatikAutoInsightsForm from './MatikAutoInsightsForm';
import PowerBIForm from './PowerBIForm';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

class ApiForm extends Component {
  componentDidMount() {
    if (this.props.dataSource) {
      this._fetchApi();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dataSource && (!prevProps.dataSource || prevProps.dataSource.id !== this.props.dataSource.id)) {
      this._fetchApi();
    }
  }

  _fetchApi() {
    if (this.props.dataSource.type === 'salesforce') {
      this.props.fetchReportCountFromApi(this.props.dataSource.id);
      this.props.fetchObjectCountFromApi(this.props.dataSource.id);
    } else if (this.props.dataSource.type === 'looker') {
      this.props.fetchApiInfoIfNeeded(this.props.dataSource.id, 'dashboards');
      this.props.fetchApiInfoIfNeeded(this.props.dataSource.id, 'models');
    } else if (this.props.dataSource.type === 'tableau') {
      this.props.fetchApiInfoIfNeeded(this.props.dataSource.id);
    } else if (this.props.dataSource.type === 'gainsight') {
      this.props.fetchApiInfoIfNeeded(this.props.dataSource.id, 'objects');
    } else if (this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.hubspot) {
      this.props.fetchApiInfoIfNeeded(this.props.dataSource.id);
    }
  }

  render() {
    let body = '';
    if (this.props.isFetching || this.props.isLoading) {
      body = <SmallLoader />;
    } else {
      if (this.props.dataSource) {
        if (
          this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce ||
          this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.hubspot
        ) {
          const queryObj = this.context.query ? JSON.parse(this.context.query) : {};
          body = (
            <ApiFormWithQuery
              queryObj={queryObj}
              apiInfo={this.props.apiInfo}
              inputsInQueryString={this.props.inputsInQueryString}
              updateTestResult={this.props.updateTestResult}
              fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
              dataSourceId={this.props.dataSource.id}
              dataSource={this.props.dataSource}
              refreshReport={this.refreshReport}
              formRef={this.props.formRef}
              reportCount={this.props.reportCount}
              objectCount={this.props.objectCount}
            />
          );
        } else if (this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.looker) {
          const queryObj = this.context.query ? JSON.parse(this.context.query) : {};
          body = (
            <LookerDynamicContentForm
              apiInfo={this.props.apiInfo}
              apiErrors={this.props.apiErrors}
              fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
              dataSourceId={this.props.dataSource.id}
              inputsInQueryString={this.props.inputsInQueryString}
              updateTestResult={this.props.updateTestResult}
              formRef={this.props.formRef}
              queryObj={queryObj}
            />
          );
        } else if (this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.tableau) {
          const queryObj = this.context.query ? JSON.parse(this.context.query) : {};
          body = (
            <TableauDynamicContentForm
              queryObj={queryObj}
              apiInfo={this.props.apiInfo}
              fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
              dataSourceId={this.props.dataSource.id}
              inputsInQueryString={this.props.inputsInQueryString}
            />
          );
        } else if (this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.gainsight) {
          const queryObj = this.context.query ? JSON.parse(this.context.query) : {};
          body = (
            <GainsightForm
              queryObj={queryObj}
              apiInfo={this.props.apiInfo}
              fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
              dataSourceId={this.props.dataSource.id}
            />
          );
        } else if (this.props.dataSource && this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.matik_logos) {
          body = (
            <MatikLogoForm inputsInQueryString={this.props.inputsInQueryString} dataSource={this.props.dataSource} />
          );
        } else if (this.props.dataSource?.type === Constants.DATA_SOURCE_TYPES.matik_ai) {
          body = (
            <MatikAIForm dataSourceId={this.props.dataSource.id} inputsInQueryString={this.props.inputsInQueryString} />
          );
        } else if (this.props.dataSource?.type === Constants.DATA_SOURCE_TYPES.matik_auto_insights) {
          body = <MatikAutoInsightsForm />;
        } else if (this.props.dataSource?.type === Constants.DATA_SOURCE_TYPES.productboard) {
          const queryObj = this.context.query ? JSON.parse(this.context.query) : {};
          body = (
            <ProductboardForm
              queryObj={queryObj}
              dataSourceId={this.props.dataSource.id}
              inputsInQueryString={this.props.inputsInQueryString}
            />
          );
        } else if (this.props.dataSource && this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.api) {
          const queryObj = this.context.query ? JSON.parse(this.context.query) : {};
          body = (
            <RestDynamicContentForm
              queryObj={queryObj}
              fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
              dataSource={this.props.dataSource}
              inputsInQueryString={this.props.inputsInQueryString}
            />
          );
        } else if (
          (this.props.dataSource && this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet) ||
          (this.props.dataSource && this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.excel)
        ) {
          const queryObj = this.context.query ? JSON.parse(this.context.query) : {};
          body = (
            <SpreadSheetForm
              queryObj={queryObj}
              dataSource={this.props.dataSource}
              allDynamicContent={this.props.allDynamicContent}
              contents={this.props.contents}
              columnNameError={this.props.columnNameError}
            />
          );
        } else if (this.props.dataSource?.type === Constants.DATA_SOURCE_TYPES.power_bi) {
          const queryObj = this.context.query ? JSON.parse(this.context.query) : {};
          body = <PowerBIForm queryObj={queryObj} dataSourceId={this.props.dataSource.id} />;
        }
      }
    }
    return (
      <React.Fragment>
        <div className="pal">{body}</div>
      </React.Fragment>
    );
  }

  refreshReport = (reportId, reportName) => {
    this.props.refreshSalesforceReportMetadata(this.props.dataSource.id, reportId, reportName);
  };
}

ApiForm.contextType = DynamicContentContext;

ApiForm.propTypes = {
  allDynamicContent: PropTypes.array,
  contents: PropTypes.object,
  dataSource: PropTypes.object,
  inputsInQueryString: PropTypes.object,
  updateTestResult: PropTypes.func,
  isFetching: PropTypes.bool,
  apiInfo: PropTypes.object,
  apiErrors: PropTypes.object,
  fetchApiInfoIfNeeded: PropTypes.func,
  isLoading: PropTypes.bool,
  columnNameError: PropTypes.string,
  refreshSalesforceReportMetadata: PropTypes.func,
  formRef: PropTypes.object,
  fetchReportCountFromApi: PropTypes.func,
  fetchObjectCountFromApi: PropTypes.func,
  reportCount: PropTypes.number,
  objectCount: PropTypes.number,
};

export default connect(mapApiInfoStateToProps, mapDispatchToProps)(ApiForm);
