import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import utils from 'lib/utils';
import { closeSidepane } from 'redux/ui/action';
import Icon from 'components/lib/Icon';
import { UserContext } from 'components/UserContext';
import DropdownMenu from 'components/lib/DropdownMenu';
import { MConfirm } from 'components/shared/Alerts';
import { flowTypes, flowStatus, runStatus } from './Constants';
import DropdownMenuItem from '../../lib/DropdownMenuItem';
import ListItem from '../../lib/ListItem';

function FlowActionDropdown({ dropdownTrigger, flow, onFlowDeactivate, onFlowDelete, onUpdateClick }) {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const match = useRouteMatch();
  const isAdminView = match.path.indexOf('create') === -1;
  const baseUrl = isAdminView ? '' : '/create';

  const onFlowUpdate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onUpdateClick(flow);
  };

  const onFlowDeactivateClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onFlowDeactivate(flow.id);
  };

  const onFlowDeleteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    MConfirm('Delete', `Are you sure you want to delete this ${flow.flow_type} flow?`, 'warning', (confirmed) => {
      if (confirmed) {
        onFlowDelete(flow);
        if (ui.sidepane) {
          dispatch(closeSidepane());
        }
      }
    });
  };

  return (
    <DropdownMenu dropdownTrigger={dropdownTrigger}>
      {flow?.template?.id && !flow?.template?.deleted && (
        <DropdownMenuItem>
          <ListItem
            to={`${baseUrl}/templates/${flow?.template?.id}`}
            icon={<Icon name="dock_panel" size={20} />}
            title="View Template"
          />
        </DropdownMenuItem>
      )}
      {flow.status === runStatus.PROCESSING && (
        <DropdownMenuItem>
          <ListItem icon={<Icon name="dismiss_circle" size={20} />} title="Cancel Run" />
        </DropdownMenuItem>
      )}
      {flow.flow_type === flowTypes.SCHEDULED && (
        <DropdownMenuItem>
          <ListItem
            onClick={onFlowUpdate}
            icon={<Icon name="pencil" size={20} />}
            title={`Update ${utils.capitalizeString(flow.flow_type)} Flow`}
          />
        </DropdownMenuItem>
      )}
      {flow.status === flowStatus.ACTIVE && (
        <DropdownMenuItem>
          <ListItem onClick={onFlowDeactivateClick} icon={<Icon name="square" size={20} />} title="Deactivate Flow" />
        </DropdownMenuItem>
      )}
      {flow.user.id === user?.id && (
        <DropdownMenuItem>
          <ListItem
            onClick={onFlowDeleteClick}
            icon={<Icon name="trash_can" size={20} />}
            title={`Delete ${utils.capitalizeString(flow.flow_type)} Flow`}
          />
        </DropdownMenuItem>
      )}
    </DropdownMenu>
  );
}

FlowActionDropdown.propTypes = {
  dropdownTrigger: PropTypes.any,
  flow: PropTypes.object,
  onFlowDeactivate: PropTypes.func,
  onFlowDelete: PropTypes.func,
  onUpdateClick: PropTypes.func,
};

export default FlowActionDropdown;
