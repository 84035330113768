import React from 'react';
import AsyncLoadImage from './AsyncLoadImage';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import LinkHeading from './LinkHeading';
import { Link } from 'react-router-dom';
import Pluralize from 'pluralize';
import MultiAvatar from './MultiAvatar';
import Skeleton from 'react-loading-skeleton';
import IconPill from 'components/lib/IconPill';
import Tag from 'components/lib/Tag';

function TemplatePreview({ accesses, generationIssues, isLoading, showAccessModal, template, thumbnails }) {
  const hasIssue = !isEmpty(generationIssues?.[template.id]);
  const userAccessorIdsWithIssues = new Set();
  const groupAccessorIdsWithIssues = new Set();
  if (hasIssue) {
    Object.values(generationIssues[template.id]).forEach((generationIssuesByItemId) => {
      Object.values(generationIssuesByItemId).forEach((accessors) => {
        accessors.forEach((accessor) => {
          if (accessor.type === 'user') {
            userAccessorIdsWithIssues.add(accessor.id);
          } else {
            groupAccessorIdsWithIssues.add(accessor.id);
          }
        });
      });
    });
  }
  let generationIssueMessage = '';
  if (hasIssue) {
    if (groupAccessorIdsWithIssues.size) {
      generationIssueMessage += `${Pluralize('group', groupAccessorIdsWithIssues.size, true)} `;
      if (userAccessorIdsWithIssues.size) {
        generationIssueMessage += 'and ';
      }
    }
    if (userAccessorIdsWithIssues.size) {
      generationIssueMessage += `${Pluralize('user', userAccessorIdsWithIssues.size, true)} `;
    }
    generationIssueMessage += `${Pluralize(
      'is',
      userAccessorIdsWithIssues.size || groupAccessorIdsWithIssues.size,
    )} missing permissions to generate.`;
  }

  return (
    <div className="template-preview flex gap-3">
      {hasIssue ? (
        <IconPill iconName="error_circle" iconColor="red-500" iconTheme="filled" theme="square" size="s" />
      ) : (
        <IconPill iconName="template" iconColor="matik-black" theme="square" size="s" />
      )}
      <div className="flex-1 min-w-0">
        <div className="template-preview-header">
          <LinkHeading name={template.name} url={`/templates/${template.id}`} />
        </div>
        <TemplatePreviewThumbnails isLoading={isLoading} template={template} thumbnails={thumbnails} />
        <div className="flex justify-between items-center">
          {hasIssue ? (
            <span className="text-red-500">{generationIssueMessage}</span>
          ) : (
            <span>{template.presentation_count?.toLocaleString('en-US')} presentations generated.</span>
          )}
          <div className="relative bottom-1">
            {accesses ? (
              <a href="#dummy" onClick={showAccessModal} className="mll pts avatars-with-grey-border" id="form-share">
                <MultiAvatar avatars={accesses.bigListSharedColumnAvatars()} limit={4} size={30} />
              </a>
            ) : (
              <span>Loading...</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

TemplatePreview.propTypes = {
  accesses: PropTypes.object,
  generationIssues: PropTypes.object,
  isLoading: PropTypes.bool,
  showAccessModal: PropTypes.func,
  template: PropTypes.object,
  thumbnails: PropTypes.array,
};

export default TemplatePreview;

function TemplatePreviewThumbnails({ isLoading, template, thumbnails }) {
  const slides = thumbnails ? thumbnails : template.templates_slides;
  const hiddenSlideCount = template.templates_slides.length > 4 ? template.templates_slides.length - 4 : null;
  let previewThumbnails;
  if (isLoading) {
    previewThumbnails = (
      <div className="flex justify-start">
        {slides.map((slide, idx) => {
          if (idx < 4) {
            return <Skeleton className="mt-1 mr-2 mb-4" width="5rem" height="3rem" key={idx} />;
          }
        })}
      </div>
    );
  } else {
    if (slides.length > 0) {
      previewThumbnails = (
        <div className={`slides-thumbnails ${slides.length === 4 ? 'is-full' : ''}`}>
          {slides.map((slide, idx) => {
            const fetchStatus = {
              data: slide,
              fetchState: 'fetched',
            };
            return (
              <div key={`t${template.id}-s${idx}`}>
                <AsyncLoadImage
                  imageClass={'async-rounded has-light-gray-border connected-item-slide'}
                  isBulk={true}
                  fetchStatus={fetchStatus}
                  fetchUrl={slide.url}
                  height="30px"
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      previewThumbnails = <Skeleton className="mt-1 mr-4 mb-4" width="6rem" height="3.5rem" />;
    }
  }

  return (
    <Link to={`/templates/${template.id}`} className="flex">
      {previewThumbnails}
      {hiddenSlideCount && (
        <div className="relative right-8 bottom-1">
          <Tag label={`+${hiddenSlideCount}`} size="s" />
        </div>
      )}
    </Link>
  );
}

TemplatePreviewThumbnails.propTypes = {
  isLoading: PropTypes.bool,
  template: PropTypes.object,
  thumbnails: PropTypes.array,
};
