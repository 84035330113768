import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from '../../../lib/DropdownMenu';
import open_in_new_tab from '../../../../images/open.svg';
import { Link } from 'react-router-dom';
import utils from 'lib/utils';
import reconnect from '../../../../svg/reconnect.svg';
import Constants from 'components/Constants';
import { UserContext } from 'components/UserContext';
import { MAlert } from '../../../shared/Alerts';
import Icon from '../../../lib/Icon';

function DynamicContentDataSourceOptions(props) {
  const { selectedDataSource } = props;
  const { user } = useContext(UserContext);

  let host = { url: '' };
  if (utils.isValidJSON(selectedDataSource.host)) {
    host = JSON.parse(selectedDataSource.host);
  }
  const dropdownTrigger = selectedDataSource.id >= 0 ? <Icon name="more" size={16} theme="regular" /> : null;

  const reconnectToDataSource = () => {
    if (selectedDataSource.type === 'salesforce') {
      utils.openSalesforceOauthPopup(user.nonce, () => {}, selectedDataSource.name, 'prompt=consent', true);
    } else if (selectedDataSource.type === 'googlebq') {
      utils.openGoogleBQOauthPopup(
        user.nonce,
        () => {},
        selectedDataSource.name,
        selectedDataSource.database ? selectedDataSource.database : '',
        'prompt=consent',
      );
    } else if (selectedDataSource.type === Constants.DATA_SOURCE_TYPES.hubspot) {
      utils.openHubspotOauthPopup(user.nonce, () => {}, selectedDataSource.name, 'prompt=consent');
    } else {
      MAlert('Data source type not supported', 'Error', 'error');
    }
  };

  const preventEventPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <DropdownMenu dropdownTrigger={dropdownTrigger}>
      <div onClick={preventEventPropagation}>
        <Link to={`/data_sources/${selectedDataSource.id}`} target="_blank" rel="noreferrer">
          <div className="dropdown-item">
            <img className="dropdown-item-icon" id="open-datasource" src={open_in_new_tab} alt="Open in Matik" />
            <span>Open in Matik</span>
          </div>
        </Link>
        {selectedDataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet && (
          <Link to={{ pathname: host.url }} target="_blank" rel="noreferrer">
            <div className="dropdown-item">
              <img className="dropdown-item-icon" id="open-sheet" src={open_in_new_tab} alt="Open in Google" />
              <span className="prm">Open in Google Sheets</span>
            </div>
          </Link>
        )}
        {selectedDataSource.auth_info &&
          Object.keys(selectedDataSource?.auth_info).length === 0 &&
          (selectedDataSource.type === Constants.DATA_SOURCE_TYPES.googlebq ||
            selectedDataSource.type === Constants.DATA_SOURCE_TYPES.salesforce ||
            selectedDataSource.type === Constants.DATA_SOURCE_TYPES.hubspot) && (
            <div className="dropdown-item" onClick={reconnectToDataSource}>
              <img
                className="dropdown-item-icon"
                id="reconnect-datasource"
                src={reconnect}
                alt="Reconnect to Datasource"
              />
              <span className="prm">Reconnect to Datasource</span>
            </div>
          )}
      </div>
    </DropdownMenu>
  );
}

DynamicContentDataSourceOptions.propTypes = {
  selectedDataSource: PropTypes.object,
};

export default DynamicContentDataSourceOptions;
