import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { Form } from 'react-bulma-components';
import Modal from 'components/shared/modal/Modal';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import Constants from '../../Constants';

function CopyModal({ closeModal, itemType, onCopySubmit, ui, oldName = '' }) {
  const [name, setName] = useState(
    itemType === Constants.ItemTypes.TEMPLATE ? `Copy of ${oldName}` : `Copy_of_${oldName}`,
  );
  const [isLoading, setIsLoading] = useState(false);

  const onClose = (e) => {
    e.preventDefault();
    closeModal();
  };

  const onChange = (e) => {
    setName(itemType === Constants.ItemTypes.TEMPLATE ? e.target.value : e.target.value.replace(/[ .:|{}(),]/g, '_'));
  };

  const handleCopySubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    onCopySubmit(e, name);
  };

  return (
    <Modal
      show={ui.modal?.name === 'copyModal'}
      title={`Copy ${startCase(itemType)}`}
      onClose={onClose}
      showDefaultFooter={true}
      footerIsSticky={false}
      primaryButtonText="Copy"
      primaryButtonOnClick={handleCopySubmit}
      primaryButtonLoading={isLoading}
      primaryButtonDisabled={!name}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={onClose}
    >
      <Form.Field>
        <Form.Label>Name</Form.Label>
        <Form.Control>
          <Form.Input
            type="text"
            name="name"
            value={name}
            onChange={onChange}
            placeholder={`${startCase(itemType)} name`}
            className="long-input"
            aria-label={`${startCase(itemType)} name`}
          />
        </Form.Control>
      </Form.Field>
    </Modal>
  );
}

CopyModal.propTypes = {
  itemType: PropTypes.string,
  oldName: PropTypes.string,
  onCopySubmit: PropTypes.func,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(CopyModal);
