import React, { useState, useEffect } from 'react';
import { Card, Heading, Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import MatikGroupSelect from 'components/shared/MatikGroupSelect';
import { fetchGroupMembers, fetchGroupsIfNeeded } from 'redux/groups/action';
import { useDispatch, useSelector } from 'react-redux';
import IntegrationFolderSelectorWithFolderButton from 'components/shared/IntegrationFolderSelectorWithFolderButton';
import utils from 'lib/utils';
import { isEmpty } from 'lodash';

const GroupFolderSelector = ({
  group,
  folder,
  onFolderSelect,
  handleFolderDelete,
  isEnabled = true,
  onGroupChange,
  idx,
  options,
}) => (
  <div className="flex gap-medium items-center my-3">
    <div>
      <MatikGroupSelect
        options={options}
        value={group}
        onChange={(newGroup) => onGroupChange(newGroup, idx)}
        isEnabled={isEnabled}
        isFocusedOnMount={true}
      />
    </div>
    <div className="w-full">
      <IntegrationFolderSelectorWithFolderButton
        currentFolder={folder}
        onFolderSelect={(newFolder) => onFolderSelect(newFolder, idx)}
        currentGroup={group}
        isEnabled={isEnabled}
        onFolderDelete={handleFolderDelete}
      />
    </div>
  </div>
);

GroupFolderSelector.propTypes = {
  group: PropTypes.object,
  folder: PropTypes.shape({
    source_type: PropTypes.string,
    drive_id: PropTypes.string,
    folder_id: PropTypes.string,
    folder_name: PropTypes.string,
    id: PropTypes.number,
  }),
  onFolderSelect: PropTypes.func.isRequired,
  handleFolderDelete: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
  onGroupChange: PropTypes.func.isRequired,
  idx: PropTypes.number,
  options: PropTypes.array,
};
function CustomFolderGroupSettings({ onGroupFolderDataChange }) {
  const { groups } = useSelector((state) => ({
    isFetching: state.groups.isFetching,
    isFetchingMembers: state.groups.isFetchingMembers,
    groups: state.groups.groups,
  }));
  const [updatedGroupsWithIntegrationFolderData, setUpdatedGroupsWithIntegrationFolderData] = useState([]);
  const [initialGroupsWithIntegrationFolderDataSnapshot, setInitialGroupsWithIntegrationFolderDataSnapshot] = useState(
    {},
  );
  const dispatch = useDispatch();
  dispatch(fetchGroupsIfNeeded()).then(dispatch(fetchGroupMembers()));

  const handleAddCustomGroupFolderButton = (storageProvider) => {
    const newGroupFolder = [{}, { source_type: storageProvider }];
    const updatedGroupFolderData = [...updatedGroupsWithIntegrationFolderData, newGroupFolder];

    setUpdatedGroupsWithIntegrationFolderData(updatedGroupFolderData);
  };

  const onFolderSelect = (folder, idx) => {
    const updatedGroupFolderData = [...updatedGroupsWithIntegrationFolderData];

    updatedGroupFolderData[idx] = [updatedGroupFolderData[idx][0], folder];

    setUpdatedGroupsWithIntegrationFolderData(updatedGroupFolderData);
    onGroupFolderDataChange(updatedGroupFolderData, initialGroupsWithIntegrationFolderDataSnapshot);
  };

  useEffect(() => {
    const initAllFolderInfoForAllGroups = async () => {
      if (isEmpty(groups)) {
        return;
      }

      const groupIds = groups.map((group) => group.id);

      const getIntegrationFoldersByUserGroups = (groupIds) => {
        return new Promise((resolve) => {
          utils.getIntegrationFoldersByUserGroups(groupIds, resolve || {});
        });
      };

      const folders = await getIntegrationFoldersByUserGroups(groupIds);

      setInitialGroupsWithIntegrationFolderDataSnapshot(folders);
      setUpdatedGroupsWithIntegrationFolderData(folders);
    };

    initAllFolderInfoForAllGroups();
  }, [groups]);

  const handleGroupChange = (selectedGroup, idx) => {
    const updatedGroupFolderData = [...updatedGroupsWithIntegrationFolderData];
    updatedGroupFolderData[idx] = [selectedGroup, updatedGroupFolderData[idx][1]];
    setUpdatedGroupsWithIntegrationFolderData(updatedGroupFolderData);
    onGroupFolderDataChange(updatedGroupFolderData, initialGroupsWithIntegrationFolderDataSnapshot);
  };

  const handleFolderDelete = (idx) => {
    const updatedGroupFolderData = [...updatedGroupsWithIntegrationFolderData];
    updatedGroupFolderData.splice(idx, 1);
    setUpdatedGroupsWithIntegrationFolderData(updatedGroupFolderData);
    onGroupFolderDataChange(updatedGroupFolderData, initialGroupsWithIntegrationFolderDataSnapshot);
  };

  return (
    <Card className="is-shadowless mbl enterprise-settings-card">
      <Card.Header className="pal is-shadowless">
        <Card.Header.Title className="p-0">
          <Heading size={5} className="break-after-all">
            Custom Group Folders
            <Form.Help>Set a custom folder to export all generated content for specific groups.</Form.Help>
            <Form.Help>
              Matik will default to saving generated content to this folder for users in the specified groups(s), unless
              more granular defaults are set.{' '}
              <a
                className="agree-to-terms underline text-grey-600"
                target="_blank"
                rel="noreferrer noopener"
                /* TODO: insert link to order of saving precedence when ready. ETA unknown */
                href="http://help.matik.io/"
              >
                Learn more.
              </a>
            </Form.Help>
          </Heading>
        </Card.Header.Title>
      </Card.Header>
      <Card.Content className="space-y-2">
        {updatedGroupsWithIntegrationFolderData.map(([group, folder], idx) => {
          return (
            <React.Fragment key={idx}>
              {['google', 'microsoft', 'temp'].includes(folder?.source_type) && (
                <GroupFolderSelector
                  group={group}
                  folder={folder}
                  onFolderSelect={onFolderSelect}
                  handleFolderDelete={() => handleFolderDelete(idx)}
                  isEnabled={true}
                  onGroupChange={handleGroupChange}
                  idx={idx}
                  options={groups}
                />
              )}
            </React.Fragment>
          );
        })}
        <Card.Footer className="my-4">
          <div className="flex gap-x-2 items-center my-3">
            <Button className="flex" category="secondary" onClick={() => handleAddCustomGroupFolderButton('google')}>
              <Icon name="google_drive_logo" size={20} />
              <span className="flex ml-2">Add a Google Drive Folder</span>
            </Button>
            <Button className="flex" category="secondary" onClick={() => handleAddCustomGroupFolderButton('microsoft')}>
              <Icon name="office_365_logo" size={20} />
              <span className="flex ml-2">Add a Office 365 Folder</span>
            </Button>
          </div>
        </Card.Footer>
      </Card.Content>
    </Card>
  );
}

CustomFolderGroupSettings.propTypes = {
  onGroupFolderDataChange: PropTypes.func.isRequired,
};

export default CustomFolderGroupSettings;
