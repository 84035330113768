import React from 'react';
import PropTypes from 'prop-types';
import SlideView from '../producer/templates/SlideView';
import { Footer, Hero } from 'react-bulma-components';
import SlideThumbnailsSlider from '../producer/templates/SlideThumbnailsSlider';

function SlideCarousel(props) {
  const handleContainerClick = (e) => {
    // We're only interested in clicks on the background, i.e. either "grid-container"
    // or "grid-item" but not on the slider
    if (e.target === e.currentTarget) {
      props.toggleAllSlides(false);
    }
  };

  return (
    <>
      <SlideView
        currentTemplate={props.currentTemplate}
        selectedSlide={props.viewingSlide}
        dynamicContentTags={props.dynamicContentTags}
      />
      {props.currentTemplate?.slides?.length > 1 && (
        <Hero.Footer className="grid-container" onClick={handleContainerClick}>
          <Footer className="grid-item main" onClick={handleContainerClick}>
            <SlideThumbnailsSlider
              selectedSlides={props.selectedSlides}
              viewingSlide={props.viewingSlide}
              setViewingSlideId={props.setViewingSlideId}
              currentTemplate={props.currentTemplate}
              status={props.status}
              highlightedSlideNums={props.highlightedSlideNums}
              toggleSlide={props.toggleSlide}
              showConditionsModal={props.showConditionsModal}
              showLoopModal={props.showLoopModal}
              showingSingleSlide={props.showingSingleSlide}
              scrollToSlideIdx={props.scrollToSlideIdx}
              setScrollToSlideIdx={props.setScrollToSlideIdx}
              matchingInputs={props.matchingInputs}
              filteredSlides={props.filteredSlides}
              onCopyConditions={props.onCopyConditions}
              copiedConditions={props.copiedConditions}
              onSelectSlidesMatchingConditions={props.onSelectSlidesMatchingConditions}
            />
          </Footer>
        </Hero.Footer>
      )}
    </>
  );
}

SlideCarousel.propTypes = {
  slides: PropTypes.array,
  currentTemplate: PropTypes.object,
  dynamicContentTags: PropTypes.array,
  status: PropTypes.object,
  selectedSlides: PropTypes.array,
  toggleSlide: PropTypes.func,
  highlightedSlideNums: PropTypes.array,
  toggleAllSlides: PropTypes.func,
  showConditionsModal: PropTypes.func,
  showLoopModal: PropTypes.func,
  showingSingleSlide: PropTypes.bool,
  viewingSlide: PropTypes.object,
  setViewingSlideId: PropTypes.func,
  scrollToSlideIdx: PropTypes.number,
  setScrollToSlideIdx: PropTypes.func,
  handleClick: PropTypes.func,
  matchingInputs: PropTypes.object,
  filteredSlides: PropTypes.arrayOf(PropTypes.number),

  onCopyConditions: PropTypes.func,
  copiedConditions: PropTypes.object,
  onSelectSlidesMatchingConditions: PropTypes.func,
};

export default SlideCarousel;
