import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { startCase, isEmpty } from 'lodash';
import { Level } from 'react-bulma-components';
import Pluralize from 'pluralize';
import { useFlags } from 'launchdarkly-react-client-sdk';
import MultiAvatar from './MultiAvatar';
import AccessModal from './AccessModal';
import Constants from '../Constants';
import LinkHeading from './LinkHeading';
import OpenInNewTabIcon from './OpenInNewTabIcon';
import ClickableCloseIcon from './nav/ClickableCloseIcon';
import SelectContentType from './SelectContentType';
import { DataSourceTypeCell, InputTypeCell } from './FullPageListCells';
import ConnectedItemsPopup from './popups/ConnectedItemsPopup';
import ConnectedItems from './popups/ConnectedItems';
import Icon from '../lib/Icon';
import IconWithDropdown from './IconWithDropdown';
import Tag from '../lib/Tag';
import Button from '../lib/Button';
import ButtonGroup from '../lib/ButtonGroup';
import utils from 'lib/utils';
import { openModal } from 'redux/ui/action';

function FormHeader({
  accesses,
  clearContentType,
  connectedItems,
  contentType,
  currentContent,
  dataSourceType,
  formType,
  generationIssues,
  input,
  isLoading,
  isNew,
  isReadOnly,
  linkDisabled,
  name,
  onClose,
  onCloseDataTip,
  onContentTypeSelect,
  onDelete,
  selectedDataSource,
  showClickableCloseIcon,
  showDelete,
  url,
}) {
  const dispatch = useDispatch();
  const [connectedItemType, setConnectedItemType] = useState(null);
  const [showSelectContentType, setShowSelectContentType] = useState(false);

  const showCopyModal = (e) => {
    e.preventDefault();
    dispatch(openModal('copyModal'));
  };

  const toggle = (e, itemType) => {
    e.preventDefault();
    e.stopPropagation();
    setConnectedItemType(itemType);
  };

  const renderContentTypeDropdownIcon = () => {
    const handleTypeClick = () => {
      if (!isReadOnly) {
        if (!showSelectContentType && connectedItemType) {
          setConnectedItemType(null);
        }
        setShowSelectContentType(!showSelectContentType);
      }
    };

    let iconDataTip = Constants.CONTENT_TYPE_DISPLAY.dynamic.text;
    let dropdownIcon = <Icon name="data_driven" size={20} theme="regular" />;
    if (contentType === Constants.ContentType.STATIC) {
      iconDataTip = Constants.CONTENT_TYPE_DISPLAY.static.text;
      dropdownIcon = <Icon name="static" size={20} theme="regular" />;
    } else if (contentType === Constants.ContentType.CONDITIONAL) {
      iconDataTip = Constants.CONTENT_TYPE_DISPLAY.conditional.text;
      dropdownIcon = <Icon name="conditional" size={20} theme="regular" />;
    } else if (contentType === Constants.ContentType.INSIGHTS) {
      iconDataTip = Constants.CONTENT_TYPE_DISPLAY.insights.text;
      dropdownIcon = <Icon name="wand" size={20} theme="regular" />;
    }

    return (
      <div className="select-content-type" role="button" aria-label="Select content type" onClick={handleTypeClick}>
        <IconWithDropdown tooltip={iconDataTip}>{dropdownIcon}</IconWithDropdown>
        {showSelectContentType && (
          <SelectContentType
            clearContentType={clearContentType}
            contentType={contentType}
            isNew={isNew}
            onClose={() => setShowSelectContentType(false)}
            onContentTypeSelect={onContentTypeSelect}
          />
        )}
      </div>
    );
  };

  const renderItemTypeIcon = () => {
    return (
      <div className="pl-1">
        {input && InputTypeCell({ value: input.source_type })}
        {selectedDataSource && (
          <span className="datasource-type-icon">{DataSourceTypeCell({ value: dataSourceType })}</span>
        )}
      </div>
    );
  };

  const downloadExcelFile = (e, dataSource) => {
    e.preventDefault();
    if (dataSource?.host) {
      const dataSourceHost = JSON.parse(dataSource.host);
      if (dataSourceHost?.type === Constants.MICROSOFT.excel_type && dataSourceHost?.share_url) {
        window.open(dataSourceHost.share_url, '_blank').focus();
        return;
      }
    }
    utils.downloadExcelFile(null, dataSource);
  };

  const isMatikUserInput = input?.source_type === Constants.InputSources.MATIK_USER;

  return (
    <div className={`form-header ${isNew ? 'is-new' : ''}`}>
      <Level className="form-header-top-row">
        <Level.Side align="left" className="pls">
          {contentType && <Level.Item>{renderContentTypeDropdownIcon()}</Level.Item>}
          {(input || selectedDataSource) && renderItemTypeIcon()}
          <Level.Item>
            <LinkHeading name={name} url={url} linkDisabled={linkDisabled} />
          </Level.Item>
        </Level.Side>
        <Level.Side align="right">
          <ButtonGroup gap={0}>
            {showDelete && !isMatikUserInput && (
              <Button
                size="small"
                status={isReadOnly || isLoading ? 'disabled' : 'default'}
                category="tertiary"
                onClick={onDelete}
                data-tooltip-id="matik-tooltip"
                data-tooltip-content="Delete"
                id="form-delete"
                type="button"
                aria-label="delete"
              >
                <Icon name="trash_can" size={20} />
              </Button>
            )}
            {[Constants.ItemTypes.DYNAMIC_CONTENT, Constants.ItemTypes.INPUT].includes(formType) &&
              !isNew &&
              !isMatikUserInput && (
                <Button
                  size="small"
                  status={isReadOnly ? 'disabled' : 'default'}
                  onClick={showCopyModal}
                  data-tooltip-content="Copy"
                  data-tooltip-id="matik-tooltip"
                  id="form-copy"
                  type="button"
                  category="tertiary"
                  aria-label="copy"
                >
                  <Icon name="copy" size={20} />
                </Button>
              )}
            {!isNew && <OpenInNewTabIcon linkTo={url} className="prn" id="open-in-new-tab" />}
            {!isNew && dataSourceType === Constants.DATA_SOURCE_TYPES.excel && (
              <Button
                size="small"
                category="tertiary"
                onClick={(e) => downloadExcelFile(e, selectedDataSource)}
                data-tooltip-id="matik-tooltip"
                data-tooltip-content="Download"
                id="data-source-download"
                aria-label="download"
              >
                <Icon name="arrow_download" size={20} />
              </Button>
            )}
            {showClickableCloseIcon && <ClickableCloseIcon onClick={onClose} dataTip={onCloseDataTip} />}
          </ButtonGroup>
        </Level.Side>
      </Level>
      <Level className="form-header-bottom-row">
        <Level.Side align="left">
          <Level.Item>
            {!isNew && (
              <>
                <Level.Item className="flex gap-2">
                  Connected to
                  <ConnectedItemTags
                    connectedItems={connectedItems}
                    generationIssues={generationIssues}
                    isLoading={isLoading}
                    toggle={toggle}
                  />
                </Level.Item>
              </>
            )}
          </Level.Item>
        </Level.Side>
        <Level.Side align="right">
          <ShareItem
            isNew={isNew}
            formType={formType}
            accesses={accesses}
            currentContent={currentContent}
            selectedDataSource={selectedDataSource}
          />
        </Level.Side>
      </Level>
      {connectedItemType && (
        <ConnectedItemsPopup togglePopup={toggle}>
          <ConnectedItems
            connectedItems={connectedItems}
            connectedItemType={connectedItemType}
            generationIssues={generationIssues}
          />
        </ConnectedItemsPopup>
      )}
    </div>
  );
}

FormHeader.propTypes = {
  accesses: PropTypes.object,
  clearContentType: PropTypes.func,
  isNew: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  name: PropTypes.string,
  contentType: PropTypes.string,
  onDelete: PropTypes.func,
  connectedItems: PropTypes.object,
  generationIssues: PropTypes.object,
  currentContent: PropTypes.object,
  formType: PropTypes.string,
  input: PropTypes.object,
  onContentTypeSelect: PropTypes.func,
  showClickableCloseIcon: PropTypes.bool,
  showDelete: PropTypes.bool,
  onClose: PropTypes.func,
  onCloseDataTip: PropTypes.string,
  linkDisabled: PropTypes.bool,
  dataSourceType: PropTypes.string,
  selectedDataSource: PropTypes.object,
  url: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default FormHeader;

function ConnectedItemTags({ connectedItems, generationIssues, isLoading, toggle }) {
  const iconNamesByItemType = {
    [Constants.ItemTypes.DYNAMIC_CONTENT]: 'reading_list',
    [Constants.ItemTypes.INPUT]: 'toggle',
    [Constants.ItemTypes.TEMPLATE]: 'dock_panel',
  };
  const tags = [];
  for (const [itemType, items] of Object.entries(connectedItems)) {
    const count = items.count ?? items.items?.length;
    const label = isLoading || !items.items ? 'Loading...' : Pluralize(startCase(itemType), count, true);
    const hasGenerationIssues =
      itemType === Constants.ItemTypes.TEMPLATE &&
      generationIssues &&
      Object.values(generationIssues).some((issues) => !isEmpty(issues));
    const indicatorColor = hasGenerationIssues ? 'red-500' : null;
    tags.push(
      <Tag
        indicatorColor={indicatorColor}
        iconName={iconNamesByItemType[itemType]}
        key={itemType}
        label={label}
        onClick={(e) => toggle(e, itemType)}
        state={count > 0 ? 'default' : 'disabled'}
      />,
    );
  }
  return tags;
}

ConnectedItemTags.propTypes = {
  connectedItems: PropTypes.object,
  generationIssues: PropTypes.object,
  isLoading: PropTypes.bool,
  toggle: PropTypes.func,
};

function ShareItem({ isNew, formType, accesses, currentContent, selectedDataSource }) {
  const flags = useFlags();
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  let isEnabled;
  if (isNew) {
    isEnabled = false;
  } else if (formType === Constants.ItemTypes.DATA_SOURCE) {
    isEnabled = flags.dataSourcePermissions;
  } else if (formType === 'dynamic_content') {
    isEnabled = true;
  }
  if (!isEnabled) {
    return null;
  }

  const showAccessModal = (e) => {
    e.preventDefault();
    let modalContent;
    if (formType === Constants.ItemTypes.DATA_SOURCE) {
      modalContent = {
        id: selectedDataSource.id,
        type: Constants.ItemTypes.DATA_SOURCE,
        name: selectedDataSource.name,
      };
    } else if (formType === Constants.ItemTypes.DYNAMIC_CONTENT) {
      modalContent = {
        id: currentContent.id,
        type: Constants.ItemTypes.DYNAMIC_CONTENT,
        name: currentContent.name,
      };
    }
    dispatch(openModal('formHeaderAccessModal', modalContent));
  };

  const renderAvatars = () => {
    let body;
    if (accesses === undefined) {
      body = <span>Loading...</span>;
    } else if (!accesses.isShared()) {
      body = (
        <button className="mll button is-secondary" onClick={showAccessModal} id="form-share-empty">
          Share
        </button>
      );
    } else {
      body = (
        <a href="#dummy" onClick={showAccessModal} className="mll pts avatars-with-grey-border" id="form-share">
          <MultiAvatar avatars={accesses.bigListSharedColumnAvatars()} limit={4} size={30} />
        </a>
      );
    }
    return <Level.Item>{body}</Level.Item>;
  };

  return (
    <>
      <Level.Item className="mbs">Affecting {renderAvatars()}</Level.Item>
      {accesses && (
        <AccessModal accesses={accesses} item={ui.modal?.content} show={ui.modal?.name === 'formHeaderAccessModal'} />
      )}
    </>
  );
}
ShareItem.propTypes = {
  isNew: PropTypes.bool,
  accesses: PropTypes.object,
  formType: PropTypes.string,
  selectedDataSource: PropTypes.object,
  currentContent: PropTypes.object,
};
