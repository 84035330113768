import React, { Component } from 'react';
import { Box, Media, Heading, Block } from 'react-bulma-components';
import PropTypes from 'prop-types';
import API from '../../lib/api';
import IconWithPill from './IconWithPill';
import google_drive_logo from 'images/google_drive_logo.png';
import microsoft365_drive_logo from 'images/microsoft365_drive_logo.png';
import utils from '../../lib/utils';
import { MConfirm } from './Alerts';
import Constants from '../Constants';
import UserProfileWrapper from './UserProfileWrapper';
import Button from '../lib/Button';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

class UserProfileConnections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      googleLoading: false,
      microsoftLoading: false,
    };
  }

  render() {
    const message = 'Connect your Google Drive or Microsoft 365 to access your presentations';
    return (
      <UserProfileWrapper
        user={this.props.user}
        updateUser={this.props.updateUser}
        currentUrl={this.props.match.url}
        consumerProfile={this.props.consumerProfile}
        updateAvatar={this.updateAvatar}
        userStats={this.props.userStats}
      >
        <Box className="user-profile">
          <Heading size={4} className="pal mbn light-bottom-border">
            Drive Connections
            <span className="mts label is-secondary-description">{message}</span>
          </Heading>
          <Block className="pal">
            {this.renderConnection(Constants.USER_INTEGRATION_TYPES.google)}
            {this.renderConnection(Constants.USER_INTEGRATION_TYPES.microsoft)}
          </Block>
        </Box>
      </UserProfileWrapper>
    );
  }

  renderConnection = (type) => {
    let integrationStatus = utils.googleIntegration(this.props.user);
    let integrationLabel = 'Google Drive';
    let integrationAlt = 'Google drive';
    let integrationLogo = google_drive_logo;
    let isLoading = this.state.googleLoading;

    if (type === Constants.USER_INTEGRATION_TYPES.microsoft) {
      integrationStatus = utils.microsoftIntegration(this.props.user);
      integrationLabel = 'Microsoft 365';
      integrationAlt = 'Microsoft 365';
      integrationLogo = microsoft365_drive_logo;
      isLoading = this.state.microsoftLoading;
    }

    const connectionStatus = integrationStatus ? 'successfully' : 'not';
    const pillStatus = integrationStatus ? 'success' : 'none';
    const buttonStatus = integrationStatus ? 'Remove Connection' : 'Connect';
    const onConnectClick = (e) => {
      e.preventDefault();
      this.onConnect(type, integrationStatus);
    };
    const buttonLoading = isLoading ? 'loading' : 'default';
    return (
      <Media className="flex justify-between">
        <div className="flex">
          <IconWithPill pill={pillStatus}>
            <img src={integrationLogo} alt={integrationAlt} className="icon" />
          </IconWithPill>
          <Heading size={5} className="mlm">
            {integrationLabel}
            <span className="mts label is-secondary-description">{`You are ${connectionStatus} connected to ${integrationLabel}`}</span>
          </Heading>
        </div>
        <Button category="secondary" onClick={onConnectClick} status={buttonLoading}>
          {buttonStatus}
        </Button>
      </Media>
    );
  };

  onConnect = (type, status) => {
    if (type === Constants.USER_INTEGRATION_TYPES.google) {
      this.setState({ googleLoading: true });
      status ? this.disconnectGoogle() : this.connectGoogle();
    }
    if (type === Constants.USER_INTEGRATION_TYPES.microsoft) {
      this.setState({ microsoftLoading: true });
      status ? this.disconnectMicrosoft() : this.connectMicrosoft();
    }
  };

  disconnectGoogle = () => {
    MConfirm(
      'Disconnect',
      'Are you sure you want to disconnect from google? Your google templates will stop working.',
      'warning',
      (confirmed) => {
        if (confirmed) {
          const googleIntegration = utils.googleIntegration(this.props.user);
          if (googleIntegration) {
            const integrationId = googleIntegration.id;
            API.delete(
              `/users/me/integrations/${integrationId}/`,
              (response) => {
                this.setState({ googleLoading: false });
                this.props.updateUser(response.data);
              },
              API.defaultError,
              null,
            );
          }
        }
      },
    );
  };

  onConnectUpdate = (response) => {
    this.setState({
      googleLoading: false,
      microsoftLoading: false,
    });
    this.props.updateUser(response);
  };

  connectGoogle = () => {
    utils.openGoogleOauthPopup(this.props.user.nonce, this.onConnectUpdate, 'prompt=consent');
  };

  disconnectMicrosoft = () => {
    MConfirm(
      'Disconnect',
      'Are you sure you want to disconnect from Microsoft 365? Your connected templates will stop working.',
      'warning',
      (confirmed) => {
        if (confirmed) {
          const microsoftIntegration = utils.microsoftIntegration(this.props.user);
          if (microsoftIntegration) {
            const integrationId = microsoftIntegration.id;
            API.delete(
              `/users/me/integrations/${integrationId}/`,
              (response) => {
                this.setState({ microsoftLoading: false });
                this.props.updateUser(response.data);
              },
              API.defaultError,
              null,
            );
          }
        }
      },
    );
  };

  connectMicrosoft = () => {
    utils.openMicrosoftOauthPopup(this.props.user.nonce, this.onConnectUpdate, 'prompt=select_account');
  };
}

UserProfileConnections.propTypes = {
  id: PropTypes.number.isRequired,
  consumerProfile: PropTypes.bool,
  updateUser: PropTypes.func,
  user: PropTypes.object,
  match: PropTypes.any,
  userStats: PropTypes.object,
  flags: PropTypes.object,
};

export default withLDConsumer()(UserProfileConnections);
