export const MAXIMUM_TIMESTAMP_GAP = 14400;
export const READ_STATE_NAMES = { unread: 'UNREAD', read: 'READ' };
export const READ_STATE_VALUES = { unread: 1, read: 2 };
export const EVENT_TYPE_NAMES = {
  all: 'ALL',
  accessChanged: 'ACCESS_CHANGED',
  presentationErrors: 'PRESENTATION_ERRORS',
  templateAssetAccessRequested: 'TEMPLATE_ASSET_ACCESS_REQUESTED',
  templateAssetAccessGranted: 'TEMPLATE_ASSET_ACCESS_GRANTED',
};
export const EVENT_TYPE_VALUES = {
  all: 0,
  accessChanged: 1,
  presentationErrors: 2,
  templateAssetAccessRequested: 3,
  templateAssetAccessGranted: 4,
};
