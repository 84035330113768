import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from '../../lib/DropdownMenu';
import { MConfirm } from '../Alerts';
import { isEmpty } from 'lodash';
import pdf from 'lib/pdf';
import Constants from 'components/Constants';
import DropdownMenuItem from '../../lib/DropdownMenuItem';
import ListItem from '../../lib/ListItem';
import Icon from '../../lib/Icon';

class PresentationActionDropdown extends Component {
  render() {
    let menuTitle = null;
    let menuIcon = null;
    if (this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT) {
      menuTitle = 'Download';
      menuIcon = <Icon name="arrow_download" size={20} />;
    } else if (this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.WORD) {
      menuTitle = 'Download';
      menuIcon = <Icon name="arrow_download" size={20} />;
    } else if (
      this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
      this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS ||
      this.props.presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS
    ) {
      if (this.props.presentation.status === 'done') {
        menuTitle = 'View';
        menuIcon = <Icon name="eye" size={20} theme="filled" />;
      }
    }

    let templateExists = true;
    if (
      !this.props.presentation.template ||
      isEmpty(this.props.presentation.template) ||
      this.props.presentation.template.deleted
    ) {
      templateExists = false;
    }

    return (
      <DropdownMenu dropdownTrigger={this.props.dropdownTrigger} active={this.props.active}>
        {this.props.presentation.presentation_file?.url && (
          <DropdownMenuItem>
            <ListItem onClick={this.onDownloadClick} title={menuTitle} icon={menuIcon} />
          </DropdownMenuItem>
        )}

        {!isEmpty(this.props.presentation.pdf) && (
          <>
            <hr className="my-1" />
            <DropdownMenuItem>
              <ListItem
                onClick={this.onPdfDownloadClick}
                icon={<Icon name="arrow_download" size={20} />}
                title="Download PDF"
              />
            </DropdownMenuItem>
          </>
        )}
        {this.props.canRead && templateExists && (
          <DropdownMenuItem>
            <ListItem
              onClick={(e) => this.props.onRegenerateClick(e, this.props.presentation)}
              icon={<Icon name="arrow_repeat_all" size={20} />}
              title="Regenerate"
            />
          </DropdownMenuItem>
        )}
        <hr className="my-1" />
        <DropdownMenuItem>
          <ListItem onClick={this.onDeleteClick} icon={<Icon name="trash_can" size={20} />} title="Delete" />
        </DropdownMenuItem>
      </DropdownMenu>
    );
  }

  onDeleteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    MConfirm('Delete', 'Are you sure you want to delete this presentation?', 'warning', (confirmed) => {
      if (confirmed) {
        this.props.onPresentationDelete(this.props.presentation);
      }
    });
  };

  onDownloadClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(this.props.presentation.presentation_file.url, '_blank');
  };

  onPdfDownloadClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    pdf.downloadPdf(this.props.presentation);
  };
}

PresentationActionDropdown.propTypes = {
  dropdownTrigger: PropTypes.any,
  presentation: PropTypes.object,
  onPresentationDelete: PropTypes.func,
  onPresentationRegenerate: PropTypes.func,
  active: PropTypes.bool,
  onRegenerateClick: PropTypes.func,
  canRead: PropTypes.bool,
};

export default PresentationActionDropdown;
