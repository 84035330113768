import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MemoSpreadsheet from './spreadsheets/MemoSpreadsheet';
import SpreadSheetFooter from './spreadsheets/SpreadSheetFooter';
import SmallLoader from 'components/shared/SmallLoader';
import API from 'lib/api';
import spreadsheetUtils from 'lib/spreadsheetUtils';
import warning_icon from 'images/warning.svg';

function SpreadsheetTemplateView({ template, className }) {
  const container = useRef();
  const [currentSheetIndex, setCurrentSheetIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [loadingErrorMessage, setLoadingErrorMessgage] = useState('Unable to load spreadsheet data');
  const [sheets, setSheets] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [parsedRowData, setParsedRowData] = useState([]);

  useEffect(() => {
    fetchRows();
  }, []);

  useEffect(() => {
    const rawRowData = rowData?.sheets?.[currentSheetIndex]?.data?.[0]?.rowData || [];
    const parsedRowData = spreadsheetUtils.formatRowDataWithStyling(rawRowData);
    setParsedRowData(parsedRowData);
  }, [currentSheetIndex, rowData]);

  const selectSheet = (sheetIndex) => {
    if (sheetIndex >= 0 && sheetIndex < sheets.length) {
      setCurrentSheetIndex(sheetIndex);
    }
  };

  const previousSheet = (e) => {
    e.preventDefault();
    let element = document.getElementById('workbook-' + (currentSheetIndex - 1));
    if (element) {
      element.scrollIntoView();
    }
    if (currentSheetIndex > 0) {
      setCurrentSheetIndex(currentSheetIndex - 1);
    }
  };

  const nextSheet = (e) => {
    e.preventDefault();
    let element = document.getElementById('workbook-' + (currentSheetIndex + 1));
    if (element) {
      element.scrollIntoView();
    }
    if (currentSheetIndex < sheets.length - 1) {
      setCurrentSheetIndex(currentSheetIndex + 1);
    }
  };

  const fetchRows = () => {
    setIsLoading(true);
    setLoadingError(false);
    const sheetData = [];
    API.get(
      `/templates/${template.id}/row_data/`,
      (response) => {
        const rows = response.data.rows;
        rows['sheets'].forEach((sheet) => {
          sheetData.push({ index: sheet.index, sheetId: sheet.sheetId, title: sheet.title });
        });
        setSheets(sheetData);
        setRowData(rows);
        setIsLoading(false);
        setLoadingError(false);
      },
      handleDocLoadError,
    );
  };

  const handleDocLoadError = (err) => {
    setIsLoading(false);
    setLoadingError(true);
    setLoadingErrorMessgage(err.message);
  };

  let spreadsheet = '';
  if (isLoading) {
    spreadsheet = (
      <div className="sheet-contents-loader ptl">
        <SmallLoader />
      </div>
    );
  } else if (loadingError) {
    spreadsheet = (
      <div className="spreadsheet-loading-error ptl">
        <div className="prs">
          <img src={warning_icon} width="30px" alt="Unable to load" />
        </div>
        <span className="spreadsheet-error-message">{loadingErrorMessage}</span>
      </div>
    );
  } else {
    spreadsheet = <MemoSpreadsheet onCellSelect={() => {}} rowData={parsedRowData} templatePage={true} />;
  }
  return (
    <div ref={container} className={className}>
      {spreadsheet}
      <SpreadSheetFooter
        selectSheet={selectSheet}
        selectPreviousSheet={previousSheet}
        selectNextSheet={nextSheet}
        documentId={template?.source?.id}
        worksheetIndex={currentSheetIndex}
        sheets={sheets}
      />
    </div>
  );
}

SpreadsheetTemplateView.propTypes = {
  template: PropTypes.object,
  className: PropTypes.string,
};

export default SpreadsheetTemplateView;
