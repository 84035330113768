import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import API from '../../../lib/api';
import PresentationsContent from './PresentationsContent';
import { Notification } from 'react-bulma-components';
import Constants from '../../Constants';
import templates_empty_icon from '../../../images/templates_empty.png';
import pdf from '../../../lib/pdf';
import utils from '../../../lib/utils';
import { toast } from 'react-toastify';

function PresentationsTab({ isProducer, match }) {
  const [isBulk, setIsBulk] = useState(false);
  const [bulkPresentationId, setBulkPresentationId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaginating, setIsPaginating] = useState(false);
  const [pagination, setPagination] = useState({});
  const [initialPresentations, setInitialPresentations] = useState(null);
  const [presentations, setPresentations] = useState([]);
  // needed to fetch correct accesses in the presentationslist component
  const [searchHits, setSearchHits] = useState([]);
  const [inputs, setInputs] = useState({});
  const [hideNotificationTimeout, setHideNotificationTimeout] = useState();
  const [notification, setNotification] = useState('');
  const [isFetchingPresentation, setIsFetchingPresentation] = useState(true);

  useEffect(() => {
    if (initialPresentations === null && presentations.length === 0) {
      const urlParams = new URLSearchParams(window.location.search);
      const paramBulkPresentationId = urlParams.get('bulk');
      let fetchBulkPresentations = false;
      if (paramBulkPresentationId !== bulkPresentationId) {
        setBulkPresentationId(paramBulkPresentationId);
        fetchBulkPresentations = true;
      }
      const downloadPdfs = urlParams.get('download_pdfs') === 'true';
      if (paramBulkPresentationId) {
        setIsBulk(true);
        if (fetchBulkPresentations) {
          API.fetchContent(
            `/bulk_presentations/${paramBulkPresentationId}/presentations/`,
            () => setIsLoading(true),
            onContentFetch,
          );
          if (downloadPdfs) {
            pdf.downloadBulkPdf(bulkPresentationId);
          }
        }
      } else {
        API.fetchContent('/presentations/?view=minimal', () => setIsLoading(true), onContentFetch);
      }
      API.get('/presentations/content/all', (response) => {
        const updatedExistingInputs = {
          param_keys: response.data.param_keys,
          params: response.data.params,
        };
        setInputs(updatedExistingInputs);
      });
    }
  }, [window.location.search, presentations]);

  useEffect(() => {
    setHideNotificationTimeout(
      setTimeout(() => {
        setNotification('');
      }, 4000),
    );
  }, [notification]);

  const updateIsFetchingPresentation = () => {
    setIsFetchingPresentation(true);
  };

  const onPresentationDelete = (deletedPresentation) => {
    const updatedPresentations = presentations.filter((presentation) => presentation.id !== deletedPresentation.id);
    setPresentations(updatedPresentations);
    utils.notify('Presentation Deleted', toast.TYPE.SUCCESS);
  };

  const onPresentationBulkDelete = (deletedPresentations) => {
    const updatedPresentations = presentations.filter(
      (presentation) => !deletedPresentations.includes(presentation.name),
    );
    setPresentations(updatedPresentations);
  };

  const onNotificationClose = () => {
    if (hideNotificationTimeout) {
      clearTimeout(hideNotificationTimeout);
    }
    setNotification('');
  };

  const onContentFetch = (presentations, pagination) => {
    presentations = filter(presentations, { deleted: false });
    if (presentations.length) {
      setPresentations(presentations);
    }
    if (initialPresentations === null) {
      setInitialPresentations(presentations);
    }
    setPagination(pagination);
    setIsLoading(false);
  };

  const beforePagination = () => {
    setIsPaginating(true);
  };

  const onContentPagination = (presentations, pagination) => {
    setPresentations(filter(presentations, { deleted: false }));
    setPagination(pagination);
    setIsPaginating(false);
  };

  const paginateContent = (offset = 0, limit = Constants.PAGE_SIZE, sort = null) => {
    let url = '/presentations/?view=minimal';
    if (isBulk) {
      url = `/bulk_presentations/${bulkPresentationId}/presentations/`;
    }
    API.fetchContent(url, beforePagination, onContentPagination, offset, limit, sort);
  };

  const emptyInfo = {
    emptyImageUrl: templates_empty_icon,
    // eslint-disable-next-line quotes
    emptyPrompt: `You haven't created any presentations yet.`,
  };
  if (isBulk) {
    emptyInfo.emptyPrompt = 'No matching presentations were found.';
  }
  return (
    <>
      <PresentationsContent
        isBulk={isBulk}
        bulkPresentationId={bulkPresentationId}
        isLoading={isLoading}
        isPaginating={isPaginating}
        pagination={pagination}
        elements={initialPresentations}
        emptyInfo={emptyInfo}
        fetchItems={paginateContent}
        initialPresentations={initialPresentations}
        presentations={presentations}
        setSearchHits={setSearchHits}
        searchHits={searchHits}
        match={match}
        onPresentationDelete={onPresentationDelete}
        entityType="presentation"
        inputs={inputs}
        isProducer={isProducer}
        isFetchingPresentation={isFetchingPresentation}
        updateIsFetchingPresentation={updateIsFetchingPresentation}
        onPresentationBulkDelete={onPresentationBulkDelete}
      />
      {notification && (
        <Notification color="success" className="bottom-notification">
          <button className="delete" onClick={onNotificationClose}></button>
          {notification}
        </Notification>
      )}
    </>
  );
}

PresentationsTab.propTypes = {
  match: PropTypes.any,
  isProducer: PropTypes.bool,
};

export default PresentationsTab;
