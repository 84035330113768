import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import utils from '../../../../lib/utils';

function ApiLimit({ limitValue, isReadOnly, onLimitUpdate }) {
  const onLimitChange = (e) => {
    e.preventDefault();
    const limit = e.target.value;
    onLimitUpdate(limit);
  };

  return (
    <React.Fragment>
      <Form.Field className="mbl">
        <Form.Label>Result Limit</Form.Label>
        <Form.Help>Maximum number of results to return</Form.Help>
        <Form.Control>
          <Form.Input
            type="text"
            value={limitValue || ''}
            onChange={onLimitChange}
            name="limit"
            style={{ maxWidth: '50px' }}
            onKeyPress={utils.preventSubmit}
            aria-label="Result limit"
            disabled={isReadOnly}
          />
        </Form.Control>
      </Form.Field>
    </React.Fragment>
  );
}

ApiLimit.propTypes = {
  isReadOnly: PropTypes.bool,
  limitValue: PropTypes.string,
  onLimitUpdate: PropTypes.func,
};

export default ApiLimit;
