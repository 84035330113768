import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../lib/Icon';
import loading_animation from 'images/loading.gif';
import DropdownMenu from '../lib/DropdownMenu';
import TemplatesArchiveAction from '../producer/templates/TemplatesArchiveAction';
import TemplatesDeleteAction from '../producer/templates/TemplatesDeleteAction';
import Button from '../lib/Button';
import Constants from 'components/Constants';
import utils from 'lib/utils';
import DropdownMenuItem from '../lib/DropdownMenuItem';
import ListItem from '../lib/ListItem';

function TemplateCardActionDropdown(props) {
  const [active, setActive] = useState(false);
  const [linkIsLoading, setLinkIsLoading] = useState(false);

  const onTemplateDelete = (template) => {
    TemplatesDeleteAction(template, props.deleteTemplate);
  };

  const onTemplateArchive = (template) => {
    TemplatesArchiveAction(template, props.archiveTemplate);
  };

  const onShare = (template) => {
    props.openModal('accessModal', template);
  };

  const onTemplateDropdownAction = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    if (value === 'delete' && props.canEdit) {
      onTemplateDelete(props.currentTemplate);
    } else if (value === 'open') {
      if (
        props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
        props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
      ) {
        setLinkIsLoading(true);
        utils.openTemplateFileInNewTab(e, props.currentTemplate, () => {
          setLinkIsLoading(false);
        });
        return;
      }
      window.open(props.currentTemplate.url, '_blank', 'noopener,noreferrer');
    } else if (value === 'archive' && props.canEdit) {
      onTemplateArchive(props.currentTemplate);
    } else if (value === 'favorite') {
      props.onFavoriteUpdate(props.currentTemplate, !props.isFavorite);
    } else if (value === 'share') {
      onShare(props.currentTemplate);
    }
  };

  const onDropdownButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(!active);
  };

  const archiveText = props.currentTemplate.deleted ? 'Unarchive Template' : 'Archive Template';
  const archiveTip = props.currentTemplate.deleted
    ? 'Unarchive this template to show it to end users.'
    : 'Archive this template to hide it from end users.';

  return (
    <DropdownMenu
      dropdownTrigger={
        <Button category="tertiary" onClick={onDropdownButtonClick}>
          <Icon name="more_horizontal" size={20} theme="regular" />
        </Button>
      }
      active={active}
      onClose={() => setActive(false)}
    >
      {!props.currentTemplate.deleted && (
        <>
          <DropdownMenuItem>
            <ListItem
              id="favorite"
              onClick={(e) => onTemplateDropdownAction(e, 'favorite')}
              icon={<Icon name="star" size={20} theme={props.isFavorite ? 'filled' : 'regular'} />}
              title={props.isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
            />
          </DropdownMenuItem>
          <DropdownMenuItem>
            <ListItem
              id="share"
              onClick={(e) => onTemplateDropdownAction(e, 'share')}
              icon={<Icon name="share" size={20} />}
              title="Share Template"
            />
          </DropdownMenuItem>
        </>
      )}
      {props.currentTemplate.source_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL && (
        <DropdownMenuItem>
          <ListItem
            id="open"
            onClick={(e) => onTemplateDropdownAction(e, 'open')}
            icon={
              linkIsLoading ? (
                <img className="w-5 h-5 m-0 p-0 border-0 bg-transparent" src={loading_animation}></img>
              ) : (
                <Icon name="open" size={20} theme="regular" />
              )
            }
            title={
              props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES
                ? 'Open in Google Slides'
                : props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS
                ? 'Open in Google Docs'
                : props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS
                ? 'Open in Google Sheets'
                : props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
                ? 'Open in Word'
                : props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD
                ? 'Download Word'
                : props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365
                ? 'Open in PowerPoint'
                : 'Download PowerPoint'
            }
          />
        </DropdownMenuItem>
      )}
      {props.canEdit && (
        <DropdownMenuItem>
          <ListItem
            id={`archive_${props.currentTemplate?.id}`}
            onClick={(e) => onTemplateDropdownAction(e, 'archive')}
            tooltip={archiveTip}
            icon={<Icon name="archive" size={20} />}
            title={archiveText}
          />
        </DropdownMenuItem>
      )}
      {props.canEdit && (
        <>
          <hr className="my-1" />
          <DropdownMenuItem>
            <ListItem
              id={`delete_${props.currentTemplate?.id}`}
              onClick={(e) => onTemplateDropdownAction(e, 'delete')}
              icon={<Icon name="trash_can" size={20} />}
              title="Delete Template"
            />
          </DropdownMenuItem>
        </>
      )}
    </DropdownMenu>
  );
}

TemplateCardActionDropdown.propTypes = {
  currentTemplate: PropTypes.object,
  canEdit: PropTypes.bool,
  isFavorite: PropTypes.bool,
  onFavoriteUpdate: PropTypes.func,
  deleteTemplate: PropTypes.func,
  archiveTemplate: PropTypes.func,
  openModal: PropTypes.func,
};

export default TemplateCardActionDropdown;
