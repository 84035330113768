import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import API from 'lib/api';
import Constants from 'components/Constants';

export const useAllDataSources = () => {
  const [dataSources, setDataSources] = useState(null);

  const [isFetching, setIsFetching] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setIsFetching(true);
    setFetchError(null);

    let abortController = new AbortController();
    API.get(
      '/data_sources/',
      (response) => setDataSources(response.data),
      (err) => {
        setFetchError(err);
        if (err.message !== 'canceled') {
          API.defaultError(err);
        }
      },
      null,
      abortController,
    ).finally(() => {
      setIsFetching(false);
      abortController = undefined;
    });

    return () => {
      abortController?.abort();
    };
  }, []);

  return {
    isFetching,
    fetchError,
    dataSources,
  };
};

export const useDataSourceConnectedItems = (id) => {
  const queryKey = ['connected_items', parseInt(id)];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        `/data_sources/${id}/get_datasource_connected_items/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id && !isNaN(id),
    cacheTime: 0,
  });

  return {
    isPending: isLoading,
    isError,
    error,
    data: data?.result ?? {
      [Constants.ItemTypes.DYNAMIC_CONTENT]: {},
      [Constants.ItemTypes.INPUT]: {},
      [Constants.ItemTypes.TEMPLATE]: {},
    },
  };
};
