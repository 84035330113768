import React from 'react';
import PropTypes from 'prop-types';

function DropdownMenuItem({ children }) {
  return <div className="py-2 px-4 hover:bg-grey-100">{children}</div>;
}

DropdownMenuItem.propTypes = {
  children: PropTypes.node,
};

export default DropdownMenuItem;
