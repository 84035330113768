import ApiReturnFields from './ApiReturnFields';
import PropTypes from 'prop-types';
import React from 'react';
import { difference } from 'lodash';

function LookerReturnFields({
  queryObj,
  allFields,
  tableCalcDependencies,
  onReturnFieldAdd,
  onReturnFieldRemove,
  onReturnFieldSelectAll,
  onReturnFieldClear,
  onReturnFieldHide,
  onReturnFieldShow,
  onReturnFieldMappingUpdate,
}) {
  const allFieldsUnion = [...(queryObj.fields || []), ...(queryObj.hidden_fields || []), ...(allFields || [])];
  const allFieldsArray = [...new Set(allFieldsUnion)];

  let returnFieldsInErrorState = false;

  const fieldOptions = allFieldsArray.map((fieldName) => {
    const selected = queryObj.fields.includes(fieldName) || queryObj.hidden_fields.includes(fieldName);
    const calculationDependencies = tableCalcDependencies[fieldName];
    const calculatedToolTip =
      calculationDependencies && `This field is calculated based on: ${calculationDependencies.join(', ')}`;

    const hidden = queryObj.hidden_fields.includes(fieldName);

    const hideable = !queryObj.pivots?.includes(fieldName);

    const returnFieldMapping = queryObj.return_field_mapping || {};

    let hideToolTip;
    let errorMessage;
    // default to true. Implement during automation phase: set to whether or not it is in table calc dependency tree
    const removable = true;
    let removeToolTip;
    if (selected) {
      if (!hideable && !hidden) {
        hideToolTip = 'This field cannot be hidden because it is currently being used to pivot the results.';
      } else if (hidden) {
        hideToolTip = 'This field is hidden. Un-hide it to display it in the dynamic content results.';
      } else {
        hideToolTip =
          'This field will be included in the results returned. Click to hide it. (Hidden fields are accounted for in the query, but will not be visibly displayed in the results).';
      }

      const missingDependencies = difference(calculationDependencies || [], queryObj.fields, queryObj.hidden_fields);
      if (!allFields.includes(fieldName)) {
        errorMessage = 'This field can no longer be found in Looker. Remove it or replace it with a valid field.';
      } else if (hidden && !hideable) {
        errorMessage =
          'This field cannot be used to pivot results if it is hidden. Please unhide this field or remove it from the pivots.';
      } else if (missingDependencies.length === 1) {
        errorMessage = `This calculated field is missing its dependent field: ${missingDependencies[0]}.`;
      } else if (missingDependencies.length > 1) {
        errorMessage = `This calculated field is missing its dependent fields: ${missingDependencies.join(', ')}.`;
      }

      removeToolTip = removable
        ? 'Remove this field entirely.'
        : 'This field can’t be removed because it is used to calculate {field}';
      returnFieldsInErrorState = returnFieldsInErrorState || !!errorMessage;
    }

    return {
      name: fieldName,
      selected: selected,
      inErrorState: !!errorMessage,
      errorMessage: errorMessage,
      hidden: hidden,
      hideable: hideable,
      hideToolTip: hideToolTip,
      removable: removable,
      removeToolTip: removeToolTip,
      iconName: calculationDependencies ? 'math_formula' : '',
      iconToolTip: calculationDependencies ? calculatedToolTip : '',
      alias: returnFieldMapping[fieldName] || '',
    };
  });

  return (
    <ApiReturnFields
      inErrorState={returnFieldsInErrorState}
      errorMessage={
        returnFieldsInErrorState
          ? 'There are issues with one or more of your selected fields. Hover over the field to see more about the error and how to resolve.'
          : ''
      }
      fieldOptions={fieldOptions}
      canHide={true}
      onReturnFieldAdd={onReturnFieldAdd}
      onReturnFieldRemove={onReturnFieldRemove}
      onReturnFieldSelectAll={onReturnFieldSelectAll}
      onReturnFieldClear={onReturnFieldClear}
      onReturnFieldHide={onReturnFieldHide}
      onReturnFieldShow={onReturnFieldShow}
      onReturnFieldsMappingUpdate={onReturnFieldMappingUpdate}
      isReadOnly={false}
      noOptionsMessage="No options"
      canCreateReturnField={false}
    />
  );
}

LookerReturnFields.propTypes = {
  queryObj: PropTypes.object,
  allFields: PropTypes.array,
  tableCalcDependencies: PropTypes.object,
  onReturnFieldAdd: PropTypes.func,
  onReturnFieldRemove: PropTypes.func,
  onReturnFieldSelectAll: PropTypes.func,
  onReturnFieldClear: PropTypes.func,
  onReturnFieldHide: PropTypes.func,
  onReturnFieldShow: PropTypes.func,
  onReturnFieldMappingUpdate: PropTypes.func,
  onErrorCountUpdate: PropTypes.func,
};

export default LookerReturnFields;
