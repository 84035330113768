import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import LookerDashboardForm from './LookerDashboardForm';
import LookerModelForm from './LookerModelForm';
import { Select } from '../../../shared/FormSelect';
import utils from '../../../../lib/utils';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { isEmpty } from 'lodash';
import { convertV1QueryToV2, initializeQuery, getQueryVersion, LOOKER_QUERY_VERSION } from '../../../../lib/looker';
function LookerForm({
  apiErrors,
  apiInfo,
  dataSourceId,
  entityType,
  fetchApiInfoIfNeeded,
  formRef,
  input,
  inputMapping,
  inputsInQueryString,
  onInputMappingUpdate,
  queryObj,
  updateTestResult,
}) {
  const dynamicContentContext = useContext(DynamicContentContext);

  useEffect(() => {
    if (!queryObj || isEmpty(queryObj)) {
      let defaultQueryObj = initializeQuery();
      dynamicContentContext.onQueryObjectUpdate(defaultQueryObj);
    } else if (getQueryVersion(queryObj) === LOOKER_QUERY_VERSION.v1) {
      dynamicContentContext.onQueryObjectUpdate(convertV1QueryToV2(queryObj));
    }
  }, []);

  const selectSource = (obj, action, queryObj) => {
    if (action.action === 'select-option') {
      const source = obj.value;
      if (source === queryObj.source) {
        return;
      }
      const updatedQueryObj = initializeQuery({ source });
      if (source === 'model') {
        fetchApiInfoIfNeeded(dataSourceId, 'models');
      }
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    }
  };
  if (getQueryVersion(queryObj) === LOOKER_QUERY_VERSION.v1) {
    // The useEffect will run after this, update state, and then we'll render for real
    return null;
  }
  return (
    <div>
      <Form.Field className="mbl">
        <Form.Label>Source</Form.Label>
        <Form.Help>
          Source can either be a previously created query on a dashboard or a model/view combination
        </Form.Help>
        <Form.Control>
          <Select
            aria-label="Select Looker Source"
            classNamePrefix="matik-select"
            placeholder="Select a Source"
            isDisabled={dynamicContentContext.isReadOnly}
            value={queryObj.source ? { label: utils.capitalizeString(queryObj.source), value: queryObj.source } : null}
            name="source"
            onChange={(obj, action) => selectSource(obj, action, queryObj)}
            options={[
              { label: 'Dashboard', value: 'dashboard' },
              { label: 'Model', value: 'model' },
            ]}
            menuPortalTarget={formRef}
          />
        </Form.Control>
      </Form.Field>
      {queryObj.source === 'dashboard' && (
        <LookerDashboardForm
          queryObj={queryObj}
          apiInfo={apiInfo}
          apiErrors={apiErrors}
          fetchApiInfoIfNeeded={fetchApiInfoIfNeeded}
          inputsInQueryString={inputsInQueryString}
          dataSourceId={dataSourceId}
          entityType={entityType}
          input={input}
          inputMapping={inputMapping}
          onInputMappingUpdate={onInputMappingUpdate}
          updateTestResult={updateTestResult}
          formRef={formRef}
        />
      )}
      {queryObj.source === 'model' && (
        <LookerModelForm
          queryObj={queryObj}
          apiInfo={apiInfo}
          apiErrors={apiErrors}
          fetchApiInfoIfNeeded={fetchApiInfoIfNeeded}
          inputsInQueryString={inputsInQueryString}
          dataSourceId={dataSourceId}
          entityType={entityType}
          input={input}
          inputMapping={inputMapping}
          onInputMappingUpdate={onInputMappingUpdate}
          formRef={formRef}
        />
      )}
    </div>
  );
}

LookerForm.propTypes = {
  apiInfo: PropTypes.object,
  apiErrors: PropTypes.object,
  fetchApiInfoIfNeeded: PropTypes.func,
  dataSourceId: PropTypes.number,
  inputsInQueryString: PropTypes.object,
  input: PropTypes.object,
  entityType: PropTypes.string,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  updateTestResult: PropTypes.func,
  formRef: PropTypes.object,
  queryObj: PropTypes.object,
};

export default LookerForm;
