import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Form, Level } from 'react-bulma-components';
import { cloneDeep, findIndex, isEmpty } from 'lodash';

import { openModal } from 'redux/ui/action';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import utils from 'lib/utils';
import API from 'lib/api';
import inputs from 'lib/inputs';
import ConditionalContentProcessor from 'lib/conditionalContentProcessor';
import slides from 'lib/slides';
import QueryProcessor from 'lib/queryProcessor';
import teams from 'lib/teams';
import LongRequest from 'lib/longRequest';
import { useDynamicContentInputList } from 'lib/hooks/useInputList';
import useIntegrationConnector from 'lib/hooks/useIntegrationConnector';
import dynamicContent from 'lib/dynamicContent';
import { usePresentationMutator } from 'lib/hooks/usePresentation';

import no_inputs_image from 'images/NoInputsInSlideSelection.png';
import connect_to_google from 'images/ConnectGoogleSlides.png';
import connect_to_microsoft from 'images/empty_state_small_microsoft_365.png';

import { UserContext } from 'components/UserContext';
import Constants from 'components/Constants';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import ButtonGroup from 'components/lib/ButtonGroup';
import PageLoader from 'components/shared/PageLoader';
import FormFromInputs from 'components/shared/FormFromInputs';
import WithTestQuery from 'components/shared/WithTestQuery';
import { MAlert, MConfirm, MProductUpsell } from 'components/shared/Alerts';
import ScheduledFlowModal from 'components/shared/flows/ScheduledFlowModal';
import CheckboxWithLabel from 'components/shared/CheckboxWithLabel';
import CsvBulkUploadForm from 'components/shared/presentations/CsvBulkUploadForm';
import PopupMenu from 'components/shared/PopupMenu';
import GeneratePresentationPopup from 'components/shared/GeneratePresentationPopup';
import SmallLoader from 'components/shared/SmallLoader';
import Banner, { toastBanner } from 'components/shared/Banner';
import UpsellGenericModal from 'components/payments/UpsellGenericModal';
import EmailSendDropdown from 'components/producer/email/EmailSendDropdown';
import { PresentationContext } from 'components/shared/presentations/PresentationContext';
import useInputsAndValues from 'lib/hooks/useInputsAndValues';
import { useInputsByName } from 'lib/hooks/useInput';
import { useGenerationIssuesMutator, useMyGenerationIssues } from 'lib/hooks/useAccess';
import EmptyState from 'components/lib/EmptyState';

const TABS = { INPUTS: 'inputs', UPLOAD: 'upload' };
const CSV_MODAL_NAME = 'csvBulkUploadModal';

/** Return a promise that resolves with the content + value
 * @returns `{ dynamicContent, value }`
 */
function getDynamicContentValue(dynamicContent, inputValues) {
  const formatResponseByDataSourceType = (response, datasourceType) => {
    switch (datasourceType) {
      case Constants.DATA_SOURCE_TYPES.google_sheet:
      case Constants.DATA_SOURCE_TYPES.excel:
        if (dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.TEXT) {
          if (!response.data.query_json.output?.columns) {
            response.data.result.splice(0, 0, ['result']);
          }
        }
        break;
      case Constants.DATA_SOURCE_TYPES.matik_content:
        if (dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.TEXT) {
          response = dynamicContent?.query_obj?.query_string;
        }
        break;
    }

    return response;
  };

  if (dynamicContent.dynamic_content_method === Constants.DynamicContentMethods.STATIC) {
    let previewValue;
    if (!isEmpty(dynamicContent.parameters)) {
      const firstInputKey = Object.keys(dynamicContent.parameters)[0];
      previewValue = inputValues[firstInputKey].value;
    } else if (inputValues[dynamicContent.name]) {
      previewValue = inputValues[dynamicContent.name].value;
    } else {
      let datasourceType = dynamicContent?.query_obj?.data_source?.type;
      previewValue = formatResponseByDataSourceType(dynamicContent, datasourceType);
    }
    return Promise.resolve({ dynamicContent, value: previewValue });
  } else {
    return new Promise((resolve, reject) => {
      const queryProcessor = new QueryProcessor(
        dynamicContent.query_obj.query_string,
        dynamicContent.parameters,
        inputValues,
        dynamicContent.query_obj.data_source,
      );
      const parameterizedQuery = queryProcessor.parameterizeQueryString();

      const queryData = {
        data_source: { id: dynamicContent.query_obj.data_source.id },
        dynamic_content_type: dynamicContent.dynamic_content_method,
        query_string: parameterizedQuery,
        values_by_param_name: inputValues,
      };

      const onResponse = (response, onComplete) => {
        if (response.data.result) {
          let datasourceType = dynamicContent?.query_obj?.data_source?.type;
          let formattedResponse = formatResponseByDataSourceType(response, datasourceType);

          let previewValue;
          if (dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.TABLE) {
            previewValue = formattedResponse.data.result;
          } else if (dynamicContent?.query_obj?.id) {
            previewValue = formattedResponse.data.result[1][0];
          } else {
            previewValue = formattedResponse.data.result[0][0];
          }
          resolve({ dynamicContent, value: previewValue });
          onComplete();
        }
      };

      const longRequest = new LongRequest('/queries');
      longRequest.post(
        queryData,
        onResponse,
        reject,
        undefined,
        undefined,
        `test/${dynamicContent.query_obj.data_source.type}`,
      );
    });
  }
}

const hasPresentationsRemaining = (user) => {
  if (
    user &&
    user.enterprise.plan_id === Constants.MATIK_TIERS.matik_team.tier_id &&
    user.enterprise.subscription_status === Constants.SUBSCRIPTION_STATUSES.active &&
    user.enterprise.monthly_presentations_remaining !== undefined
  ) {
    return user.enterprise.monthly_presentations_remaining > 0;
  }
  return true;
};

const handlePresentationUpsell = (user) => {
  const fetchNoPresentationsHtml = (user) => {
    const planName = Constants.MATIK_TIERS[user.enterprise.plan_id].display_name;

    return ReactDOMServer.renderToStaticMarkup(
      <UpsellGenericModal
        featureHeader={`Get more Presentations with Matik ${Constants.MATIK_TIERS.matik_enterprise.display_name}`}
        featureDescription={`Your team has already generated ${Constants.MONTHLY_PRESENTATIONS} presentations this month,
          which is the maximum allowed for the ${planName} plan.`}
      />,
    );
  };

  API.track('tier_limit_warning', {
    type: 'no_presentations_remaining',
    tier: user.enterprise.plan_id,
  });
  const noPresentationsHtml = fetchNoPresentationsHtml(user);
  API.track('enterprise_upsell_shown', { from: 'no_presentations_remaining' });
  MProductUpsell(`${noPresentationsHtml}`, false, (result) => {
    if (!result.dismiss) {
      API.track('discover_matik_enterprise_click', { from: 'no_presentations_remaining' });
      const demoUrl = teams.buildRequestDemoUrl(user, 'presentation_limit', user.enterprise.trial_days_remaining);
      window.open(demoUrl, '_blank');
    }
  });
};

const checkIncompleteTags = (template, callback) => {
  let matches = null;
  let allMatches = '';
  if (template?.slides?.[0]?.slide_xml) {
    matches = template?.slides?.[0]?.slide_xml.match(/{{\w+[<\s]/g);
  }
  if (matches) {
    allMatches = matches.shift();
    allMatches = allMatches.slice(0, -1);
    for (let match of matches) {
      allMatches = allMatches + ', ' + match.slice(0, -1);
    }
    MConfirm(
      'Incomplete Tags',
      `The email template contains incomplete dynamic content tags: '${allMatches} tag'. Resolve the tags to ensure your dynamic content populates correctly.`,
      'warning',
      (result) => {
        if (!result) {
          return false;
        } else {
          return callback();
        }
      },
      'Continue',
    );
  } else {
    callback();
  }
};

/** Check to see if the current user has access to all the given template's assets */
function useCanUserAccessAssets(template, attachmentTemplate, addedSlides, slideIdxs) {
  const templateIds = [];
  if (template) {
    templateIds.push(template.id);
  }
  if (attachmentTemplate) {
    templateIds.push(attachmentTemplate.id);
  }
  addedSlides?.forEach((added) => {
    if (!templateIds.includes(added.template.id)) {
      templateIds.push(added.template.id);
    }
  });
  const issueResults = useMyGenerationIssues(templateIds);
  if (templateIds.some((templateId) => issueResults[templateId]?.isPending)) {
    return { isPending: true, canAccessAssets: null };
  }

  const canAccessAssets = {};

  // Can we access the attachment assets?
  if (attachmentTemplate) {
    const { data: attachmentIssues } = issueResults[attachmentTemplate.id];
    if (!isEmpty(attachmentIssues?.template) || !isEmpty(attachmentIssues?.slides)) {
      canAccessAssets[attachmentTemplate.id] = false;
    }
  }

  // Can we access assets on the main template?
  if (template) {
    const { data: generationIssues } = issueResults[template.id];
    let canAccess = isEmpty(generationIssues?.template) && isEmpty(generationIssues?.slides);

    // When the user has selected only some slides, we check to see if the restricted content is on one of those slides
    if (generationIssues && slideIdxs?.length > 0) {
      const selectedSlideIds = slideIdxs?.map((selectedIndex) => template.slides[selectedIndex]?.id)?.filter(Boolean);
      if (!isEmpty(generationIssues.template)) {
        // There are inaccessible template-wide assets, so slide selection doesn't matter.
        canAccess = false;
      } else if (isEmpty(generationIssues.slides)) {
        // No issues on the slides, so all good.
        canAccess = true;
      } else {
        // Check to see if there are inaccessible assets related to the selected slides
        canAccess = selectedSlideIds.every((slideId) => isEmpty(generationIssues.slides[slideId]));
      }
    }

    canAccessAssets[template.id] = canAccess;
  }

  // Can we access all the ad-hoc slide additions?
  if (addedSlides?.length > 0) {
    addedSlides.forEach((added) => {
      const templateId = added.template.id;
      if (canAccessAssets[templateId] === false) {
        // we already know we can't access this template, so no need to do any more checks
        return;
      }

      const slideId = added.slide.id;
      const { data: generationIssues } = issueResults[templateId];
      canAccessAssets[templateId] = isEmpty(generationIssues?.slides?.[slideId]);
    });
  }

  return {
    isPending: false,
    canAccessAssets,
  };
}

const PresentationInputsForm = forwardRef(function PresentationInputsForm(props, forwardedRef) {
  const context = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [currentBulkInputName, setCurrentBulkInputName] = useState(null);
  const [processingStatus, setProcessingStatus] = useState('Processing');
  const [includePdf, setIncludePdf] = useState(false);
  const [includeTalkTracks, setIncludeTalkTracks] = useState(false);
  const [includeExecSummary, setIncludeExecSummary] = useState(false);
  const [authTokenByDynamicContent, setAuthTokenByDynamicContent] = useState({});
  const [activeTab, setActiveTab] = useState(TABS.INPUTS);
  const [customFolder, setCustomFolder] = useState({});
  const [customName, setCustomName] = useState('');
  const [customFolderPlaceholder, setCustomFolderPlaceholder] = useState('/Matik Presentations/');
  const [userBasedInputsIsLoading, setUserBasedInputsIsLoading] = useState(false);

  const timeConstructedRef = useRef(Date.now());
  const presentationCreationCancelRef = useRef();

  const isEmailTemplate = props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL;
  const isBulk = Boolean(currentBulkInputName);

  const { needsToConnect: needsToConnectToIntegration, connect: connectIntegration } = useIntegrationConnector(
    props.source_type,
  );

  const { canAccessAssets } = useCanUserAccessAssets(
    props.template,
    props.attachedTemplate,
    props.addedSlides,
    props.slideIdxs,
  );

  const history = useHistory();

  const { create: createPresentation } = usePresentationMutator();

  useImperativeHandle(
    forwardedRef,
    () => {
      return {
        submitForm: (isTestSlide) => onFormSubmit(null, isTestSlide),
      };
    },
    // onFormSubmit has many, many data dependencies. So we'll refresh the ref every render so the closure
    // has the latest.
    [Date.now()],
  );

  useEffect(() => {
    if (!props.template) {
      toastBanner(
        <Banner
          bannerType="error"
          text="Presentation's template info does not exist"
          sublineText="This is likely due to the template being deleted."
        />,
      );
    }
    return () => {
      presentationCreationCancelRef.current?.();
    };
  }, []);

  useEffect(() => {
    utils.getDefaultIntegrationFolderName(props.template?.id, (response) => {
      if (response?.length > 0) {
        setCustomFolderPlaceholder(response);
      }
    });
  }, [props.template?.id]);

  useEffect(() => {
    // for mail templates, only the first (non-nested) dynamic recipients input is bulkable so we set it to bulk for the user
    if (props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      if (
        props.template?.source?.recipients?.bulkedInput &&
        props.template?.source?.recipients?.bulkedInput !== 'None'
      ) {
        setCurrentBulkInputName(props.template.source.recipients.bulkedInput);
      } else {
        const recipientContentInputs = Object.values(props.templateContentData?.content_by_slide || {})
          .filter((content) => content.dynamic_content_type === 'recipients')
          .flatMap((content) => Object.keys(content.parameters));
        if (recipientContentInputs.length > 0) {
          setCurrentBulkInputName(recipientContentInputs[0]);
        }
      }
    }
  }, [JSON.stringify(props.templateContentData?.content_by_slide), props.template?.source?.recipients?.bulkedInput]);

  let isComplete = false;
  if (props.sortedInputs && !props.isContentFetching && !props.areInputsLoading && !userBasedInputsIsLoading) {
    const sortedInputsByName = {};
    props.sortedInputs.forEach((input) => (sortedInputsByName[input.name] = input));
    isComplete = inputs.areInputsFilledOut(props.inputValues, sortedInputsByName);
  }
  useEffect(() => {
    if (isComplete) {
      API.track('generatePresentationActive', {
        durationMs: Date.now() - timeConstructedRef.current,
        templateId: props.template?.id,
        numInputs: props.sortedInputs.length,
      });
    }

    props.setAllInputsFilledOut?.(isComplete);
  }, [isComplete]);

  // Flag any inputs dependent on the bulk input and set their values to "all options"
  useEffect(() => {
    if (props.sortedInputs) {
      const updateInputValues = {};
      props.sortedInputs.forEach((input) => {
        const isDependentOnBulkInput = inputs.inputIsDependentOnBulkInput(input, currentBulkInputName);
        const inputValue = props.inputValues[input.name];
        if (isDependentOnBulkInput) {
          let updateInputValue;
          if (!inputValue?.is_dependent_on_bulk_input) {
            updateInputValue = {};
            updateInputValue.is_dependent_on_bulk_input = true;
          }

          // List inputs or looped inputs include all available options if they depend on a bulk input.
          if (
            inputValue?.options &&
            (input.type === Constants.InputTypes.LIST || Array.isArray(inputValue.value)) &&
            JSON.stringify(inputValue.value) !== JSON.stringify(inputValue.options)
          ) {
            if (!updateInputValue) {
              updateInputValue = {};
            }
            updateInputValue.value = [...inputValue.options];
          }
          if (updateInputValue) {
            updateInputValues[input.name] = updateInputValue;
          }
        } else if (!isDependentOnBulkInput && inputValue?.is_dependent_on_bulk_input) {
          updateInputValues[input.name] = { is_dependent_on_bulk_input: false };
        }
      });
      if (Object.keys(updateInputValues).length > 0) {
        props.onInputValueChange(updateInputValues);
      }
    }
  }, [currentBulkInputName, JSON.stringify(props.sortedInputs), JSON.stringify(props.inputValues)]);

  if (props.sortedInputs?.length > 0 && (!props.inputValues || Object.keys(props.inputValues).length === 0)) {
    return null;
  }

  const buildDataObject = (isTestSlide) => {
    const contentByConditional = (templateType) => {
      const contentByConditional = {};
      let conditionalContent;
      if (templateType === 'attachment') {
        conditionalContent = props.attachmentContent.filter((content) => {
          const contentObj = props.allDynamicContent ? props.allDynamicContent[content] : null;
          return contentObj && contentObj.dynamic_content_type === Constants.DynamicContentTypes.CONDITIONAL;
        });
      } else {
        conditionalContent = props.templateContent.filter((content) => {
          const contentObj = props.allDynamicContent ? props.allDynamicContent[content] : null;
          return contentObj && contentObj.dynamic_content_type === Constants.DynamicContentTypes.CONDITIONAL;
        });
      }

      for (let conditional of conditionalContent) {
        const contentObj = props.allDynamicContent[conditional];
        const conditionalContentProcessor = new ConditionalContentProcessor(
          JSON.parse(contentObj.query_obj.query_string),
          props.inputValues,
        );
        contentByConditional[contentObj.name] = conditionalContentProcessor.getOutputContent();
      }

      return contentByConditional;
    };

    const data = {
      bulk_input_name: currentBulkInputName,
      template_id: props.template?.id,
      content_conditions: contentByConditional('body'),
      producer_triggered: props.test,
      include_pdf: includePdf,
      ai_exec_summary: includeExecSummary,
      ai_talk_tracks: includeTalkTracks,
      is_test_slide: isTestSlide,
      custom_folder: customFolder,
      custom_name: customName,
      override_presentation_id: props.presentationContext.overrideConditions
        ? props.presentationContext.presentationToRegenerate.id
        : null,
    };

    let attachmentData = {};
    if (props.template?.attached_template_id && props.attachedTemplate) {
      attachmentData = {
        attachment_template_id: props.attachedTemplate.id,
        attachment_content_conditions: contentByConditional('attachment'),
      };
    }

    if (props.addedSlides) {
      data['added_library_slides'] = slides.parseAddedSlides(props.addedSlides);
    }

    if (props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL && props.attachedTemplate) {
      data.slide_idxs = props.attachedTemplate.slides.map((slide) =>
        findIndex(props.attachedTemplate.slides, { id: slide.id }),
      );
    } else if (props.slideIdxs) {
      data.slide_idxs = props.slideIdxs;
    } else {
      data.slide_idxs = props.template?.slides.map((slide) => findIndex(props.template?.slides, { id: slide.id }));
    }

    if (!isEmpty(authTokenByDynamicContent)) {
      data.auth_token_by_dynamic_content = authTokenByDynamicContent;
    }
    return [data, attachmentData];
  };

  const onSubmitError = (err) => {
    setIsLoading(false);
    API.defaultError(err);
  };

  const submitPresentation = (data, attachmentData = {}, schedule) => {
    setIsLoading(true);
    setProcessingStatus('Processing');

    presentationCreationCancelRef.current?.();

    const generationStarted = Date.now();
    const { cancel, result } = createPresentation(data, attachmentData, setProcessingStatus);
    presentationCreationCancelRef.current = cancel;
    result
      .then((result) => {
        const { status, presentationId } = result;
        if (status === 'done') {
          setProcessingStatus('done');

          const timeElapsed = Date.now() - generationStarted;
          API.track('presentation_generate_on_tab', {
            elapsed_ms: timeElapsed,
            producer_triggered: !!props.test,
            presentation_id: presentationId,
          });

          const { presentation, attachedPresentation } = result;
          // Only error and warning logs are passed through here, and the info logs are fetched if needed.
          if (presentation.logs.length > 0) {
            if (props.test) {
              history.push(`/presentations/${presentationId}?logs=true`);
              return;
            }
          } else if (result.isNonEmailFailedCondition) {
            if (props.test) {
              history.push(`/presentations/${presentationId}`);
              return;
            }
          }

          props.onPresentationCreate(presentation, schedule, attachedPresentation);
        } else if (status === 'generation_error') {
          if (history.location.pathname.includes('create')) {
            history.push(`/create/presentations/${presentationId}?logs=true`);
          } else {
            history.push(`/presentations/${presentationId}?logs=true`);
          }
        }
      })
      .catch(onSubmitError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getBulkPresentationData = (refreshSelectAllValues, isTestSlide) => {
    const bulkInputName = currentBulkInputName;
    //
    // Unroll the bulkifiedInput into an array of input value objects, one per presentation
    //
    // For example:
    //  currentBulkInputName: 'e'
    //  props.inputValues:          { a: 1, b: 2, ..., e: {value: [3, 4, 5]} }
    //
    // Produces the following list of expandedValues:
    //  [
    //    { a: 1, b: 2, ..., e: {value: 3} },
    //    { a: 1, b: 2, ..., e: {value: 4} },
    //    { a: 1, b: 2, ..., e: {value: 5} }
    //  ]
    //
    // Each member is the params list for one presentation.
    //

    // for email tests, we still want to go through the bulk process, so we insert the first bulk value into an array
    let bulkifiedInput;

    if (isTestSlide && props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      bulkifiedInput = [props.inputValues[bulkInputName].value[0]];
    } else {
      bulkifiedInput = props.inputValues[bulkInputName].value;
    }

    if (bulkifiedInput.length > 1000 && props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      MAlert('Bulk email list is limited to 1000 recipients');
    }
    const inputsByName = {};
    if (props.sortedInputs) {
      props.sortedInputs.forEach((input) => (inputsByName[input.name] = input));
    }
    const expandedValues = bulkifiedInput.map((value) => {
      const r = cloneDeep(props.inputValues);
      if (inputsByName[bulkInputName].type === Constants.InputTypes.LIST) {
        r[currentBulkInputName].value = [value];
      } else {
        r[currentBulkInputName].value = value;
      }
      return r;
    });
    const mappedValues = expandedValues.map((exVal) => {
      return removeOptionsAndReplaceMappedValuesForInputs(exVal, refreshSelectAllValues);
    });
    return mappedValues;
  };

  const submitBulkPresentation = (baseData, attachmentData, isTestSlide) => {
    const expandedValues = getBulkPresentationData(false, isTestSlide);
    const previewValues = cloneDeep(expandedValues[0]);
    expandedValues[0]['is_preview'] = true;

    const previewData = {
      values_by_param_name: previewValues,
      is_test_slide: isTestSlide,
      ...baseData,
      ...attachmentData,
    };

    const bulkData = {
      bulk_values_by_param_name: expandedValues,
      ...baseData,
      ...attachmentData,
    };

    API.track('submit_bulk_presentation');
    API.post(
      '/bulk_presentations/',
      bulkData,
      (response) => {
        // kick off the preview job
        previewData['bulk_presentation_id'] = response.data.id;
        submitPresentation(previewData);
      },
      onSubmitError,
    );
  };

  const removeOptionsAndReplaceMappedValuesForInputs = (inputValues, refreshSelectAllValues = false) => {
    const updatedInputValues = cloneDeep(inputValues);
    const inputsByName = {};
    if (props.sortedInputs) {
      props.sortedInputs.forEach((input) => (inputsByName[input.name] = input));
    }
    for (let key in updatedInputValues) {
      if (
        refreshSelectAllValues &&
        inputs.canInputRefreshSelectAll(inputsByName[key], props.inputValues[key], currentBulkInputName)
      ) {
        updatedInputValues[key].should_refresh_value = true;
      }
      if (updatedInputValues[key].mappedOptions && !isEmpty(updatedInputValues[key].mappedOptions)) {
        const valueToReplace = updatedInputValues[key].value;
        updatedInputValues[key].unmapped_value = valueToReplace;
        if (Array.isArray(valueToReplace)) {
          updatedInputValues[key].value = valueToReplace.map((val) => {
            return updatedInputValues[key].mappedOptions[val];
          });
        } else {
          updatedInputValues[key].value = updatedInputValues[key].mappedOptions[valueToReplace];
        }
        delete updatedInputValues[key].mappedOptions;
      }
      delete updatedInputValues[key].options;
      if (updatedInputValues[key].value === Constants.NULL_VALUE_INPUT_LABEL) {
        updatedInputValues[key].value = null;
      }
    }
    return updatedInputValues;
  };

  const buildAndSubmitPresentation = (isTestSlide) => {
    const [data, attachmentData] = buildDataObject(isTestSlide);

    if (isBulk) {
      // enter the bulk path iff we actually have a bulk input or if it's a test email, which still goes through the bulk flow
      submitBulkPresentation(data, attachmentData, isTestSlide);
    } else {
      data.values_by_param_name = removeOptionsAndReplaceMappedValuesForInputs(props.inputValues);
      submitPresentation(data, attachmentData);
    }
  };

  const onFormSubmit = (e, isTestSlide = props.isTestSlide) => {
    if (e) {
      e.preventDefault();
    }
    if (!hasPresentationsRemaining(context.user)) {
      return handlePresentationUpsell(context.user);
    }

    if (needsToConnectToIntegration) {
      return connectIntegration();
    }

    if (props.presentationContext.regenerate && props.presentationContext.regenerateTemplateChanged) {
      MConfirm(
        'This template has changed',
        `This template has changed since it was last used to generate a presentation.  Matik may not be able to
            generate a presentation correctly using these inputs.`,
        'warning',
        (result) => {
          if (!result) {
            return false;
          } else {
            return buildAndSubmitPresentation(isTestSlide);
          }
        },
        'Continue',
      );
    } else if (props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL && props.isTemplateTest) {
      const contentPreview = () => {
        const previewValues = {};
        const templateDynamicContent = Object.values(props.template?.matching_dynamic_content_by_slide)[0];
        const previewDynamicContentValues = Object.values(templateDynamicContent);
        const filterPreviewDC = previewDynamicContentValues.filter((previewDynamicContentValue) => {
          if (
            previewDynamicContentValue.dynamic_content_type !== Constants.DynamicContentTypes.RECIPIENTS &&
            previewDynamicContentValue.dynamic_content_method !== Constants.DynamicContentMethods.STATIC
          ) {
            return previewDynamicContentValue;
          }
        });

        for (let key in templateDynamicContent) {
          const dynamicContent = templateDynamicContent[key];
          if (dynamicContent.dynamic_content_type !== Constants.DynamicContentTypes.RECIPIENTS) {
            getDynamicContentValue(dynamicContent, props.inputValues).then(({ dynamicContent, value }) => {
              previewValues[dynamicContent.name] = value;
              props.onEmailPreview?.(previewValues, filterPreviewDC);
            });
          }
        }
        props.onEmailPreview?.(previewValues, filterPreviewDC);
        props.closeModal();
      };
      checkIncompleteTags(props.template, contentPreview);
    } else {
      checkIncompleteTags(props.template, () => buildAndSubmitPresentation(isTestSlide));
    }
  };

  const onBulkInputToggle = (inputName, shouldBeBulk) => {
    props.onInputValueChange({ [inputName]: { value: null } });

    API.track('toggle_bulk_input', { input_name: inputName });
    setCurrentBulkInputName(shouldBeBulk ? inputName : null);
  };

  const handleSetAuthTokenByDynamicContent = (dynamicContent, token) => {
    const updatedObj = Object.assign({}, authTokenByDynamicContent);
    dynamicContent.forEach((content) => {
      updatedObj[content.name] = token;
    });
    setAuthTokenByDynamicContent(updatedObj);
  };

  const handleTabClick = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  const onPdfToggle = (e) => {
    API.track('toggle_include_pdf', { include_pdf: e.target.checked });
    setIncludePdf(e.target.checked);
  };

  const onTalkTracksToggle = (e) => {
    setIncludeTalkTracks(e.target.checked);
  };

  const onExecSummaryToggle = (e) => {
    setIncludeExecSummary(e.target.checked);
  };

  const onCustomFolderUpdate = (result) => {
    if (!result || Object.keys(result).length === 0) {
      API.track('remove_run_custom_folder');
      setCustomFolder({});
      return;
    }
    const folder = {
      folder_type: 'template',
      template_id: props.template?.id,
      ...result,
    };
    API.track('set_run_custom_folder', { folder: folder });
    setCustomFolder(folder);
  };

  const onCustomNameUpdate = (name) => {
    API.track('edit_presentation_name', { name: name });
    setCustomName(name);
  };

  const onClose = (e) => {
    e?.preventDefault();
    props.closeModal();
  };

  const templateType = Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(props.source_type)
    ? 'document'
    : props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
    ? 'email'
    : 'presentation';

  const hideButtons =
    props.isReadOnly ||
    (templateType === 'email' && !props.showEmailSendButton) ||
    (needsToConnectToIntegration && !props.isProducer);

  return (
    <React.Fragment>
      <form onSubmit={onFormSubmit} className={`presentation-input-form ${isEmailTemplate ? 'email' : ''}`}>
        <Inputs
          {...props}
          currentBulkInputName={currentBulkInputName}
          onBulkInputToggle={onBulkInputToggle}
          authTokenByDynamicContent={authTokenByDynamicContent}
          handleSetAuthTokenByDynamicContent={handleSetAuthTokenByDynamicContent}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          setUserBasedInputsIsLoading={setUserBasedInputsIsLoading}
          onAccessRequested={onClose}
        />
        <PageLoader isActive={isLoading} title={processingStatus} showLottie={!isEmailTemplate} />
        {activeTab !== TABS.UPLOAD && !hideButtons && (
          <Buttons
            {...props}
            includePdf={includePdf}
            includeTalkTracks={includeTalkTracks}
            onTalkTracksToggle={onTalkTracksToggle}
            includeExecSummary={includeExecSummary}
            onExecSummaryToggle={onExecSummaryToggle}
            onPdfToggle={onPdfToggle}
            onClose={onClose}
            isButtonActive={
              isComplete && canAccessAssets && Object.values(canAccessAssets).every((canAccess) => canAccess)
            }
            isBulk={isBulk}
            isLoading={isLoading}
            templateType={templateType}
            onFormSubmit={onFormSubmit}
            customFolder={customFolder}
            onCustomFolderUpdate={onCustomFolderUpdate}
            customName={customName}
            onCustomNameUpdate={onCustomNameUpdate}
            customFolderPlaceholder={customFolderPlaceholder}
          />
        )}
      </form>
      {templateType === Constants.TEMPLATE_SOURCE_TYPES.EMAIL && (
        <CsvBulkUploadForm template={props.template} isReadOnly={props.isReadOnly} modalName={CSV_MODAL_NAME} />
      )}
      <ScheduleModal
        currentBulkInputName={currentBulkInputName}
        buildDataObject={buildDataObject}
        getBulkPresentationData={getBulkPresentationData}
        removeOptionsAndReplaceMappedValuesForInputs={removeOptionsAndReplaceMappedValuesForInputs}
        setIsLoading={setIsLoading}
        submitPresentation={submitPresentation}
        customFolderPlaceholder={customFolderPlaceholder}
        includePdf={includePdf}
        onPdfToggle={onPdfToggle}
        customFolder={customFolder}
        onCustomFolderUpdate={onCustomFolderUpdate}
        {...props}
      />
    </React.Fragment>
  );
});

PresentationInputsForm.propTypes = {
  addedSlides: PropTypes.array,
  allDynamicContent: PropTypes.object,
  allowBulk: PropTypes.bool,
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  inputs: PropTypes.object,
  inputValues: PropTypes.object,
  isReadOnly: PropTypes.bool,
  isPresentationView: PropTypes.bool,
  onPresentationCreate: PropTypes.func,
  slideIdxs: PropTypes.array,
  slideIdsByInputName: PropTypes.object,
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
  templateContent: PropTypes.array,
  attachmentContent: PropTypes.array,
  test: PropTypes.bool,
  onInputValueChange: PropTypes.func,
  isTestSlide: PropTypes.bool,
  updateTestResult: PropTypes.func,
  tokenError: PropTypes.any,
  tokenSuccess: PropTypes.any,
  tokenLoading: PropTypes.bool,
  setTokenLoading: PropTypes.func,
  testQuery: PropTypes.func,
  onClose: PropTypes.func,
  isProducer: PropTypes.bool,
  isContentFetching: PropTypes.bool,
  inModal: PropTypes.bool,
  presentationContext: PropTypes.object,
  setAllInputsFilledOut: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  onEmailPreview: PropTypes.func,
  isTemplateTest: PropTypes.bool,
  onEmailPreviewError: PropTypes.func,
  templateContentData: PropTypes.object,
  sortedInputs: PropTypes.array,
  areInputsLoading: PropTypes.bool,
  source_type: PropTypes.string,
  allInputsFilledOut: PropTypes.bool,
  showEmailSendButton: PropTypes.bool,
  submitTestEmail: PropTypes.func,
  onSendEmailClick: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return Object.assign({}, mapUiDispatchToProps(dispatch));
}

// TODO: move this population logic to where existingInputValues are created
const useInitialInputValueEffect = (callback, seedInputValues) => {
  const inputResults = useInputsByName(seedInputValues ? Object.keys(seedInputValues) : []);
  const areInputsPending = Object.values(inputResults).some((result) => result.isPending);

  useEffect(() => {
    if (!areInputsPending && seedInputValues && Object.keys(seedInputValues).length > 0) {
      // existingInputValues are only populated with values but are missing any inputValue metadata, so we'll
      // populate the metadata here.
      const fullInputValues = {};
      Object.entries(seedInputValues).forEach(([inputName, seedInputValue]) => {
        let fullInputValue = seedInputValue;
        const input = inputResults[inputName]?.data;
        if (input) {
          fullInputValue = inputs.initInputNullValue(input);
          Object.assign(fullInputValue, seedInputValue);
          fullInputValues[inputName] = fullInputValue;
        }
      });
      callback(fullInputValues);
    }
  }, [areInputsPending, JSON.stringify(seedInputValues)]);
};

const PresentationInputsFormWrapper = forwardRef(function PresentationInputsFormWrapper(props, forwardedRef) {
  const { allTemplateContent, existingInputValues, inputs: allInputs, ...otherProps } = props;

  // Hook to get the inputs relevant to this template/presentation with a given set of input values.
  const useRelevantInputs = (inputValues) => {
    const allContent = props.templateContent.concat(props.attachmentContent || []);
    const { isLoading, orderedInputList } = useDynamicContentInputList(
      allTemplateContent?.content_by_slide,
      allContent,
      inputValues,
      props.template.params_order,
      allInputs,
    );
    return {
      isLoading,
      inputs: orderedInputList,
    };
  };

  const {
    inputValues,
    updateInputValues,
    areInputsLoading,
    inputs: orderedInputList,
  } = useInputsAndValues(useRelevantInputs);

  // "existingInputValues" are meant to pre-populate the input values in the form. e.g. when seeding
  // input values from a previously generated presentation.
  useInitialInputValueEffect((initializedValues) => {
    updateInputValues(initializedValues);
  }, existingInputValues);

  const dispatch = useDispatch();
  const reduxDispatchProps = mapDispatchToProps(dispatch);

  return (
    <PresentationContext.Consumer>
      {(presentationContext) => (
        <PresentationInputsForm
          ref={forwardedRef}
          {...otherProps}
          {...reduxDispatchProps}
          templateContentData={allTemplateContent}
          inputValues={inputValues}
          onInputValueChange={updateInputValues}
          areInputsLoading={areInputsLoading}
          sortedInputs={orderedInputList}
          presentationContext={presentationContext}
        />
      )}
    </PresentationContext.Consumer>
  );
});
PresentationInputsFormWrapper.propTypes = {
  allTemplateContent: PropTypes.object,
  attachmentContent: PropTypes.array,
  existingInputValues: PropTypes.object,
  inputs: PropTypes.object,
  inputValues: PropTypes.object,
  template: PropTypes.object,
  templateContent: PropTypes.array,
};

const TestQueryPresentationInputsForm = WithTestQuery(PresentationInputsFormWrapper);

// WithTestQuery calls back to the 'updateTestResult' prop it expects to be passed in.
const TestQueryResultHandler = forwardRef(function TestQueryResultHandler({ ...props }, forwardedRef) {
  const [tokenError, setTokenError] = useState('');
  const [tokenSuccess, setTokenSuccess] = useState('');
  const [tokenLoading, setTokenLoading] = useState(false);

  const updateTestResult = (resultData) => {
    if ((resultData && resultData.status === 'error') || !resultData) {
      setTokenError('Invalid token');
      setTokenSuccess(null);
    } else if (resultData && resultData.status === 'success') {
      setTokenSuccess('Valid Token');
      setTokenError(null);
    }
    setTokenLoading(false);
  };

  const handleTokenLoading = () => {
    setTokenLoading(true);
    setTokenSuccess(null);
    setTokenError(null);
  };

  return (
    <TestQueryPresentationInputsForm
      forwardedRef={forwardedRef} // not "ref" to work around the WithTestQuery HOC ref handling
      {...props}
      tokenLoading={tokenLoading}
      setTokenLoading={handleTokenLoading}
      tokenError={tokenError}
      tokenSuccess={tokenSuccess}
      updateTestResult={updateTestResult}
    />
  );
});
TestQueryResultHandler.displayName = 'PresentationInputsForm.TestQueryResultHandler';

export default TestQueryResultHandler;

function Inputs({
  currentBulkInputName,
  onBulkInputToggle,
  authTokenByDynamicContent,
  handleSetAuthTokenByDynamicContent,
  handleTabClick,
  activeTab,
  setUserBasedInputsIsLoading,
  onAccessRequested,
  ...props
}) {
  const { needsToConnect } = useIntegrationConnector(props.source_type);
  const { isPending: isGenIssuesPending, canAccessAssets } = useCanUserAccessAssets(
    props.template,
    props.attachedTemplate,
    props.addedSlides,
    props.slideIdxs,
  );

  if (needsToConnect) {
    return <NeedsToConnect sourceType={props.source_type} isProducer={props.isProducer} />;
  }

  if (props.isContentFetching || props.areInputsLoading || isGenIssuesPending) {
    return (
      <div>
        <SmallLoader />
      </div>
    );
  }

  if (!Object.values(canAccessAssets).every((canAccess) => canAccess)) {
    return (
      <MissingAccess
        templateIds={Object.keys(canAccessAssets)
          .filter((templateId) => !canAccessAssets[templateId])
          .map((templateId) => parseInt(templateId))}
        onAccessRequested={onAccessRequested}
      />
    );
  }

  const dataSourcesWithAuthToken = [];
  if (!props.isContentFetching && !props.isReadOnly) {
    if (!isEmpty(props.template) && !isEmpty(props.templateContentData)) {
      const dataSourcesById = utils.getDataSourcesForTemplate(props.template, props.templateContentData);
      if (dataSourcesById) {
        for (let dataSourceId in dataSourcesById) {
          const dataSource = dataSourcesById[dataSourceId];
          if (dataSource.has_auth_token) {
            dataSourcesWithAuthToken.push(dataSource);
          }
        }
      }
    }
  }

  if (props.sortedInputs?.length > 0 || dataSourcesWithAuthToken.length > 0) {
    const onTestAccess = (e, dataSource, dynamicContent, apiInputValues, authToken) => {
      if (Object.keys(dynamicContent.parameters).length !== Object.keys(apiInputValues).length) {
        MAlert('Please fill out all inputs for REST API before testing access', 'Error', 'error');
        props.updateTestResult(null);
        return;
      }
      props.setTokenLoading();
      const queryProcessor = new QueryProcessor(
        dynamicContent.query_obj.query_string,
        dynamicContent.parameters,
        apiInputValues,
        dataSource,
      );
      const parameterizedQuery = queryProcessor.parameterizeQueryString();
      props.testQuery(e, parameterizedQuery, dataSource, dynamicContent, authToken);
    };

    let matik_user_banner_text = '';
    const hasMatikUser = utils.hasMatikUserInputs(props.sortedInputs);
    const isNotEndUserView = location.pathname.indexOf('create') === -1;
    const allDynamicContentOnTemplate = {};
    if (props.templateContentData) {
      dynamicContent.setAllDynamicContentByName(props.templateContentData, allDynamicContentOnTemplate);
    }

    if (hasMatikUser) {
      const entityTypeToBannerTextMap = {
        template: 'Template',
        input: 'Input',
        dynamic_content: 'Dynamic Content',
      };

      matik_user_banner_text = entityTypeToBannerTextMap[props.entityType] || '';
    }
    return (
      <>
        {hasMatikUser && isNotEndUserView && !props.isPresentationView && (
          <div className="pb-4">
            <Banner
              bannerType="info"
              text={`This ${matik_user_banner_text} uses an input that populates based on the
            current user. To proceed, select the user you would like to impersonate.`}
            />
          </div>
        )}
        <FormFromInputs
          allowBulk={props.allowBulk}
          currentBulkInputName={currentBulkInputName}
          inputs={props.sortedInputs}
          inputValues={props.inputValues}
          isReadOnly={props.isReadOnly}
          isPresentationView={props.isPresentationView}
          onBulkInputToggle={onBulkInputToggle}
          slideIdsByInputName={props.slideIdsByInputName}
          onInputValueChange={props.onInputValueChange}
          template={props.template}
          attachedTemplate={props.attachedTemplate}
          dataSourcesWithAuthToken={dataSourcesWithAuthToken}
          onTestAccess={onTestAccess}
          allDynamicContent={allDynamicContentOnTemplate}
          tokenError={props.tokenError}
          tokenSuccess={props.tokenSuccess}
          tokenLoading={props.tokenLoading}
          authTokenByDynamicContent={authTokenByDynamicContent}
          setAuthTokenByDynamicContent={handleSetAuthTokenByDynamicContent}
          isProducer={props.isProducer}
          inModal={props.inModal}
          isTestSlide={props.isTestSlide}
          entityId={props.entityId}
          entityType={props.entityType}
          test={props.test}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          setUserBasedInputsIsLoading={setUserBasedInputsIsLoading}
        />
      </>
    );
  } else {
    return (
      <div className="no-inputs">
        <div className="no-inputs-inner">
          <img src={no_inputs_image} alt="No Inputs" />
          <p className="no-inputs-text">No inputs detected</p>
        </div>
      </div>
    );
  }
}
Inputs.displayName = 'PresentationInputsForm.Inputs';
Inputs.propTypes = {
  addedSlides: PropTypes.array,
  currentBulkInputName: PropTypes.string,
  onBulkInputToggle: PropTypes.func,
  authTokenByDynamicContent: PropTypes.object,
  handleSetAuthTokenByDynamicContent: PropTypes.func,
  handleTabClick: PropTypes.func,
  activeTab: PropTypes.string,
  setUserBasedInputsIsLoading: PropTypes.func,
  allowBulk: PropTypes.bool,
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  inputValues: PropTypes.object,
  onInputValueChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isPresentationView: PropTypes.bool,
  slideIdxs: PropTypes.array,
  slideIdsByInputName: PropTypes.object,
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
  test: PropTypes.bool,
  isTestSlide: PropTypes.bool,
  updateTestResult: PropTypes.func,
  tokenError: PropTypes.any,
  tokenSuccess: PropTypes.any,
  tokenLoading: PropTypes.bool,
  setTokenLoading: PropTypes.func,
  testQuery: PropTypes.func,
  isProducer: PropTypes.bool,
  isContentFetching: PropTypes.bool,
  inModal: PropTypes.bool,
  templateContentData: PropTypes.object,
  sortedInputs: PropTypes.array,
  areInputsLoading: PropTypes.bool,
  source_type: PropTypes.string,
  onAccessRequested: PropTypes.func,
};

function NeedsToConnect({ sourceType, isProducer }) {
  const isMS =
    sourceType === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
    sourceType === Constants.TEMPLATE_SOURCE_TYPES.WORD_365;
  return (
    <div className="connect-to-google">
      <div className="connect-to-google-inner">
        <div className="image-container">
          <img src={isMS ? connect_to_microsoft : connect_to_google} alt="Connect to Google" />
        </div>
        <p className="connect-to-google-title">{isMS ? 'Connect to Microsoft 365' : 'Connect to Google Slides'}</p>
        <p className="connect-to-google-text">
          {isMS
            ? 'You need to connect to Microsoft 365 before you can generate this presentation.'
            : 'You need to connect to Google Slides before you can generate this presentation.'}
        </p>
        {!isProducer && (
          <div className="connect-to-google-button">
            <ConnectButton sourceType={sourceType} />
          </div>
        )}
      </div>
    </div>
  );
}
NeedsToConnect.displayName = 'PresentationInputsForm.NeedsToConnect';
NeedsToConnect.propTypes = {
  sourceType: PropTypes.string,
  isProducer: PropTypes.bool,
};

function MissingAccess({ templateIds, onAccessRequested }) {
  const [didRequest, setDidRequest] = useState(false);

  const { requestAccess } = useGenerationIssuesMutator();

  const handleRequestAccess = () => {
    setDidRequest(true);
    Promise.all(templateIds.map((templateId) => requestAccess(templateId))).then(() => {
      onAccessRequested();
    });
  };

  return (
    <div className="!pb-6">
      <EmptyState
        size="small"
        title="Missing Access For Generation"
        content="You are missing access to assets associated with this template. 
        You can request access from your admin."
        buttons={[
          <Button
            key="req"
            type="button"
            category="primary"
            status={didRequest ? 'disabled' : 'default'}
            onClick={handleRequestAccess}
          >
            Request Access
          </Button>,
        ]}
      />
    </div>
  );
}
MissingAccess.propTypes = {
  templateIds: PropTypes.arrayOf(PropTypes.number),
  onAccessRequested: PropTypes.func,
};

function Buttons({
  includePdf,
  onPdfToggle,
  includeTalkTracks,
  onTalkTracksToggle,
  includeExecSummary,
  onExecSummaryToggle,
  onClose,
  isButtonActive,
  isLoading,
  onFormSubmit,
  isBulk,
  templateType,
  customFolder,
  onCustomFolderUpdate,
  customName,
  onCustomNameUpdate,
  customFolderPlaceholder,
  ...props
}) {
  const { needsToConnect } = useIntegrationConnector(props.source_type);
  const flags = useFlags();
  const isEmailTemplate = props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL;

  let buttons = null;
  if (needsToConnect) {
    if (props.isProducer) {
      buttons = <ConnectButton sourceType={props.source_type} />;
    } else {
      // the end-user connect button is in the body
      buttons = null;
    }
  } else if (isEmailTemplate) {
    buttons = props.isProducer ? (
      <AdminEmailButton isButtonActive={isButtonActive} isLoading={isLoading} />
    ) : (
      <EndUserEmailButtons isButtonActive={isButtonActive} {...props} />
    );
  } else {
    let generateButtonText = isBulk && !props.isTestSlide ? 'Preview Bulk Pack' : 'Generate';

    if (props.isProducer) {
      buttons = <AdminButton isButtonActive={isButtonActive} isLoading={isLoading} generateText={generateButtonText} />;
    } else {
      if (flags.enableGenerationPopup) {
        buttons = (
          <EndUserGenerationPopupButtons
            {...props}
            isButtonActive={isButtonActive}
            onFormSubmit={onFormSubmit}
            generateText={generateButtonText}
            templateType={templateType}
            includePdf={includePdf}
            onPdfToggle={onPdfToggle}
            includeTalkTracks={includeTalkTracks}
            onTalkTracksToggle={onTalkTracksToggle}
            includeExecSummary={includeExecSummary}
            onExecSummaryToggle={onExecSummaryToggle}
            customFolder={customFolder}
            onCustomFolderUpdate={onCustomFolderUpdate}
            customName={customName}
            onCustomNameUpdate={onCustomNameUpdate}
            customFolderPlaceholder={customFolderPlaceholder}
          />
        );
      } else {
        buttons = (
          <EndUserButtons
            isButtonActive={isButtonActive}
            onFormSubmit={onFormSubmit}
            generateText={generateButtonText}
          />
        );
      }
    }
  }

  return (
    <Form.Field className="fixed-bottom-button">
      <Form.Control className="block-button">
        <Level>
          <Level.Side align="left">
            <Level.Item>
              {!needsToConnect && !isEmailTemplate && (!flags.enableGenerationPopup || props.isProducer) && (
                <CheckboxWithLabel
                  checked={includePdf}
                  id="include-pdf-checkbox"
                  label="Include PDF"
                  onChange={onPdfToggle}
                />
              )}
            </Level.Item>
          </Level.Side>
          <Level.Side align="right">
            {props.isProducer && (
              <Level.Item>
                <Button category="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </Level.Item>
            )}
            <Level.Item>{buttons}</Level.Item>
          </Level.Side>
        </Level>
      </Form.Control>
    </Form.Field>
  );
}
Buttons.displayName = 'PresentationInputsForm.Buttons';
Buttons.propTypes = {
  includePdf: PropTypes.bool,
  onPdfToggle: PropTypes.func,
  includeExecSummary: PropTypes.bool,
  includeTalkTracks: PropTypes.bool,
  onTalkTracksToggle: PropTypes.func,
  onExecSummaryToggle: PropTypes.func,
  onClose: PropTypes.func,
  isButtonActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  onFormSubmit: PropTypes.func,
  isBulk: PropTypes.bool,
  templateType: PropTypes.string,
  customFolder: PropTypes.object,
  onCustomFolderUpdate: PropTypes.func,
  customName: PropTypes.string,
  onCustomNameUpdate: PropTypes.func,
  customFolderPlaceholder: PropTypes.string,
  source_type: PropTypes.string,
  isProducer: PropTypes.bool,
  isTestSlide: PropTypes.bool,
};

function ConnectButton({ sourceType }) {
  const isMS =
    sourceType === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
    sourceType === Constants.TEMPLATE_SOURCE_TYPES.WORD_365;
  return (
    <Button id="template-create-button" type="submit" status="default">
      {isMS ? 'Connect to Microsoft 365' : 'Connect To Google'}
    </Button>
  );
}
ConnectButton.displayName = 'PresentationInputsForm.ConnectButton';
ConnectButton.propTypes = {
  sourceType: PropTypes.string,
};

function AdminEmailButton({ isButtonActive, isLoading }) {
  let primaryButtonStatus = 'default';
  if (!isButtonActive) {
    primaryButtonStatus = 'disabled';
  } else if (isLoading) {
    primaryButtonStatus = 'loading';
  }
  return (
    <Button id="template-create-button" type="submit" status={primaryButtonStatus}>
      Send Email
    </Button>
  );
}
AdminEmailButton.displayName = 'PresentationInputsForm.AdminEmailButton';
AdminEmailButton.propTypes = {
  isButtonActive: PropTypes.bool,
  isLoading: PropTypes.bool,
};

function AdminButton({ isButtonActive, isLoading, generateText }) {
  let primaryButtonStatus = 'default';
  if (!isButtonActive) {
    primaryButtonStatus = 'disabled';
  } else if (isLoading) {
    primaryButtonStatus = 'loading';
  }
  return (
    <Button id="template-create-button" type="submit" status={primaryButtonStatus}>
      {generateText}
    </Button>
  );
}
AdminButton.displayName = 'PresentationInputsForm.AdminButton';
AdminButton.propTypes = {
  isButtonActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  generateText: PropTypes.node,
};

function EndUserEmailButtons({ isButtonActive, ...props }) {
  let isSendingDisabled = true;
  let tooltip = '';
  const subjectFilledOut = props.template.source.subject?.length > 0;
  const recipientsFilledOut = Boolean(props.template.source.recipients?.dynamicRecipients);
  const fromFilledOut =
    [
      props.template.source.from?.fromEmail,
      props.template.source.from?.replyEmail,
      props.template.source.from?.name,
    ].every((field) => field !== '') || Boolean(props.template.source.from?.senderContent);

  const allEmailFieldsFilledOut = subjectFilledOut && recipientsFilledOut && fromFilledOut;
  isSendingDisabled = !(props.allInputsFilledOut && allEmailFieldsFilledOut);
  if (props.allInputsFilledOut === false) {
    tooltip = 'Some inputs are missing';
  }
  if (allEmailFieldsFilledOut === false) {
    tooltip = 'Some email fields are incomplete: ';
    const missingEmailFields = [];
    if (!props.template.source.subject?.length) {
      missingEmailFields.push('Subject');
    }
    if (!Object.values(props.template.source.recipients).some((field) => field.length > 0)) {
      missingEmailFields.push('To');
    }
    if (!Object.values(props.template.source.from).every((field) => field !== '')) {
      missingEmailFields.push('From');
    }
    if (missingEmailFields.length) {
      tooltip += missingEmailFields.join(', ');
    }
  }

  return (
    <ButtonGroup>
      <ScheduleButton status={isButtonActive ? 'default' : 'disabled'} />
      <EmailSendDropdown
        submitTestEmail={props.submitTestEmail}
        onSendEmailClick={props.onSendEmailClick}
        isSendingDisabled={isSendingDisabled}
        tooltip={tooltip}
        hideScheduleOption={true}
      />
    </ButtonGroup>
  );
}
EndUserEmailButtons.displayName = 'PresentationInputsForm.EndUserEmailButtons';
EndUserEmailButtons.propTypes = {
  isButtonActive: PropTypes.bool,
  template: PropTypes.object,
  allInputsFilledOut: PropTypes.bool,
  submitTestEmail: PropTypes.func,
  onSendEmailClick: PropTypes.func,
};

function EndUserGenerationPopupButtons({
  isButtonActive,
  onFormSubmit,
  generateText,
  templateType,
  includePdf,
  onPdfToggle,
  includeTalkTracks,
  onTalkTracksToggle,
  includeExecSummary,
  onExecSummaryToggle,
  customFolder,
  onCustomFolderUpdate,
  customName,
  onCustomNameUpdate,
  customFolderPlaceholder,
  ...props
}) {
  const popupMenuRef = useRef();
  const generateButtonRef = useRef();

  const namePreview = utils.getNameTemplatePreview(props.template, props.sortedInputs, props.inputValues);
  const disabledFolderOptions = [
    Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS,
    Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS,
    Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES,
  ].includes(props.source_type)
    ? ['microsoft']
    : ['google'];

  return (
    <ButtonGroup>
      <ScheduleButton status={isButtonActive ? 'default' : 'disabled'} />
      <Button
        buttonRef={generateButtonRef}
        category="primary"
        type="button"
        onClick={() => {
          popupMenuRef.current.toggle();
          API.track('open_generate_popup');
        }}
        status={isButtonActive ? 'default' : 'disabled'}
      >
        {generateText}
      </Button>
      <PopupMenu ref={popupMenuRef} anchorRef={generateButtonRef} offset={-93} position="x">
        <GeneratePresentationPopup
          onPdfToggle={onPdfToggle}
          includePdf={includePdf}
          includeTalkTracks={includeTalkTracks}
          onTalkTracksToggle={onTalkTracksToggle}
          includeExecSummary={includeExecSummary}
          onExecutiveSummaryToggle={onExecSummaryToggle}
          templateType={templateType}
          sourceType={props.source_type}
          popupMenuRef={popupMenuRef}
          showConditionsBanner={props.template?.conditions?.length > 0 && !props.presentationContext.overrideConditions}
          primaryButtonOnClick={onFormSubmit}
          primaryButtonText={generateText}
          primaryButtonActive={isButtonActive}
          onCustomFolderUpdate={onCustomFolderUpdate}
          customFolder={customFolder}
          customFolderPlaceholder={customFolderPlaceholder}
          onCustomNameUpdate={onCustomNameUpdate}
          customName={customName}
          disabledFolderOptions={disabledFolderOptions}
          namePreview={namePreview}
        />
      </PopupMenu>
    </ButtonGroup>
  );
}
EndUserGenerationPopupButtons.displayName = 'PresentationInputsForm.EndUserGenerationPopupButtons';
EndUserGenerationPopupButtons.propTypes = {
  isButtonActive: PropTypes.bool,
  onFormSubmit: PropTypes.func,
  generateText: PropTypes.node,
  templateType: PropTypes.string,
  includePdf: PropTypes.bool,
  onPdfToggle: PropTypes.func,
  includeExecSummary: PropTypes.bool,
  includeTalkTracks: PropTypes.bool,
  onTalkTracksToggle: PropTypes.func,
  onExecSummaryToggle: PropTypes.func,
  customFolder: PropTypes.object,
  onCustomFolderUpdate: PropTypes.func,
  customName: PropTypes.string,
  onCustomNameUpdate: PropTypes.func,
  customFolderPlaceholder: PropTypes.string,
  inputValues: PropTypes.object,
  template: PropTypes.object,
  sortedInputs: PropTypes.array,
  source_type: PropTypes.string,
  presentationContext: PropTypes.object,
};

function EndUserButtons({ isButtonActive, onFormSubmit, generateText }) {
  return (
    <ButtonGroup>
      <ScheduleButton status={isButtonActive ? 'default' : 'disabled'} />
      <Button category="primary" type="button" onClick={onFormSubmit} status={isButtonActive ? 'default' : 'disabled'}>
        {generateText}
      </Button>
    </ButtonGroup>
  );
}
EndUserButtons.displayName = 'PresentationInputsForm.EndUserButtons';
EndUserButtons.propTypes = {
  isButtonActive: PropTypes.bool,
  onFormSubmit: PropTypes.func,
  generateText: PropTypes.node,
};

function ScheduleButton({ status }) {
  const context = useContext(UserContext);
  const dispatch = useDispatch();

  const isSchedulingEnabledForEnterprise = () => {
    return (
      context.user &&
      context.user.enterprise &&
      context.user.enterprise.plan_id === Constants.MATIK_TIERS.matik_enterprise.tier_id
    );
  };

  const openSchedule = (e) => {
    e.preventDefault();
    if (isSchedulingEnabledForEnterprise()) {
      API.track('click_schedule_button');
      dispatch(openModal('scheduledFlowModal'));
    } else {
      const planName = Constants.MATIK_TIERS[context.user.enterprise.plan_id].display_name;
      const noSchedulingHtml = ReactDOMServer.renderToStaticMarkup(
        <UpsellGenericModal
          featureHeader={`Schedule Presentations with Matik ${Constants.MATIK_TIERS.matik_enterprise.display_name}`}
          featureDescription={`Scheduling is not available for the ${planName} plan. Please upgrade to schedule presentations.`}
        />,
      );
      API.track('enterprise_upsell_shown', { from: 'no_scheduling' });
      MProductUpsell(`${noSchedulingHtml}`, false, (result) => {
        if (!result.dismiss) {
          API.track('discover_matik_enterprise_click', { from: 'no_scheduling' });
          const demoUrl = teams.buildRequestDemoUrl(
            context.user,
            'scheduling',
            context.user.enterprise.trial_days_remaining,
          );
          window.open(demoUrl, '_blank');
        }
      });
    }
  };

  return (
    <Button category="secondary" status={status} onClick={openSchedule}>
      <Icon name="calendar" size={20} theme="regular" />
      Schedule
    </Button>
  );
}
ScheduleButton.displayName = 'PresentationInputsForm.ScheduleButton';
ScheduleButton.propTypes = {
  status: Button.propTypes.status,
};

function ScheduleModal({
  currentBulkInputName,
  buildDataObject,
  getBulkPresentationData,
  removeOptionsAndReplaceMappedValuesForInputs,
  setIsLoading,
  submitPresentation,
  customFolderPlaceholder,
  includePdf,
  onPdfToggle,
  customFolder,
  onCustomFolderUpdate,
  ...props
}) {
  const context = useContext(UserContext);
  const { needsToConnect: needsToConnectToIntegration, connect: connectIntegration } = useIntegrationConnector(
    props.source_type,
  );

  const isBulk = Boolean(currentBulkInputName);

  function canRefreshSelectAllValues() {
    return Object.values(props.sortedInputs).some((input) =>
      inputs.canInputRefreshSelectAll(input, props.inputValues[input.name], currentBulkInputName),
    );
  }

  const onScheduleTrigger = (scheduleArg, skipPreview, refreshSelectAllValues) => {
    if (!hasPresentationsRemaining(context.user)) {
      return handlePresentationUpsell(context.user);
    }
    if (needsToConnectToIntegration) {
      return connectIntegration();
    }

    const [data, attachmentData] = buildDataObject(props.isTestSlide);
    scheduleArg.task_params = {
      ...data,
      ...attachmentData,
      should_refresh_select_all_values: refreshSelectAllValues,
    };
    const previewData = {
      ...data,
      ...attachmentData,
    };
    if (scheduleArg.csv_task_id) {
      if (skipPreview) {
        submitSchedule(scheduleArg);
      } else {
        previewData.values_by_param_name = cloneDeep(scheduleArg.previewData);
        delete scheduleArg.previewData;
        submitScheduledPreview(previewData, scheduleArg);
      }
    } else {
      // fashion the params for the scheduled generation
      const bulkValuesByParamName = isBulk
        ? getBulkPresentationData(refreshSelectAllValues, props.isTestSlide)
        : [removeOptionsAndReplaceMappedValuesForInputs(props.inputValues, refreshSelectAllValues)];
      scheduleArg.task_params.bulk_values_by_param_name = bulkValuesByParamName;
      if (skipPreview) {
        submitSchedule(scheduleArg);
      } else {
        previewData.values_by_param_name = bulkValuesByParamName[0];
        submitScheduledPreview(previewData, scheduleArg);
      }
    }
  };

  const onSubmitError = (err) => {
    setIsLoading(false);
    API.defaultError(err);
  };

  const submitSchedule = (scheduleArg) => {
    checkIncompleteTags(props.template, () => {
      setIsLoading(true);
      API.post(
        '/scheduled_tasks/',
        scheduleArg,
        () => {
          setIsLoading(false);
          props.closeModal();
          utils.notify('Successfully scheduled generation');
        },
        onSubmitError,
      );
    });
  };

  const submitScheduledPreview = (previewData, scheduleArg) => {
    checkIncompleteTags(props.template, () => {
      previewData['is_test_slide'] = true;
      if (isBulk || scheduleArg.csv_task_id) {
        API.track('scheduled_preview_click');
      } else {
        API.track('submit_non_bulk_schedule');
      }
      submitPresentation(previewData, {}, scheduleArg);
    });
  };

  return (
    <ScheduledFlowModal
      canRefreshSelectAllValues={canRefreshSelectAllValues()}
      onScheduleSubmit={onScheduleTrigger}
      template={props.template}
      type="create"
      customFolderPlaceholder={customFolderPlaceholder}
      includePdf={includePdf}
      onPdfToggle={onPdfToggle}
      customFolder={customFolder}
      onCustomFolderUpdate={onCustomFolderUpdate}
    />
  );
}
ScheduleModal.displayName = 'PresentationInputsForm.ScheduleModal';
ScheduleModal.propTypes = {
  currentBulkInputName: PropTypes.string,
  buildDataObject: PropTypes.func,
  getBulkPresentationData: PropTypes.func,
  removeOptionsAndReplaceMappedValuesForInputs: PropTypes.func,
  setIsLoading: PropTypes.func,
  submitPresentation: PropTypes.func,
  customFolderPlaceholder: PropTypes.string,
  includePdf: PropTypes.bool,
  onPdfToggle: PropTypes.func,
  customFolder: PropTypes.object,
  onCustomFolderUpdate: PropTypes.func,
  inputValues: PropTypes.object,
  template: PropTypes.object,
  sortedInputs: PropTypes.array,
  source_type: PropTypes.string,
  closeModal: PropTypes.func,
  isTestSlide: PropTypes.bool,
};
