import { useQuery, useQueryClient } from '@tanstack/react-query';
import API from 'lib/api';
import { useAllDynamicContentById } from 'lib/hooks/useDynamicContent';
import useMultiFetch from 'lib/hooks/useMultiFetch';

/** Fetch the dynamic content that refers to the given input
 */
export function useDynamicContentReferers(inputId) {
  const queryClient = useQueryClient();
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['parameters', 'dynamic_content', inputId],
    queryFn: () => {
      return API.get(
        `/parameters/${inputId}/dynamic_content/`,
        (response) => response.data,
        (err) => {
          API.defaultError(err);
        },
      );
    },
    enabled: !!inputId,
  });

  const { isLoading: isContentLoading, dynamicContentById } = useAllDynamicContentById(!!inputId);

  let hydratedContent = null;
  if (data && dynamicContentById) {
    hydratedContent = data.map((ref) => dynamicContentById[ref.id] || ref);
  }

  const invalidateAll = () => {
    queryClient.invalidateQueries({ queryKey: ['parameters', 'dynamic_content'] });
  };

  return {
    isPending: isLoading || isContentLoading,
    data: hydratedContent,
    isError,
    error,
    invalidateAll,
  };
}

export const useInputMutator = () => {
  const queryClient = useQueryClient();

  const create = (data) => {
    return API.post(
      '/parameters/',
      data,
      (response) => {
        const input = response.data.new_entity;
        queryClient.setQueryData(['parameter', parseInt(input.id)], input);
        queryClient.setQueryData(['parameter', input.name], input);
        return input;
      },
      (err) => {
        throw err;
      },
    );
  };

  const update = (id, data) => {
    return API.put(
      `/parameters/${id}/`,
      data,
      (response) => {
        const input = response.data.updated_entity;
        queryClient.setQueryData(['parameter', parseInt(input.id)], input);
        queryClient.setQueryData(['parameter', input.name], input);
        return input;
      },
      (err) => {
        throw err;
      },
    );
  };

  const del = (input) => {
    return API.delete(
      `/parameters/${input.id}/`,
      () => {
        queryClient.invalidateQueries({ queryKey: ['parameter', parseInt(input.id)], exact: true });
        queryClient.invalidateQueries({ queryKey: ['parameter', input.name], exact: true });
      },
      (err) => {
        throw err;
      },
    );
  };

  return {
    create,
    update,
    del,
  };
};

export const useInputs = (inputIds) => {
  const getQueryKey = (id) => ['parameter', parseInt(id)];
  const getFetchUri = (ids) => `/parameters/?filter=${JSON.stringify({ id: ids })}`;
  const getEntityId = (input) => input.id;
  const getAlternateKeys = (input) => [['parameter', input.name]];

  return useMultiFetch(inputIds, getQueryKey, getFetchUri, getEntityId, getAlternateKeys);
};

export const useInputsByName = (inputNames) => {
  const getQueryKey = (name) => ['parameter', name];
  const getFetchUri = (names) => `/parameters/?filter=${JSON.stringify({ name: names })}`;
  const getEntityId = (input) => input.name;
  const getAlternateKeys = (input) => [['parameter', parseInt(input.id)]];

  return useMultiFetch(inputNames, getQueryKey, getFetchUri, getEntityId, getAlternateKeys);
};

/** Check to see if the given input exists but is not readable. If it doesn't exist or
 * is readable, this will return false. Any non-404/403 request error will be treated as
 * an error.
 */
export const useIsInputForbidden = (inputName) => {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['parameter', inputName, 'is_forbidden'],
    queryFn: () => {
      return API.get(
        `/parameters/name/${inputName}/`,
        () => {
          // The input exists, so is not forbidden.
          return false;
        },
        (err) => {
          if (err.response.status === 403) {
            return true;
          } else if (err.response.status === 404) {
            return false;
          } else {
            // If it's an error response that isn't 404 or 403, then we'll treat this as an unexpected error
            throw err.response;
          }
        },
      );
    },
    enabled: !!inputName,
    staleTime: 0,
  });

  return {
    isPending: isLoading,
    isForbidden: data,
    isError,
    error,
  };
};
