import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import LookerQueryForm from './LookerQueryForm';
import { Select } from '../../../shared/FormSelect';
import utils from '../../../../lib/utils';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { initializeQuery } from '../../../../lib/looker';

class LookerModelForm extends Component {
  componentDidMount() {
    if (this.props.queryObj.model) {
      this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'models');
      if (this.props.queryObj.view) {
        const modelExploreId = utils.modelExploreId(this.props.queryObj.model, this.props.queryObj.view);
        this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'model_explore', modelExploreId);
      }
    }
  }
  render() {
    const modelExploreId = utils.modelExploreId(this.props.queryObj.model, this.props.queryObj.view);
    let fields = [];
    if (
      this.props.queryObj.view &&
      this.props.apiInfo?.['model_explore']?.[modelExploreId] &&
      this.props.apiInfo['model_explore'][modelExploreId] instanceof Array
    ) {
      fields = this.props.apiInfo['model_explore'][modelExploreId];
    }
    const models = this.props.apiInfo['models']
      ? Object.keys(this.props.apiInfo['models']).map((modelName) => {
          const model = this.props.apiInfo['models'][modelName];
          return { value: model.name, label: model.label ? model.label : model.name };
        })
      : [];
    let selectedModelOption = null;
    let modelErrorMessage = null;
    if (this.props.queryObj.model) {
      selectedModelOption = models.find((modelOption) => this.props.queryObj.model === modelOption.value);
      if (!selectedModelOption) {
        modelErrorMessage = 'This model cannot be found in Looker. Please select a valid model.';
        selectedModelOption = { value: this.props.queryObj.model, label: this.props.queryObj.model };
      }
    }

    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Model</Form.Label>
          <Form.Help>Select Looker Model</Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Looker Model"
              placeholder="Select a Model"
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={selectedModelOption}
              name="selected-model"
              onChange={this.selectModel}
              options={models}
              classNames={{
                control: () => (modelErrorMessage ? 'select-error' : ''),
              }}
              menuPortalTarget={this.props.formRef}
            />
          </Form.Control>
          <Form.Help color="danger">{modelErrorMessage}</Form.Help>
        </Form.Field>
        {this.props.queryObj.model && this.props.apiInfo['models'] && this.renderModelExplores()}
        {this.props.queryObj.view && (
          <LookerQueryForm
            queryObj={this.props.queryObj}
            fields={fields}
            inputsInQueryString={this.props.inputsInQueryString}
            entityType={this.props.entityType}
            input={this.props.input}
            inputMapping={this.props.inputMapping}
            onInputMappingUpdate={this.props.onInputMappingUpdate}
          />
        )}
      </React.Fragment>
    );
  }

  renderModelExplores() {
    const explores =
      (this.props.queryObj.model && this.props.apiInfo?.['models']?.[this.props.queryObj.model]?.explores) || [];
    const exploreOptions = explores.map((explore) => {
      return { value: explore.name, label: explore.label ? explore.label : explore.name };
    });
    let selectedExploreOption = null;
    let exploreErrorMessage = null;
    if (this.props.queryObj.view) {
      selectedExploreOption = exploreOptions.find((exploreOption) => this.props.queryObj.view === exploreOption.value);
      if (!selectedExploreOption) {
        exploreErrorMessage = 'The view cannot be found in Looker. Please select a valid view.';
        selectedExploreOption = { value: this.props.queryObj.view, label: this.props.queryObj.view };
      }
    }

    const apiErrorMessage =
      this.props.apiErrors &&
      this.props.queryObj.model &&
      this.props.apiErrors['model_explore'] &&
      this.props.apiErrors['model_explore'][
        utils.modelExploreId(this.props.queryObj.model, this.props.queryObj.view)
      ] &&
      'This view is inaccessible. Please check your configuration in Looker.';

    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Model View</Form.Label>
          <Form.Help>Select Looker Model View</Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Looker Model View"
              placeholder="Select a Model View"
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={selectedExploreOption}
              name="selectedModelExplore"
              onChange={this.selectModelExplore}
              options={exploreOptions}
              classNames={{
                control: () => (exploreErrorMessage || apiErrorMessage ? 'select-error' : ''),
              }}
              menuPortalTarget={this.props.formRef}
            />
          </Form.Control>
          <Form.Help color="danger">{exploreErrorMessage || apiErrorMessage}</Form.Help>
        </Form.Field>
      </React.Fragment>
    );
  }

  selectModel = (obj, action) => {
    if (action.action === 'select-option') {
      if (this.props.apiInfo['models'][obj.value].name === this.props.queryObj.model) {
        return;
      }
      const updatedQueryObj = initializeQuery({
        source: 'model',
        model: this.props.apiInfo['models'][obj.value].name,
      });
      this.context.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  selectModelExplore = (obj, action) => {
    if (action.action === 'select-option') {
      const modelExploreName = obj.value;
      if (modelExploreName === this.props.queryObj.view) {
        return;
      }
      const updatedQueryObj = initializeQuery({
        source: 'model',
        model: this.props.queryObj.model,
        view: modelExploreName,
      });
      this.props.fetchApiInfoIfNeeded(
        this.props.dataSourceId,
        'model_explore',
        utils.modelExploreId(this.props.queryObj.model, modelExploreName),
      );
      this.context.onQueryObjectUpdate(updatedQueryObj);
    }
  };
}

LookerModelForm.contextType = DynamicContentContext;

LookerModelForm.propTypes = {
  apiInfo: PropTypes.object,
  apiErrors: PropTypes.object,
  dataSourceId: PropTypes.number,
  fetchApiInfoIfNeeded: PropTypes.func,
  inputsInQueryString: PropTypes.object,
  queryObj: PropTypes.object,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  formRef: PropTypes.object,
};

export default LookerModelForm;
