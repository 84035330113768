import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Hero, Heading } from 'react-bulma-components';
import { cloneDeep, filter } from 'lodash';
import Constants from '../../Constants';
import TemplateConditionGroup from './TemplateConditionGroup';
import conditional_empty_icon from '../../../images/conditional_empty.svg';
import { ReactComponent as AddCircleFilled } from '../../../svg/add_circle_filled.svg';
import Banner from 'components/shared/Banner';
import Button from '../../lib/Button';
import { useAllDynamicContentById } from '../../../lib/hooks/useDynamicContent';
import { usePrevious } from 'ra-core/esm/util/hooks';
import API from '../../../lib/api';
import utils from 'lib/utils';
import Icon from 'components/lib/Icon';

function TemplateConditionsForm({
  currentTemplate,
  onConditionsUpdated,
  setPrimaryButtonOnClick,
  setPrimaryButtonDisabled,
  onClose,
  width,
}) {
  const [conditions, setConditions] = useState({});
  const prevTemplate = usePrevious(currentTemplate);

  useEffect(() => {
    if (currentTemplate?.conditions?.[0] && currentTemplate !== prevTemplate) {
      setConditions(currentTemplate.conditions?.[0].conditions);
    }
  }, [currentTemplate]);

  useEffect(() => {
    setPrimaryButtonOnClick(() => onFormSubmit);
  }, [conditions]);

  const onConditionsUpdate = (updatedConditions) => {
    setConditions(updatedConditions);
  };

  useEffect(() => {
    let primaryButtonDisabled;
    if (Object.keys(conditions).length > 0) {
      primaryButtonDisabled = false;
    } else {
      primaryButtonDisabled = true;
    }
    setPrimaryButtonDisabled(primaryButtonDisabled);
  }, [conditions]);

  const onError = (err) => {
    API.defaultError(err);
  };

  const onFormSubmit = () => {
    let data = {
      template_id: currentTemplate.id,
      conditions: conditions,
      target_ids: [currentTemplate.id],
      target_type: 'template',
    };

    if (currentTemplate?.conditions?.length) {
      API.put(
        `/conditions/${currentTemplate.conditions[0].id}/`,
        data,
        () => {
          utils.notify('Condition successfully updated');
          onConditionsUpdated();
          onClose();
        },
        onError,
      );
    } else {
      API.post(
        '/conditions/',
        data,
        () => {
          utils.notify('Condition successfully added');
          onConditionsUpdated();
          onClose();
        },
        onError,
      );
    }
  };

  const onConditionsDelete = () => {
    if (currentTemplate?.conditions?.length) {
      API.delete(`/conditions/${currentTemplate.conditions[0].id}/`, () => {
        utils.notify('Condition successfully removed');
        setConditions({});
        onConditionsUpdated();
      });
    } else {
      setConditions({});
    }
  };

  const { dynamicContentById: allDynamicContentNamesById } = useAllDynamicContentById();
  const generateEmptyClause = () => {
    const dynamicContentById = allDynamicContentNamesById || {};
    const allDynamicContent = filter(dynamicContentById, {
      dynamic_content_type: Constants.DynamicContentTypes.TEXT,
    });
    const supported_operators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.conditions;
    return {
      dynamicContent: allDynamicContent?.[0]?.id,
      operator: supported_operators[0],
      operand: '',
    };
  };

  const addEmptyCondition = (e, conditions) => {
    e.preventDefault();
    const updatedConditions = cloneDeep(conditions);
    if (!updatedConditions.conditions) {
      updatedConditions.conditions = [];
    }
    updatedConditions.conditions.push({
      clauses: [generateEmptyClause()],
    });
    onConditionsUpdate(updatedConditions);
  };

  let formBody = '';

  let heading;
  let bodyText;
  let messageText;
  if (currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
    heading = 'Add Conditions For This Email';
    bodyText = 'Conditions allow you to control whether an email is sent.';
    messageText = 'If any of the condition groups return true, the email will be sent.';
  } else if (currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS) {
    heading = 'Add Conditions For This Document';
    bodyText = 'Conditions allow you to control whether a document is generated.';
    messageText = 'If any of the condition groups return true, the document will be generated.';
  } else if (currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS) {
    heading = 'Add Conditions For This Spreadsheet';
    bodyText = 'Conditions allow you to control whether a spreadsheet is generated.';
    messageText = 'If any of the condition groups return true, the spreadsheet will be generated.';
  } else {
    heading = 'Add Conditions For This Presentation';
    bodyText = 'Conditions allow you to control whether a presentation is generated.';
    messageText = 'If any of the condition groups return true, the presentation will be generated.';
  }

  const templateType = Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(currentTemplate.source_type)
    ? 'document'
    : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
    ? 'email'
    : 'presentation';

  if (
    !conditions ||
    Object.keys(conditions).length === 0 ||
    !conditions.conditions ||
    conditions.conditions.length === 0
  ) {
    formBody = (
      <Hero>
        <Hero.Body className="has-text-centered">
          <Heading size={5} subtitle>
            <img width="200px" src={conditional_empty_icon} alt="Empty indication" />
          </Heading>
          <Heading size={4} subtitle className="has-text-grey-dark">
            {heading}
          </Heading>
          <p>{bodyText}</p>
          <Form.Field className="pam">
            <Form.Control className="centered-button">
              <Button onClick={(e) => addEmptyCondition(e, conditions)}>
                <AddCircleFilled className="has-text-white mrs" />
                Add Condition Group
              </Button>
            </Form.Control>
          </Form.Field>
        </Hero.Body>
      </Hero>
    );
  } else {
    formBody = (
      <div className={width === 'full' ? 'fullscreen-conditions-form' : 'w-10/12'}>
        <Banner className="mts mbm" color="message-info" text={messageText} bannerType="info" />
        {conditions.conditions &&
          conditions.conditions.map((condition, idx) => (
            <TemplateConditionGroup
              key={idx}
              condition={condition}
              conditionIndex={idx}
              conditions={conditions}
              deleteAllConditions={onConditionsDelete}
              onConditionsUpdate={onConditionsUpdate}
              generateEmptyClause={generateEmptyClause}
              templateType={templateType}
            />
          ))}
        <div className="flex justify-between">
          <Button category="secondary" onClick={(e) => addEmptyCondition(e, conditions)}>
            <Icon name="add_circle" size={20} theme="regular" />
            <span className="mls">Add Condition Group</span>
          </Button>
          <Button category="tertiary" onClick={onConditionsDelete}>
            <Icon name="trash_can" size={20} theme="regular" />
            <span className="mls">Delete All Conditions</span>
          </Button>
        </div>
      </div>
    );
  }

  return formBody;
}

TemplateConditionsForm.propTypes = {
  onConditionsUpdate: PropTypes.func,
  conditions: PropTypes.object,
  onConditionsDelete: PropTypes.func,
  currentTemplate: PropTypes.object,
};

export default TemplateConditionsForm;
