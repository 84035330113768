import React, { forwardRef, memo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import useAllConditions, {
  useConditionTargetMutator,
  useConditionsInTemplate,
  useSlideConditionTargets,
} from 'lib/hooks/useCondition';
import { useDispatch } from 'react-redux';

import PopupMenu from 'components/shared/PopupMenu';
import ConditionContextMenu from './ConditionContextMenu';
import { openSidepane } from 'redux/ui/action';
import { isEqual } from 'lodash';

const ConditionContextPopupMenu = forwardRef(
  (
    {
      position,
      anchorRef,
      template,
      selectedSlides,
      copiedConditions,
      onCopyConditions,
      onSelectSlidesMatchingConditions,
    },
    menuRef,
  ) => {
    if (!selectedSlides) {
      selectedSlides = [];
    }
    const dispatch = useDispatch();

    const { elements: conditions } = useAllConditions(0, 999);
    const conditionsInTemplate = useConditionsInTemplate(template);

    const { upsertSlides: upsertSlideConditions } = useConditionTargetMutator();
    const targetResultsById = useSlideConditionTargets(selectedSlides.map((slide) => slide.id));

    const cloneConditions = (slide) => {
      const { data: slideTarget } = targetResultsById[slide.id] || {};

      const clone = {
        or: [...(slideTarget?.condition_clauses?.or || [])],
      };
      clone.or.forEach((or) => {
        or.and = [...(or.and || [])];
      });
      return clone;
    };

    // create an object keyed with each selectedSlide id pointing at the given value.
    const byEachSlideId = (value) => {
      const bySlide = {};
      selectedSlides.forEach((slide) => (bySlide[slide.id] = value));
      return bySlide;
    };

    // Only enable "copy" and "selectAllSlides" when every selected slide has the same condition_clauses.
    const basisSlide = selectedSlides[0];
    const isSameConditionClauses =
      selectedSlides.length > 0
        ? selectedSlides.every((slide) => isEqual(slide.condition_clauses, basisSlide.condition_clauses))
        : false;

    const handleCreate = () => {
      menuRef.current.close();
      dispatch(
        openSidepane(
          { entityType: 'condition', slideIds: selectedSlides.map((slide) => slide.id) },
          'template-sidepane',
        ),
      );
    };

    const handleCopy = () => {
      menuRef.current.close();
      if (basisSlide) {
        onCopyConditions(cloneConditions(basisSlide));
      }
    };

    const handlePaste = () => {
      menuRef.current.close();
      upsertSlideConditions(byEachSlideId(copiedConditions));
    };

    const handleApply = (conditionId) => {
      menuRef.current.close();
      // OR the condition

      const bySlide = {};
      selectedSlides.forEach((slide) => {
        const updatedConditions = cloneConditions(slide);
        updatedConditions.or.push({ and: [conditionId] });
        bySlide[slide.id] = updatedConditions;
      });
      upsertSlideConditions(bySlide);
    };

    const handleRemove = (conditionId) => {
      menuRef.current.close();
      const bySlide = {};
      selectedSlides.forEach((slide) => {
        const updatedConditions = cloneConditions(slide);

        // Remove the given condition from any clauses
        updatedConditions.or.forEach((or) => {
          or.and = or.and.filter((id) => id !== conditionId);
        });

        // trim any empty "and" and "or"
        updatedConditions.or = updatedConditions.or.filter((or) => or.and?.length > 0);
        if (updatedConditions.or.length === 0) {
          bySlide[slide.id] = null;
        } else {
          bySlide[slide.id] = updatedConditions;
        }
      });
      upsertSlideConditions(bySlide);
    };

    const handleRemoveAll = () => {
      menuRef.current.close();
      // Clear all conditions from the slide
      upsertSlideConditions(byEachSlideId(null));
    };

    const handleSelectAllSlides = () => {
      menuRef.current.close();
      if (basisSlide) {
        onSelectSlidesMatchingConditions(cloneConditions(basisSlide));
      }
    };

    const conditionsById = {};
    if (conditions) {
      conditions.forEach((condition) => {
        conditionsById[condition.id] = condition;
      });
    }

    // gather all the conditions referenced by the selected selectedSlides
    const conditionIdsOnSlides = new Set();
    selectedSlides?.forEach((slide) => {
      const { data: slideTarget } = targetResultsById[slide.id] || {};
      slideTarget?.condition_clauses?.or?.flatMap((or) => or.and)?.forEach((id) => conditionIdsOnSlides.add(id));
    });

    const conditionsOnSlides = [];
    conditionIdsOnSlides.forEach((id) => (conditionsById[id] ? conditionsOnSlides.push(conditionsById[id]) : null));

    return (
      <PopupMenu ref={menuRef} position={position} anchorRef={anchorRef}>
        <ConditionContextMenu
          conditionsInTemplate={conditionsInTemplate}
          conditionsOnSlides={conditionsOnSlides}
          conditionsById={conditionsById}
          onCopy={isSameConditionClauses ? handleCopy : null}
          onPaste={copiedConditions ? handlePaste : null}
          onCreate={handleCreate}
          onApply={handleApply}
          onSelectAllSlides={isSameConditionClauses ? handleSelectAllSlides : null}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
        />
      </PopupMenu>
    );
  },
);
ConditionContextPopupMenu.displayName = 'ConditionContextPopupMenu';
ConditionContextPopupMenu.propTypes = {
  /** popup menu position */
  position: PopupMenu.propTypes.position,
  /** popup menu anchor element ref */
  anchorRef: PropTypes.any,

  template: PropTypes.object,
  selectedSlides: PropTypes.array,

  onCopyConditions: PropTypes.func,
  copiedConditions: PropTypes.object,
  onSelectSlidesMatchingConditions: PropTypes.func,
};

const MemoizedConditionContextPopupMenu = memo(ConditionContextPopupMenu);

const StableCallbackConditionContextPopupMenu = forwardRef(
  function StableCallbackConditionContextPopupMenu(props, forwardedRef) {
    // Use useCallback to ensure function references don't break the memoization

    // eslint-disable-next-line no-unused-vars
    const { onCopyConditions, onSelectSlidesMatchingConditions, ...otherProps } = props;

    const callbacks = {};
    ['onCopyConditions', 'onSelectSlidesMatchingConditions'].forEach((fn) => {
      const ref = useRef();
      ref.current = props[fn];
      callbacks[fn] = useCallback(
        (...args) => {
          if (ref.current) {
            ref.current(...args);
          }
        },
        [ref],
      );
    });

    return <MemoizedConditionContextPopupMenu ref={forwardedRef} {...otherProps} {...callbacks} />;
  },
);
StableCallbackConditionContextPopupMenu.propTypes = ConditionContextPopupMenu.propTypes;

export default StableCallbackConditionContextPopupMenu;
