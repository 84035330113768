import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import API from 'lib/api';

/** Useful in case you have an endpoint that supports passing in multiple IDs and getting
 * multiple results.
 * @param {*} ids An array of IDs to fetch.
 * @param {*} getQueryKey A function that is passed an ID and returns a query cache key
 * @param {*} getFetchUri A function that is passed an array of IDs and returns the fetch URI
 * @param {*} getEntityId A function that is passed a result entity and returns the entity's ID
 * @param {*} cacheTime If supplied, the length of time the fetched entities are cached (milliseconds)
 * @param {*} getAlternateQueryKeys If supplied, return an array of keys used to cache the entities by
 * something other than the given "id". Useful for cross-caching data with different keys -- e.g. input name and id
 * @returns An object of query state results keyed by ID. The value will be similar to
 * what you would expect from a single `useFoo(id)` call. e.g.
 * ```
 * { isPending, isError, data, error }
 * ```
 */
const useMultiFetch = (ids, getQueryKey, getFetchUri, getEntityId, cacheTime, getAlternateQueryKeys) => {
  const queryClient = useQueryClient();

  // We only need to fetch stuff not already cached.
  const queryCache = queryClient.getQueryCache();
  const idsToFetch = ids.filter((id) => {
    // Note "queryCache.get()" is undocumented, but fast. "queryClient.getQueryState()" is documented, but slow.
    //const queryState = queryClient.getQueryState({ queryKey: getQueryKey(id) });
    const queryState = queryCache.get(JSON.stringify(getQueryKey(id)))?.state;
    if (!queryState) {
      // not cached
      return true;
    }
    if (queryState.isInvalidated && queryState.fetchStatus === 'idle') {
      // cached, but invalidated. we want to refetch
      return true;
    }
    return false;
  });

  const queries = ids.map((id) => ({
    queryKey: getQueryKey(id),
    queryFn: () => {
      /* just listening to the cache */
    },
    enabled: false,
    cacheTime: cacheTime,
  }));
  const results = useQueries({ queries });

  const resultById = {};
  for (let i = 0; i < ids.length; i++) {
    // ID lookups on a batch endpoint with no result are encoded as data === null.
    // Since that's effectively the same as a 404, we'll reshape the result to look
    // like that in those cases.
    const { isLoading, isError, data, error } = results[i];
    resultById[ids[i]] = {
      isPending: isLoading,
      isError: isError || data === null,
      data,
      error: error || data === null ? 'Not Found' : undefined,
    };
  }

  let batchFetchKey = ['n/a'];
  if (idsToFetch.length > 0) {
    batchFetchKey = getQueryKey(idsToFetch[0]);
    batchFetchKey.splice(batchFetchKey.length - 1, 1, idsToFetch);
  }
  useQuery({
    queryKey: batchFetchKey,
    queryFn: () => {
      return API.get(
        getFetchUri(idsToFetch),
        (response) => {
          const entityById = {};
          response.data.forEach((entity) => {
            entityById[getEntityId(entity)] = entity;
          });
          idsToFetch.forEach((id) => {
            // If no entitied is returned in the result, that's effectively a 404 for that id
            // but we can't cache an error status so we'll set `null` instead.
            queryClient.setQueryData(getQueryKey(id), entityById[id] || null);

            if (getAlternateQueryKeys) {
              const altKeys = getAlternateQueryKeys(entityById[id]);
              altKeys.forEach((altKey) => {
                queryClient.setQueryData(altKey, entityById[id] || null);
              });
            }
          });
          return null; // not caching the full response
        },
        (err) => {
          throw err;
        },
      );
    },
    enabled: idsToFetch.length > 0,
    cacheTime: 0,
  });

  return resultById;
};
export default useMultiFetch;
