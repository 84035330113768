import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'react-user-avatar';
import Constants from 'components/Constants';
import Icon from './Icon';

const SIZE = {
  m: 32,
  s: 24,
  xs: 20,
};

// eslint-disable-next-line react/prop-types
function Clickable({ children, onClick, ...props }) {
  return onClick ? (
    <button type="button" onClick={onClick} {...props}>
      {children}
    </button>
  ) : (
    <div {...props}>{children}</div>
  );
}

function AvatarGroup({ size = 'm', limit = 3, avatars = [], onClick, showAdd }) {
  const avatarSize = SIZE[size] || 32;
  const avatarishStyle = { height: `${avatarSize + 2}px`, width: `${avatarSize + 2}px` };
  return (
    <Clickable className={`flex items-center p-0.5 ${size === 'm' ? '-space-x-2' : '-space-x-1'}`} onClick={onClick}>
      {avatars.slice(0, limit).map((avatar, index) => (
        <UserAvatar
          key={index}
          colors={Constants.AVATAR_COLORS}
          src={avatar.photoUrl}
          name={avatar.name}
          size={avatarSize}
          className={`text-matik-white ${size === 'm' ? 'border-2' : 'border'} border-matik-white rounded-full`}
        />
      ))}
      {avatars.length - limit > 0 && (
        <div
          className={`bg-grey-200 text-matik-black flex justify-center items-center rounded-full ${
            size === 'm' ? 'border-2' : 'border'
          } border-matik-white`}
          style={avatarishStyle}
        >
          +{avatars.length - limit}
        </div>
      )}
      {showAdd && (
        <div
          className="bg-matik-white text-matik-black flex justify-center items-center rounded-full border-grey-300 border shadow-s"
          style={avatarishStyle}
        >
          <Icon name="plus" size={size === 'xs' ? 16 : 20} theme="regular" />
        </div>
      )}
    </Clickable>
  );
}
AvatarGroup.propTypes = {
  size: PropTypes.oneOf(Object.keys(SIZE)),
  limit: PropTypes.number,
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      photoUrl: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
  showAdd: PropTypes.bool,
};

export default AvatarGroup;
